import { FC, useState } from "react";
import SearchResidentsForm from "./SearchResidentsForm";
import { useTranslation } from "react-i18next";
import SurnameMapTable from "./SurnameMapTable";
import { SearchResidentsXLSX, SearchResidentsDTO } from "../../domain/models/dto/ResidentDTO";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import residentServiceFactory from "../../services/ResidentServiceImpl";
import MapComponent from "./MapComponent";
import { District } from "../../domain/models/District";
import { MapLayerMode, getDistrictColor } from "../../domain/enums/MapLayerMode";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomCheckbox from "../shared/form-inputs/CustomCheckbox";
import ExportToXLSX from "../shared/exports/ExportToXLSX";
import CopyURL from "../shared/exports/CopyURL";
import { GeoJSON } from "../../types/GeoJSON";
import LegendBlock from "./LegendBlock";

interface SurnameMapSectionProps {
    districts: District[];
}

const langService = languageServiceFactory();
const residentService = residentServiceFactory();

const SurnameMapSection: FC<SurnameMapSectionProps> = (props) => {
    const [residents, setResidents] = useState<SearchResidentsDTO[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currentDistrictId, setCurrentDistrictId] = useState<string>(null);
    const [fileName, setFileName] = useState("");

    const [searchParams] = useSearchParams();
    const lastNameFathers = searchParams.get("lastNameFathers");
    const lastNameMothers = searchParams.get("lastNameMothers");

    const [fathersMode, setFathersMode] = useState(false);
    const [mothersMode, setMothersMode] = useState(false);

    const layerMode =
        fathersMode && mothersMode
            ? MapLayerMode.COMBINED
            : fathersMode
            ? MapLayerMode.FATHERS
            : mothersMode
            ? MapLayerMode.MOTHERS
            : null;

    const resetMapLayers = () => {
        setFathersMode(false);
        setMothersMode(false);
        setCurrentDistrictId(null);
        navigate(window.location.pathname);
    };

    const geoData = props.districts
        .filter((item) => item.id && item.geo)
        .map((item) => {
            const dto = residents
                .filter((resident) => resident.districtId === item.id)
                .reduce(
                    (acc, item) => {
                        if (!acc.districtId) {
                            acc.districtId = item.districtId;
                        }
                        acc.fathersCount += item.fathersCount;
                        acc.mothersCount += item.mothersCount;
                        return acc;
                    },
                    { fathersCount: 0, mothersCount: 0, districtId: null }
                );

            const color = dto && !_.isNull(layerMode) && getDistrictColor(dto)[layerMode];
            return { ...(item.geo as GeoJSON), id: item.id, color };
        });

    return (
        <div className="relative h-[1600px] bg-beige1 pt-[36px] overflow-x-clip">
            <div className="absolute left-0 right-0 mx-auto w-fit h-full flex">
                <div className="">
                    <SearchResidentsForm
                        setResidents={setResidents}
                        setFathersMode={setFathersMode}
                        setMothersMode={setMothersMode}
                        resetMapLayers={resetMapLayers}
                        setFileName={setFileName}
                    />
                    <div className="absolute right-[520px] p-4 rounded-md">
                        <label className="flex items-center mb-2 font-manrope text-[18px] leading-[26px] text-brown3">
                            <CustomCheckbox
                                labelKey="surnameMapPage.surnameMapSection.spreadFathersLastName"
                                checked={fathersMode}
                                disabled={!lastNameFathers}
                                onClick={() => setFathersMode(!fathersMode)}
                            />
                        </label>
                        <label className="flex items-center font-manrope text-[18px] leading-[26px] text-brown3">
                            <CustomCheckbox
                                labelKey="surnameMapPage.surnameMapSection.spreadMothersLastName"
                                checked={mothersMode}
                                disabled={!lastNameMothers}
                                onClick={() => setMothersMode(!mothersMode)}
                            />
                        </label>
                    </div>
                    <MapComponent
                        geoData={geoData}
                        currentDistrictId={currentDistrictId}
                        setCurrentDistrictId={setCurrentDistrictId}
                    />
                    <LegendBlock />
                    <div className="absolute top-[840px] flex justify-between items-center font-manrope text-[22px] text-brown3 leading-[32px] ml-[20px] w-[750px]">
                        {t("surnameMapPage.surnameMapSection.xlsxDownloadText")}
                        <div className="flex items-center justify-between w-[250px]">
                            <ExportToXLSX
                                data={residents}
                                toExport={SearchResidentsXLSX}
                                fileName={fileName}
                                titleKey="surnameMapPage.surnameMapSection.xlsxTitle"
                                translateKey="surnameMapPage.surnameMapSection.xlsxFields"
                            />
                            <CopyURL />
                        </div>
                    </div>
                </div>
                <div className="mr-[20px]">
                    <p className="font-cormorant font-semibold text-[26px] leading-[37.7px] text-brown3">
                        {t("surnameMapPage.surnameMapSection.text1")}
                    </p>
                    <p className="font-manrope font-normal text-[20px] leading-[29px] text-brown3 mt-[10px]">
                        {t("surnameMapPage.surnameMapSection.text2")}
                    </p>
                    <p className="font-manrope font-normal text-[20px] leading-[29px] text-brown3 mb-[20px]">
                        {t("surnameMapPage.surnameMapSection.text3")}
                    </p>
                    <SurnameMapTable
                        residents={residents.length ? residents : residentService.getMockSearchResidents()}
                        currentDistrictId={currentDistrictId}
                        setCurrentDistrictId={setCurrentDistrictId}
                    />
                </div>
            </div>
            <div className="absolute left-0 right-0 mx-auto w-[1531px] flex border-t border-t-brown3 pt-[83px] top-[950px]">
                <div className="w-[1041px] border-r border-brown3 pr-[30px] mr-[17px]">
                    <p className="font-cormorant text-[40px] text-brown3 leading-[42px]">
                        <span className="font-bold">{t("surnameMapPage.surnameMapSection.text4")} </span>
                        {t("surnameMapPage.surnameMapSection.text5")}
                    </p>
                    <div className="flex justify-between mt-[56px]">
                        <p
                            className={`font-cormorant ${
                                langService.isUkrainian
                                    ? "text-[37.8px] leading-[58.6px]"
                                    : "text-[32.8px] leading-[48.6px]"
                            } text-brown3 w-[490px] pr-[80px]`}
                        >
                            {t("surnameMapPage.surnameMapSection.text6")}
                        </p>
                        <p className="font-manrope text-[20px] text-brown3 leading-[29px] w-[490px]">
                            {t("surnameMapPage.surnameMapSection.text7")}
                        </p>
                    </div>
                    <p className="font-cormorant text-[40px] text-brown3 leading-[42px] w-[812px] mt-[56px]">
                        {t("surnameMapPage.surnameMapSection.text8")}{" "}
                        <span className="font-bold">{t("surnameMapPage.surnameMapSection.text9")}</span>
                    </p>
                </div>
                <div>
                    <p className="font-manrope text-[18px] text-brown3 leading-[22.5px] w-[473px]">
                        {t("surnameMapPage.surnameMapSection.text10")}
                    </p>
                    <ul className="list-disc list-outside mt-[24px]">
                        <li className="ml-[25px] font-manrope text-[18px] text-brown3 leading-[22.5px]">
                            {t("surnameMapPage.surnameMapSection.text11")}
                        </li>
                        <li className="ml-[25px] font-manrope text-[18px] text-brown3 leading-[22.5px]">
                            {t("surnameMapPage.surnameMapSection.text12")}
                        </li>
                        <li className="ml-[25px] font-manrope text-[18px] text-brown3 leading-[22.5px]">
                            {t("surnameMapPage.surnameMapSection.text13")}
                        </li>
                    </ul>
                    <p className="font-manrope text-[15px] text-brown3 leading-[18.75px] w-[470px] mt-[24px]">
                        {t("surnameMapPage.surnameMapSection.text14")}
                    </p>
                    <p className="font-manrope text-[15px] text-brown3 leading-[18.75px] w-[470px] mt-[24px]">
                        {t("surnameMapPage.surnameMapSection.text15")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SurnameMapSection;
