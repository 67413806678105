import { DistrictDTO } from "./DistrictDTO";

export interface ResidentDTO {
    id?: string;

    firstNameUk: string;
    firstNameEn: string;

    lastNameUk: string;
    lastNameEn: string;

    regionUk: string;
    regionEn: string;

    districtId: string;

    localityUk: string;
    localityEn: string;

    createdAt?: string;
    updatedAt?: string;

    district?: DistrictDTO;
}

export interface ResidentAutocompleteDTO {
    lastNameUk: string;
    lastNameEn: string;
}

export interface SimpleSearchDTO {
    districtId?: string;
    fathersCount: number;
    mothersCount: number;
}

export interface SearchResidentsDTO extends SimpleSearchDTO {
    regionUk?: string;
    regionEn?: string;
    districtUk?: string;
    districtEn?: string;
    localityUk?: string;
    localityEn?: string;
}

export const SearchResidentsXLSX: (keyof SearchResidentsDTO)[] = [
    "regionUk",
    "regionEn",
    "districtUk",
    "districtEn",
    "localityUk",
    "localityEn",
    "fathersCount",
    "mothersCount",
];
