import { FC } from "react";
import Header from "../header/Header";
import HeroSection from "./HeroSection";
import GenealogistInfoSection from "./GenealogistInfoSection";
import GenerationalHistorySection from "./GenerationalHistorySection";
import FamilyStoriesSection from "./FamilyStoriesSection";
import ResourceHelperSection from "./ResourceHelperSection";
import Footer from "../footer/Footer";

const AboutPage: FC = () => {
    return (
        <>
            <Header />
            <HeroSection />
            <GenealogistInfoSection />
            <GenerationalHistorySection />
            <FamilyStoriesSection />
            <ResourceHelperSection />
            <Footer />
        </>
    );
};

export default AboutPage;
