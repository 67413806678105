import { FC } from "react";

interface InputTipSVGProps {
    className?: string;
}

const InputTipSVG: FC<InputTipSVGProps> = (props) => {
    return (
        <svg
            className={props.className}
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path
                    d="M14.2411 19.8013C14.2411 19.2046 14.2518 18.7073 14.2731 18.3095C14.3015 17.9045 14.433 17.4783 14.6674 17.0307C14.8947 16.6045 15.1896 16.2457 15.5519 15.9544C15.9142 15.656 16.2836 15.3825 16.6601 15.1339C17.0366 14.8852 17.3599 14.6153 17.6298 14.324C17.8998 14.0114 18.0809 13.6882 18.1733 13.3543C18.2657 13.0133 18.3118 12.6616 18.3118 12.2993C18.3118 11.8589 18.255 11.4681 18.1413 11.1271C18.0277 10.7861 17.8572 10.4949 17.6298 10.2533C17.2888 9.8555 16.8768 9.58554 16.3937 9.44345C15.9177 9.30137 15.4275 9.23033 14.9231 9.23033C14.3832 9.23033 13.8824 9.31203 13.4206 9.47542C12.9659 9.63172 12.593 9.8697 12.3017 10.1894C12.0389 10.4522 11.847 10.7684 11.7263 11.1378C11.6126 11.5001 11.5558 11.8802 11.5558 12.278H10.6606C10.6606 11.8091 10.7352 11.3332 10.8844 10.8501C11.0407 10.3599 11.2929 9.93364 11.641 9.57133C12.0673 9.13087 12.5752 8.81474 13.1649 8.62292C13.7616 8.43111 14.3619 8.33521 14.9658 8.33521C15.6051 8.33521 16.2126 8.43111 16.788 8.62292C17.3705 8.81474 17.8643 9.13087 18.2692 9.57133C18.6244 9.94075 18.8802 10.3563 19.0365 10.8181C19.1927 11.2728 19.2709 11.7736 19.2709 12.3206C19.2709 12.8108 19.1892 13.2868 19.0258 13.7486C18.8695 14.2104 18.6244 14.6082 18.2905 14.9421C18.0348 15.2191 17.7186 15.4855 17.3421 15.7413C16.9727 15.9899 16.6139 16.2528 16.2658 16.5299C15.9177 16.8069 15.6549 17.116 15.4773 17.457C15.2997 17.798 15.2073 18.1496 15.2002 18.5119C15.2002 18.8671 15.2002 19.2969 15.2002 19.8013H14.2411ZM14.2411 23.9999V22.4015H15.2002V23.9999H14.2411Z"
                    fill="#6A4F3E"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.7617 29.2618C22.3622 29.2618 28.5236 23.1004 28.5236 15.4999C28.5236 7.89945 22.3622 1.73804 14.7617 1.73804C7.16117 1.73804 0.999756 7.89945 0.999756 15.4999C0.999756 23.1004 7.16117 29.2618 14.7617 29.2618ZM14.7617 30.2618C22.9144 30.2618 29.5236 23.6527 29.5236 15.4999C29.5236 7.34717 22.9144 0.738037 14.7617 0.738037C6.60889 0.738037 -0.000244141 7.34717 -0.000244141 15.4999C-0.000244141 23.6527 6.60889 30.2618 14.7617 30.2618Z"
                    fill="#6A4F3E"
                />
            </g>
        </svg>
    );
};

export default InputTipSVG;
