import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SearchResidentsDTO } from "../../domain/models/dto/ResidentDTO";
import SurnameMapTableRow from "./SurnameMapTableRow";

interface SurnameMapTableProps {
    residents: SearchResidentsDTO[];
    currentDistrictId: string;
    setCurrentDistrictId: (value: string) => void;
}

const SurnameMapTable: FC<SurnameMapTableProps> = (props) => {
    const tableRef = useRef<HTMLTableElement>(null);
    const { t } = useTranslation();
    useEffect(() => {
        if (tableRef) {
            const currentScroll = window.scrollY;
            const rows = Array.from(tableRef.current.rows);
            rows.filter((row) => row.getAttribute("id") === props.currentDistrictId)[0]?.scrollIntoView();
            window.scrollTo({ top: currentScroll });
        }
    }, [props.currentDistrictId]);

    return (
        <div className="relative">
            <div className="relative flex items-center border border-b-0 border-brown3 w-fit h-[60px] bg-button-gradient font-manrope font-semibold text-[18px] text-beige1 text-left leading-[18px] mt-[20px]">
                <p className="w-[52px] text-center">{t("surnameMapPage.surnameMapSection.surnameMapTable.number")}</p>
                <p className="w-[160px] pl-[12px]">{t("surnameMapPage.surnameMapSection.surnameMapTable.district")}</p>
                <p className="w-[160px] pl-[12px]">{t("surnameMapPage.surnameMapSection.surnameMapTable.locality")}</p>
                <p className="w-[46px] text-center">{t("surnameMapPage.surnameMapSection.surnameMapTable.A")}</p>
                <p className="w-[46px] text-center">{t("surnameMapPage.surnameMapSection.surnameMapTable.B")}</p>
            </div>
            <div className="relative max-h-[701px] overflow-auto">
                <table ref={tableRef}>
                    <tbody className="relative border border-brown3">
                        {props.residents.map((resident, index) => (
                            <SurnameMapTableRow
                                key={`tr-surname-map-table-${index}`}
                                resident={resident}
                                order={index + 1}
                                currentDistrictId={props.currentDistrictId}
                                setCurrentDistrictId={props.setCurrentDistrictId}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SurnameMapTable;
