import * as XLSX from "xlsx";
import CSVFileServiceFactory from "../../../services/CSVFileServiceImpl";
import DownloadButton from "../svg/DownloadButton";
import { NameFormattingServiceImpl } from "../../../services/NameFormattingService";
import { useTranslation } from "react-i18next";

interface ExportToXLSXProps<T> {
    data: T[];
    toExport: (keyof T)[];
    titleKey: string;
    fileName: string;
    translateKey: string;
}

const CSVFileService = CSVFileServiceFactory();

function ExportToXLSX<T>({ data, toExport, titleKey, fileName, translateKey }: ExportToXLSXProps<T>) {
    const { t } = useTranslation();

    const handleXLSXExport = () => {
        if (!data.length) return;

        const csvData = CSVFileService.convertToCSVObject<T>(data, toExport, translateKey, t);

        const worksheet = XLSX.utils.json_to_sheet(csvData, { skipHeader: true });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet);

        const xlsxFileName = NameFormattingServiceImpl.getFileName(fileName) + ".xlsx";
        XLSX.writeFile(workbook, xlsxFileName);
    };

    return (
        <button onClick={handleXLSXExport} disabled={!data.length}>
            <DownloadButton label="XLSX" title={t(titleKey)} disable={!data.length} />
        </button>
    );
}

export default ExportToXLSX;
