import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { ArchiveDTO } from "../domain/models/dto/ArchiveDTO";
import { ukrainianPhone } from "../domain/constants";
import { enumToSelectProps } from "../tools/EnumTools";
import { ArchivesTypes } from "../domain/enums/ArchivesTypesEnum";

export const archiveFields: Fields<ArchiveDTO> = {
    nameUk: {
        min: 1,
        max: 200,
        placeholderKey: `admin.archivesPage.tableColumns.nameUk`,
        type: "text",
        initial: "",
    },
    nameEn: {
        min: 1,
        max: 200,
        placeholderKey: `admin.archivesPage.tableColumns.nameEn`,
        type: "text",
        initial: "",
    },
    addressUk: {
        min: 1,
        max: 200,
        placeholderKey: `admin.archivesPage.tableColumns.addressUk`,
        type: "text",
        initial: "",
    },
    addressEn: {
        min: 1,
        max: 200,
        placeholderKey: `admin.archivesPage.tableColumns.addressEn`,
        type: "text",
        initial: "",
    },
    phone: {
        min: 13,
        max: 20,
        placeholderKey: `admin.archivesPage.tableColumns.phoneStr`,
        type: "text",
        array: true,
        initial: [""],
        optional: true,
        pattern: ukrainianPhone,
        patternErrorKey: "errors.validation.invalidPhone",
    },
    email: {
        min: 1,
        max: 200,
        placeholderKey: `admin.archivesPage.tableColumns.emailStr`,
        type: "email",
        array: true,
        initial: [""],
        optional: true,
    },
    site: {
        min: 1,
        max: 200,
        placeholderKey: `admin.archivesPage.tableColumns.site`,
        type: "text",
        initial: "",
        optional: true,
    },
    type: {
        min: 0,
        max: 100,
        placeholderKey: `admin.archivesPage.tableColumns.type`,
        type: "select",
        initial: { value: null, labelKey: "" },
        selectOptions: enumToSelectProps(ArchivesTypes, `archivesPage.type`),
    },
};

export const archiveSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, archiveFields);
};
