import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import DeleteTrashIconSVG from "../../shared/svg/admin-icons/DeleteTrashIconSVG";
import EditPencilIconSVG from "../../shared/svg/admin-icons/EditPencilIconSVG";
import { District } from "../../../domain/models/District";
import { DistrictFields } from "../../../domain/enums/DistrictFieldsEnum";
import DistrictsTableRow from "./DistrictTableRow";

interface DistrictsTableProps {
    districts: District[];
    updateHandler: (district: District) => void;
    deleteHandler: (district: District) => void;
}

const DistrictsTable: FC<DistrictsTableProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="w-[95%] h-[calc(100vh-150px)] ml-auto mr-auto relative overflow-auto shadow-md rounded-xl">
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="sticky top-0 text-[14px] font-extralight leading-[16px] text-gray-700 bg-gray-300">
                    <tr className="text-center px-2 py-3 group relative">
                        {Object.values(DistrictFields).map((value, index) => {
                            if (value === DistrictFields.ID) {
                                return <Fragment key={`th-districts-table-${index}`}></Fragment>;
                            }
                            return (
                                <th key={`th-districts-table-${value}`} className="px-2 py-3 group border-x border-y-0">
                                    {t(`admin.districtsPage.tableColumns.${value}`)}
                                </th>
                            );
                        })}
                        <th className="px-2 py-3 group border-x border-y-0 w-8">
                            <div className="ml-[8px]">
                                <EditPencilIconSVG iconColor="black" />
                            </div>
                        </th>
                        <th className="px-2 py-3 group border-x border-y-0 w-8">
                            <div className="ml-[8px]">
                                <DeleteTrashIconSVG iconColor="black" />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.districts.map((district) => (
                        <DistrictsTableRow
                            key={`tr-districts-table-${district.id}`}
                            district={district}
                            updateHandler={props.updateHandler}
                            deleteHandler={props.deleteHandler}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DistrictsTable;
