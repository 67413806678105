import _ from "lodash";
import { Langs } from "../domain/enums/common/LangsEnum";
import { Archive, ArchivesCollection } from "../domain/models/Archive";
import { ArchiveDTO } from "../domain/models/dto/ArchiveDTO";
import { ArchiveService } from "../domain/services/ArchiveService";
import archiveMapperFactory from "../mappers/ArchiveMapper";
import { RemoveResponse } from "../types/ApiResults";
import { BaseServiceImpl } from "./BaseServiceImpl";

class ArchiveServiceImpl extends BaseServiceImpl implements ArchiveService {
    async getArchives(
        page: number,
        limit: number,
        lang: Langs,
        dto: ArchiveDTO = {} as ArchiveDTO
    ): Promise<ArchivesCollection> {
        try {
            const mapper = archiveMapperFactory();
            let url = `/api/v1/archives/?page=${page}&limit=${limit}&lang=${lang}`;
            Object.entries(dto).map(([key, value]) => {
                if (
                    (key === "type" && _.isNumber(value)) ||
                    (key && key !== "phone" && key !== "email" && value)
                ) {
                    url += `&${key}=${value}`;
                }
            });
            const result = await this.getCollection<ArchiveDTO>(url, []);
            return {
                ...result,
                rows: result.rows.map((item) => mapper.fromDTO(item)),
            };
        } catch (err) {
            console.log("Error in ArchiveServiceImpl.getArchives");
            console.log(err);
        }
    }

    async createArchive(archive: Archive): Promise<Archive> {
        try {
            const mapper = archiveMapperFactory();
            return this.post("/api/v1/archives", mapper.toDTO(archive));
        } catch (err) {
            console.log("Error in ArchiveServiceImpl.createArchive");
            console.log(err);
        }
    }

    async updateArchive(archive: Archive): Promise<Archive> {
        try {
            if (!archive?.id) {
                return null;
            }
            const mapper = archiveMapperFactory();
            return this.patch(
                `/api/v1/archives/${archive.id}`,
                mapper.toDTO(archive)
            );
        } catch (err) {
            console.log("Error in ArchiveServiceImpl.updateArchive");
            console.log(err);
        }
    }

    async deleteArchive(archiveId: string): Promise<RemoveResponse> {
        try {
            if (!archiveId) {
                return { status: false };
            }
            return this.delete(`/api/v1/archives/${archiveId}`);
        } catch (err) {
            console.log("Error in ArchiveServiceImpl.deleteArchive");
            console.log(err);
        }
    }
}

export default function archiveServiceFactory(): ArchiveService {
    return new ArchiveServiceImpl();
}
