import { ChangeEvent, FC, FocusEventHandler } from "react";
import CommonError from "./CommonError";
import { useTranslation } from "react-i18next";

interface CustomTextAreaProps {
    labelKey?: string;
    labelClassName?: string;
    value?: string | number;
    name?: string;
    placeholderKey?: string;
    errorKey?: string;
    disabled?: boolean;
    className?: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: FocusEventHandler;
    onFocus?: FocusEventHandler;
    pattern?: RegExp;
}

const CustomTextArea: FC<CustomTextAreaProps> = (props) => {
    const { t } = useTranslation();

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (props.pattern && !props.pattern.test(e.target.value)) {
            e.preventDefault();
            return;
        }
        props.onChange && props.onChange(e);
    };

    return (
        <div className="flex flex-col">
            {props.labelKey && (
                <label
                    className={`block mb-2 text-lg font-semibold text-[#344054] ${props.labelClassName}`}
                    htmlFor={props.labelKey}
                >
                    {t(props.labelKey)}
                </label>
            )}
            <textarea
                autoComplete="off"
                id={props.labelKey}
                value={props.value}
                name={props.name || ""}
                placeholder={t(props.placeholderKey)}
                onChange={onChange}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                disabled={props.disabled}
                className={`px-3 py-2 text-base text-[#344054] bg-white border-2 border-[#d0d5dd] shadow-sm rounded-lg placeholder-[#667085] ${props.className}`}
            />
            <CommonError errorKey={props.errorKey} name={props.name} />
        </div>
    );
};

export default CustomTextArea;
