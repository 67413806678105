import { FC, Fragment } from "react";
import { Resident } from "../../../domain/models/Resident";
import { useTranslation } from "react-i18next";
import { ResidentFields } from "../../../domain/enums/ResidentFieldsEnum";
import DeleteTrashIconSVG from "../../shared/svg/admin-icons/DeleteTrashIconSVG";
import EditPencilIconSVG from "../../shared/svg/admin-icons/EditPencilIconSVG";
import ResidentsTableRow from "./ResidentsTableRow";

interface ResidentsTableProps {
    residents: Resident[];
    updateHandler: (resident: Resident) => void;
    deleteHandler: (resident: Resident) => void;
}

const ResidentsTable: FC<ResidentsTableProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="w-[95%] h-[calc(100vh-150px)] ml-auto mr-auto relative overflow-auto shadow-md rounded-xl">
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="sticky top-0 text-[14px] font-extralight leading-[16px] text-gray-700 bg-gray-300">
                    <tr className="text-center px-2 py-3 group relative">
                        {Object.values(ResidentFields).map((value, index) => {
                            if (value === ResidentFields.ID) {
                                return <Fragment key={`th-residents-table-${index}`}></Fragment>;
                            }
                            return (
                                <th key={`th-residents-table-${value}`} className="px-2 py-3 group border-x border-y-0">
                                    {t(`admin.residentsPage.tableColumns.${value}`)}
                                </th>
                            );
                        })}
                        <th className="px-2 py-3 group border-x border-y-0 w-8">
                            <div className="ml-[8px]">
                                <EditPencilIconSVG iconColor="black" />
                            </div>
                        </th>
                        <th className="px-2 py-3 group border-x border-y-0 w-8">
                            <div className="ml-[8px]">
                                <DeleteTrashIconSVG iconColor="black" />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.residents.map((resident) => (
                        <ResidentsTableRow
                            key={`tr-residents-table-${resident.id}`}
                            resident={resident}
                            updateHandler={props.updateHandler}
                            deleteHandler={props.deleteHandler}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ResidentsTable;
