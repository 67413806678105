import { FC } from "react";
import { FormikProps } from "formik";
import { SelectValue } from "../../../types/SelectProps";
import CustomDropdown from "../../shared/form-inputs/CustomDropdown";
import { PaginationEnum } from "../../../domain/enums/common/PaginationEnum";
import { enumToSelectProps, enumValueToSelectProps } from "../../../tools/EnumTools";
import { useTranslation } from "react-i18next";
import FilterResetIconSVG from "../../shared/svg/admin-icons/FilterResetIconSVG";
import { ArchiveDTO } from "../../../domain/models/dto/ArchiveDTO";
import ArchiveCreationModalForm from "./ArchiveCreationModalForm";
import ArchivesSearchFiltersForm from "./ArchivesSearchFiltersForm";

interface ArchivesHeaderProps {
    selectedArchive: ArchiveDTO;
    searchFiltersForm: FormikProps<ArchiveDTO>;
    filtersFields: ArchiveDTO;
    resetSelectedArchive: () => void;
    createOrUpdateArchive: (dto: ArchiveDTO) => Promise<void>;
    limitHandler: (value: SelectValue) => void;
    resetFiltersHandler: () => void;
}

const ArchivesHeader: FC<ArchivesHeaderProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="bg-gray-100 w-[95%] mt-[10px] mr-auto mb-[10px] py-2 px-3 ml-auto flex items-center shadow-md rounded-xl">
            <div className="pr-4 border-r-[1px] border-gray-300">
                <ArchiveCreationModalForm
                    selectedArchive={props.selectedArchive}
                    resetSelectedArchive={props.resetSelectedArchive}
                    createOrUpdateArchive={props.createOrUpdateArchive}
                />
            </div>
            <div className="flex items-center pr-4 border-r-[1px] border-gray-300">
                <ArchivesSearchFiltersForm form={props.searchFiltersForm} filtersFields={props.filtersFields} />
                <button
                    className="flex items-center text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center h-10 ml-2"
                    onClick={props.resetFiltersHandler}
                    title={t("admin.common.filtersReset")}
                >
                    <FilterResetIconSVG className="w-[20px] fill-white" />
                </button>
            </div>
            <div className="flex items-center pr-4">
                <h3 className="mr-2 ml-4 text-[16px] font-semibold text-gray-500">{t("pagination.show")}</h3>
                <CustomDropdown
                    defaultValue={enumValueToSelectProps(PaginationEnum.SHOW_20, "pagination")}
                    data={enumToSelectProps(PaginationEnum, "pagination")}
                    dropDownCallback={props.limitHandler}
                    dropdownClassName="w-[80px]"
                />
            </div>
        </div>
    );
};

export default ArchivesHeader;
