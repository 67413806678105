import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { DistrictDTO } from "../domain/models/dto/DistrictDTO";

export const districtsSearchFiltersFields: Fields<DistrictDTO> = {
    districtUk: {
        min: 1,
        max: 200,
        placeholderKey: `admin.districtsPage.tableColumns.districtUk`,
        type: "text",
        initial: "",
        optional: true,
    },
    districtEn: {
        min: 1,
        max: 200,
        placeholderKey: `admin.districtsPage.tableColumns.districtEn`,
        type: "text",
        initial: "",
        optional: true,
    },
    geo: {
        min: 1,
        max: 1000,
        placeholderKey: `admin.districtsPage.tableColumns.geo`,
        type: "text",
        initial: "",
        optional: true,
    },
};

export const districtsSearchFiltersSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, districtsSearchFiltersFields);
};
