import { ChangeUserPasswordDTO, UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import { UserService } from "../domain/services/UserService";
import userMapperFactory from "../mappers/UserMapper";
import { BaseServiceImpl } from "./BaseServiceImpl";

export class UserServiceImpl extends BaseServiceImpl implements UserService {
    static _instance: UserService;
    currentUser?: User;

    private constructor() {
        super();
    }

    static getInstance(): UserService {
        if (!UserServiceImpl._instance) {
            UserServiceImpl._instance = new UserServiceImpl();
        }
        return UserServiceImpl._instance;
    }

    async getUser(): Promise<User> {
        if (!this.currentUser) {
            this.currentUser = await this.getCurrentUser();
        }
        return this.currentUser;
    }

    private async getCurrentUser(): Promise<User | undefined> {
        try {
            return userMapperFactory().fromDTO(await this.get("/api/v1/user"));
        } catch (err) {
            console.log("UserServiceImpl.getCurrentUser => ERROR:");
            console.log(err);
        }
    }

    async login(email: string, password: string): Promise<User> {
        if (!email || !password) {
            return;
        }
        const mapper = userMapperFactory();
        this.currentUser = mapper.fromDTO(
            await this.post<UserDTO>("/api/v1/login", { email, password })
        );
        return this.currentUser;
    }

    async logout(): Promise<boolean> {
        try {
            return !!(await this.get("/api/v1/logout"));
        } catch (err) {
            console.log("UserServiceImpl.logout => ERROR:");
            console.log(err);
        }
    }

    async changeUserPassword(dto: ChangeUserPasswordDTO): Promise<User> {
        if (
            !dto?.currentPassword ||
            !dto?.newPassword ||
            !dto?.confirmNewPassword
        ) {
            return;
        }
        const mapper = userMapperFactory();
        return mapper.fromDTO(
            await this.patch<UserDTO>("/api/v1/user/change-password", dto)
        );
    }
}

export function userServiceFactory(): UserService {
    return UserServiceImpl.getInstance();
}
