import { FC, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AboutPage from "../components/about-page/AboutPage";
import RecommendationsPage from "../components/recommendations-page/RecommendationsPage";
import LoginPage from "../components/admin/login-page/LoginPage";
import DNAGenealogyPage from "../components/dna-genealogy-page/DNAGenealogyPage";
import SearchPage from "../components/search-page/SearchPage";
import ArchivesPage from "../components/archives-page/ArchivesPage";
import ContactsPage from "../components/contacts-page/ContactsPage";

const UnAuthRoutes: FC = () => {
    // Temporal adaptation solution start
    const location = useLocation();
    useEffect(() => {
        const routesWithScale = [
            "/",
            "/about",
            "/login",
            "/recommendations",
            "/dna-genealogy",
            "/search",
            "/archives",
            "/contacts",
        ];

        if (routesWithScale.includes(location.pathname)) {
            const styleTag = document.createElement("style");
            styleTag.id = "dynamic-body-style";
            styleTag.textContent = `
            @media (max-width: 399px) {
                body { transform: scale(0.025); transform-origin: top left; width: 4000%; max-height: 100vh; }
            }
            @media (min-width: 400px) and (max-width: 479px) {
                body { transform: scale(0.05); transform-origin: top left; width: 2000%; max-height: 100vh; }
            }
            @media (min-width: 480px) and (max-width: 559px) {
                body { transform: scale(0.1); transform-origin: top left; width: 1000%; max-height: 100vh; }
            }
            @media (min-width: 560px) and (max-width: 639px) {
                body { transform: scale(0.15); transform-origin: top left; width: 666.67%; max-height: 100vh; }
            }
            @media (min-width: 640px) and (max-width: 719px) {
                body { transform: scale(0.2); transform-origin: top left; width: 500%; max-height: 100vh; }
            }
            @media (min-width: 720px) and (max-width: 799px) {
                body { transform: scale(0.25); transform-origin: top left; width: 400%; max-height: 100vh; }
            }
            @media (min-width: 800px) and (max-width: 879px) {
                body { transform: scale(0.3); transform-origin: top left; width: 333.33%; max-height: 100vh; }
            }
            @media (min-width: 880px) and (max-width: 959px) {
                body { transform: scale(0.35); transform-origin: top left; width: 285.71%; max-height: 100vh; }
            }
            @media (min-width: 960px) and (max-width: 1039px) {
                body { transform: scale(0.4); transform-origin: top left; width: 250%; max-height: 100vh; }
            }
            @media (min-width: 1040px) and (max-width: 1119px) {
                body { transform: scale(0.45); transform-origin: top left; width: 222.22%; max-height: 100vh; }
            }
            @media (min-width: 1120px) and (max-width: 1199px) {
                body { transform: scale(0.5); transform-origin: top left; width: 200%; max-height: 100vh; }
            }
            @media (min-width: 1200px) and (max-width: 1279px) {
                body { transform: scale(0.55); transform-origin: top left; width: 181.82%; max-height: 100vh; }
            }
            @media (min-width: 1280px) and (max-width: 1359px) {
                body { transform: scale(0.6); transform-origin: top left; width: 166.67%; max-height: 100vh; }
            }
            @media (min-width: 1360px) and (max-width: 1439px) {
                body { transform: scale(0.65); transform-origin: top left; width: 153.85%; max-height: 100vh; }
            }
            @media (min-width: 1440px) and (max-width: 1519px) {
                body { transform: scale(0.7); transform-origin: top left; width: 142.86%; max-height: 100vh; }
            }
            @media (min-width: 1520px) and (max-width: 1599px) {
                body { transform: scale(0.8); transform-origin: top left; width: 125%; max-height: 100vh; }
            }
            @media (min-width: 1600px) and (max-width: 1679px) {
                body { transform: scale(0.85); transform-origin: top left; width: 117.65%; max-height: 100vh; }
            }
            @media (min-width: 1680px) and (max-width: 1759px) {
                body { transform: scale(0.9); transform-origin: top left; width: 111.11%; max-height: 100vh; }
            }
            @media (min-width: 1760px) and (max-width: 1839px) {
                body { transform: scale(0.95); transform-origin: top left; width: 105.26%; max-height: 100vh; }
            }
            @media (min-width: 1840px) and (max-width: 1920px) {
                body { transform: scale(1); transform-origin: top left; width: 100%; max-height: 100vh; }
            }
        `;
            document.head.appendChild(styleTag);
        } else {
            const existingStyleTag = document.getElementById("dynamic-body-style");
            if (existingStyleTag) {
                existingStyleTag.remove();
            }
        }

        return () => {
            const existingStyleTag = document.getElementById("dynamic-body-style");
            if (existingStyleTag) {
                existingStyleTag.remove();
            }
        };
    }, [location]);
    // Temporal adaptation solution finish
    return (
        <Routes>
            <Route path="/" element={<AboutPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/recommendations" element={<RecommendationsPage />} />
            <Route path="/dna-genealogy" element={<DNAGenealogyPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/archives" element={<ArchivesPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
        </Routes>
    );
};

export default UnAuthRoutes;
