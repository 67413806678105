import { TFunction } from "i18next";
import { ChangeUserPasswordDTO } from "../domain/models/dto/UserDTO";
import { Fields } from "../types/SchemaFactory";
import { schemaFactory } from "./SchemaFactory";

export const changeUserPasswordFields: Fields<ChangeUserPasswordDTO> = {
    currentPassword: {
        min: 8,
        max: 24,
        placeholderKey: `admin.settingsPage.changePassword.currentPassword`,
        type: "password",
        initial: "",
    },
    newPassword: {
        min: 8,
        max: 24,
        placeholderKey: `admin.settingsPage.changePassword.newPassword`,
        type: "password",
        initial: "",
    },
    confirmNewPassword: {
        min: 8,
        max: 24,
        placeholderKey: `admin.settingsPage.changePassword.confirmNewPassword`,
        type: "password",
        initial: "",
    },
};

export const changeUserPasswordSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, changeUserPasswordFields);
};
