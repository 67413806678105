import {
    defaultAutocompletePatternMin,
    defaultProjectEmail,
} from "./domain/constants";
import { environment } from "./environments/environment";

export const apiURL = environment.API_URL;

export const projectEmail = environment.PROJECT_EMAIL || defaultProjectEmail;
export const projectFacebook = environment.PROJECT_FACEBOOK;

export const autocompletePatternMin =
    +environment.AUTOCOMPLETE_PATTERN_MIN || defaultAutocompletePatternMin;

export const paypal = environment.PAYPAL;
export const privatBankCard = environment.PRIVATBANK_CARD;
export const accountOwner = environment.ACCOUNT_OWNER;

export const useCustomTransform = environment.USE_CUSTOM_TRANSFORM === "true";
