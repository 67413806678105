import { FC } from "react";

const ButtonActionArrowSVG: FC = () => {
    return (
        <svg width="43" height="8" viewBox="0 0 43 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M42.3133 4.30939C42.4842 4.13852 42.4842 3.86149 42.3133 3.69062L39.5288 0.906124C39.3579 0.735253 39.0809 0.735253 38.91 0.906123C38.7392 1.07699 38.7392 1.35403 38.91 1.5249L41.3851 4L38.91 6.47511C38.7392 6.64598 38.7392 6.92301 38.91 7.09388C39.0809 7.26475 39.3579 7.26475 39.5288 7.09388L42.3133 4.30939ZM-3.8251e-08 4.43754L42.0039 4.43754L42.0039 3.56246L3.8251e-08 3.56246L-3.8251e-08 4.43754Z"
                fill="url(#paint0_linear_2206_33)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2206_33"
                    x1="-4.37114e-08"
                    y1="4.5"
                    x2="42.0039"
                    y2="4.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF2D2" />
                    <stop offset="1" stopColor="#EBDCB5" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ButtonActionArrowSVG;
