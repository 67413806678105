import { FC, useState } from "react";
import { useAuth } from "../../../context/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import LangSelection from "../../header/LangSelect";
import HomeButtonSVG from "../../shared/svg/HomeButton";
import TogglePassword from "../../shared/form-inputs/TogglePassword";
import { useTranslation } from "react-i18next";
import { userLoginFields, userLoginSchemaFactory } from "../../../validation-schemas/UserLoginSchema";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";

const LoginPage: FC = () => {
    const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);
    const { signIn } = useAuth();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { form } = useCustomFormik({
        initial: null,
        fields: userLoginFields,
        schema: userLoginSchemaFactory(t),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);
            setError(null);

            if (!values.email || !values.password) {
                return;
            }

            try {
                const user = await signIn(values.email || "", values.password || "");
                if (!user) {
                    setError("errors.common");
                    return;
                }
                navigate("/admin");
            } catch (err) {
                setError("errors.common");
                return;
            }
        },
    });

    return (
        <div className="flex items-center justify-center h-[calc(100vh-200px)]">
            <div className="max-w-sm bg-gray-200 px-[24px] py-[24px] rounded-xl">
                <div className="flex items-center justify-between w-[400px]">
                    <Link to={"/"}>
                        <HomeButtonSVG fill="#000" />
                    </Link>
                    <LangSelection
                        additionalButtonClassName="absolute z-10 right-[65px]"
                        additionalPopupClassName="absolute z-10 right-[65px] top-[10px] bg-gray-100 shadow-md"
                    />
                </div>
                <form id="enterForm" onSubmit={form.handleSubmit}>
                    <h1 className="block mt-4 mb-4 text-[22px] font-normal text-gray-700">{t("admin.login.title")}</h1>
                    <input
                        className={`bg-gray-100 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                            form.touched.email && form.errors.email && "bg-red-100"
                        }`}
                        type="input"
                        placeholder={t("admin.login.email")}
                        name="email"
                        id="id_email"
                        autoComplete="email"
                        onChange={form.handleChange}
                        defaultValue={form.values.email}
                    />

                    <p className={form.touched.email && form.errors.email ? "mt-[-10px] mb-[5px]" : "mt-[15px]"}>
                        {form.touched.email && form.errors.email}
                    </p>
                    <div className="flex items-center justify-between mb-4">
                        <input
                            className={`bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[calc(100%-44px)] p-2.5 ${
                                form.touched.password && form.errors.password && "bg-red-100"
                            }`}
                            id="id_password"
                            type={!isPasswordVisible ? "password" : "input"}
                            placeholder={t("admin.login.password")}
                            name="password"
                            onChange={form.handleChange}
                            defaultValue={form.values.password}
                        />

                        <TogglePassword
                            isPasswordVisible={isPasswordVisible}
                            setPasswordVisible={setPasswordVisibility}
                        />
                    </div>
                    <p className={form.touched.password && form.errors.password ? "mt-[-10px] mb-[5px]" : "mt-[15px]"}>
                        {form.touched.password && form.errors.password}
                    </p>
                    <p className={error ? "mt-[-10px] mb-[5px]" : "mt-[15px]"}>{error && t(error)}</p>
                    <button
                        className="flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center h-[42px]"
                        type="submit"
                    >
                        {t("admin.login.action")}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
