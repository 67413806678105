import { FC, useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ArchivesIntro from "./ArchivesIntro";
import ArchivesList from "./ArchivesList";
import LoadingPanel from "../shared/loading-panel/LoadingPanel";
import archiveServiceFactory from "../../services/ArchiveServiceImpl";
import { DEFAULT_PAGE, GET_ALL_RECORDS } from "../../domain/constants";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { ArchivesCollection } from "../../domain/models/Archive";
import { NullableCollection } from "../../types/ApiResults";
import { ArchivesTypes } from "../../domain/enums/ArchivesTypesEnum";
import { ArchiveDTO } from "../../domain/models/dto/ArchiveDTO";

const archiveService = archiveServiceFactory();
const langService = languageServiceFactory();

const ArchivesPage: FC = () => {
    const [loading, setLoading] = useState(false);
    const [regionalArchives, setRegionalArchives] = useState<ArchivesCollection>(NullableCollection);
    const [centralArchives, setCentralArchives] = useState<ArchivesCollection>(NullableCollection);
    const [sectoralArchives, setSectoralArchives] = useState<ArchivesCollection>(NullableCollection);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const [regional, central, sectoral] = await Promise.all([
                archiveService.getArchives(GET_ALL_RECORDS, DEFAULT_PAGE, langService.currentLang, {
                    type: ArchivesTypes.REGIONAL,
                } as ArchiveDTO),
                archiveService.getArchives(GET_ALL_RECORDS, DEFAULT_PAGE, langService.currentLang, {
                    type: ArchivesTypes.CENTRAL,
                } as ArchiveDTO),
                archiveService.getArchives(GET_ALL_RECORDS, DEFAULT_PAGE, langService.currentLang, {
                    type: ArchivesTypes.SECTORAL,
                } as ArchiveDTO),
            ]);
            setRegionalArchives(regional);
            setCentralArchives(central);
            setSectoralArchives(sectoral);
            setLoading(false);
        })();
    }, []);

    return (
        <>
            <LoadingPanel isVisible={loading} />
            <Header />
            <ArchivesIntro />
            <ArchivesList
                regional={regionalArchives.rows}
                central={centralArchives.rows}
                sectoral={sectoralArchives.rows}
            />
            <Footer />
        </>
    );
};

export default ArchivesPage;
