import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";

const langService: LanguageService = languageServiceFactory();

const GenerationalHistorySection: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="bg-generational-history-section-gradient text-center h-[1319px] overflow-x-clip">
            <h2
                className={`relative font-cormorant font-light ${
                    langService.isUkrainian ? "text-[106px]" : "text-[82px]"
                } text-beige6 leading-[110.3px] pt-[118px] z-10`}
            >
                {t("aboutPage.generationalHistorySection.text1")}
            </h2>
            <div className="absolute left-0 right-0 mx-auto max-w-[1840px] h-full flex justify-between overflow-x-clip">
                <div className="relative left-[40px]">
                    <div className="absolute left-[410px] top-[-20px] w-[385px] h-[470px] transform rotate-[10.5deg] bg-gradient-to-b from-[#A69168] to-[#A18767] p-[20px] shadow-familyPhoto">
                        <img
                            className="max-w-none w-full"
                            src="/img/about-page/generationalHistorySection3.png"
                            alt="Top"
                        />
                    </div>
                    <div className="absolute left-[70px] top-[190px] w-[405px] h-[525px] transform rotate-[-15.7deg] bg-gradient-to-b from-[#D5C79C] to-[#B69B7B] p-[20px] shadow-familyPhoto">
                        <img
                            className="max-w-none w-full"
                            src="/img/about-page/generationalHistorySection1.png"
                            alt="Middle"
                        />
                    </div>
                    <div className="absolute left-[445px] top-[375px] w-[470px] h-[580px] transform rotate-[16.5deg] bg-gradient-to-b from-[#D7CAA1] to-[#B69B7B] p-[20px] shadow-familyPhoto">
                        <img
                            className="max-w-none w-full"
                            src="/img/about-page/generationalHistorySection2.png"
                            alt="Bottom"
                        />
                    </div>
                </div>
                <div className="text-left w-[590px] mr-[165px]">
                    <p className="font-manrope font-thin text-[22px] text-beige6 leading-[32px] mt-[37px] w-[575px]">
                        {t("aboutPage.generationalHistorySection.text2")}{" "}
                        <span className="font-semibold">{t("aboutPage.generationalHistorySection.text3")}</span>
                    </p>
                    <p className="font-liana text-[35.5px] leading-[50.5px] text-beige6 mt-[12px]">
                        {t("aboutPage.generationalHistorySection.text4")}
                    </p>
                    <p className="font-manrope font-thin text-[22px] text-beige6 leading-[32px] mt-[37px] w-[520px]">
                        {t("aboutPage.generationalHistorySection.text5")}
                    </p>
                    <p className="relative font-cormorant font-light text-[106px] text-beige6 leading-[110.3px] pt-[118px]">
                        {t("aboutPage.generationalHistorySection.text6")}
                    </p>
                    <p className="font-manrope font-thin text-[22px] text-beige6 leading-[32px] mt-[37px] w-[540px]">
                        {t("aboutPage.generationalHistorySection.text7")}{" "}
                        <span className="font-semibold">{t("aboutPage.generationalHistorySection.text8")} </span>
                        {t("aboutPage.generationalHistorySection.text9")}{" "}
                        <span className="font-semibold">{t("aboutPage.generationalHistorySection.text10")} </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GenerationalHistorySection;
