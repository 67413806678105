import { FC } from "react";

interface MiddleCalloutLineSVGProps {
    className?: string;
}

const MiddleCalloutLineSVG: FC<MiddleCalloutLineSVGProps> = (props) => {
    return (
        <svg
            className={props.className}
            width="272"
            height="9"
            viewBox="0 0 272 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3 6L42.5 1H272" stroke="#6A4F3E" />
            <circle cx="3" cy="6" r="3" fill="#6A4F3E" />
        </svg>
    );
};

export default MiddleCalloutLineSVG;
