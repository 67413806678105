import { FC } from "react";

interface ShowMoreArrowSVGProps {
    className?: string;
}

const ShowMoreArrowSVG: FC<ShowMoreArrowSVGProps> = (props) => {
    return (
        <svg
            className={props.className}
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="26.8442" cy="26.8442" r="26.3442" stroke="#6A4F3E" />
            <path d="M12.5505 20.9176L27.1928 35.5599L41.8351 20.9176" stroke="#6A4F3E" />
        </svg>
    );
};

export default ShowMoreArrowSVG;
