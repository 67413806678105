import { FC } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ContactsIntro from "./ContactsIntro";
import ContributionSection from "../search-page/ContributionSection";
// import ContactsSendEmail from "./ContactsSendEmail";

const ContactsPage: FC = () => {
    return (
        <>
            <Header />
            <ContactsIntro />
            <ContributionSection />
            {/* <ContactsSendEmail /> */}
            <Footer />
        </>
    );
};

export default ContactsPage;
