import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";

const langService: LanguageService = languageServiceFactory();

const InterviewInstructions: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="relative bg-beige1 h-[865px] w-full">
            <div className="max-w-[1910px] mx-auto h-full flex justify-between overflow-x-clip">
                <div className="relative text-left pl-[170px] pt-[129px] pr-[34px] pb-[159px]">
                    <p className="font-liana text-[27px] leading-[29.4px] w-[750px]">
                        {t("searchRecommendationsPage.interviewInstructions.text1")}
                    </p>
                    <p
                        className={`font-cormorant ${
                            langService.isUkrainian ? "text-[45px]" : "text-[40px]"
                        } text-brown3 leading-[54.5px] mt-[57px]`}
                    >
                        {t("searchRecommendationsPage.interviewInstructions.text2")}
                    </p>
                    <ol className="list-decimal list-inside w-[750px]">
                        <li className="font-manrope text-[22px] text-brown3 leading-[31.9px] mt-[35px]">
                            {t("searchRecommendationsPage.interviewInstructions.text3")}{" "}
                            <span className="font-bold">
                                {t("searchRecommendationsPage.interviewInstructions.text4")}
                            </span>
                        </li>
                        <li className="font-manrope text-[22px] text-brown3 leading-[31.9px] mt-[35px]">
                            {t("searchRecommendationsPage.interviewInstructions.text5")}{" "}
                            <span className="font-bold">
                                {t("searchRecommendationsPage.interviewInstructions.text6")}{" "}
                            </span>
                            {t("searchRecommendationsPage.interviewInstructions.text7")}{" "}
                            <span className="font-bold">
                                {t("searchRecommendationsPage.interviewInstructions.text8")}{" "}
                            </span>
                            {t("searchRecommendationsPage.interviewInstructions.text9")}{" "}
                            <span className="font-bold">
                                {t("searchRecommendationsPage.interviewInstructions.text10")}{" "}
                            </span>
                            {t("searchRecommendationsPage.interviewInstructions.text11")}
                        </li>
                        <li className="font-manrope text-[22px] text-brown3 leading-[31.9px] mt-[35px]">
                            {t("searchRecommendationsPage.interviewInstructions.text12")}{" "}
                            <span className="font-bold">
                                {t("searchRecommendationsPage.interviewInstructions.text13")}{" "}
                            </span>
                            {t("searchRecommendationsPage.interviewInstructions.text14")}{" "}
                            <span className="font-bold">
                                {t("searchRecommendationsPage.interviewInstructions.text15")}{" "}
                            </span>
                            {t("searchRecommendationsPage.interviewInstructions.text16")}
                        </li>
                    </ol>
                </div>
                <img src="/img/recommendations-page/interviewInstructions.png" alt="Interview Instructions" />
            </div>
        </div>
    );
};

export default InterviewInstructions;
