import { FC } from "react";
import { useTranslation } from "react-i18next";

const ArchivesIntro: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="relative z-0 bg-beige1 h-[951px] overflow-hidden">
            <div className="absolute left-0 right-0 mx-auto w-[1910px] h-full">
                <p className="absolute left-[194px] bottom-[775px] font-cormorant font-thin text-[261px] bg-clip-text text-[transparent] bg-search-intro-gradient-1 leading-[232.4px]">
                    {t("archivesPage.archivesIntro.text1")}
                </p>
                <img
                    className="absolute left-[-5px] top-[45px]"
                    src="/img/archives-page/archivesIntro.png"
                    alt="Archives Intro"
                />
                <p className="absolute top-[191px] right-[195px] font-cormorant font-medium text-[52.8px] leading-[64px] text-brown3 w-[750px]">
                    {t("archivesPage.archivesIntro.text2")}{" "}
                    <span className="font-bold italic">{t("archivesPage.archivesIntro.text3")} </span>
                    {t("archivesPage.archivesIntro.text4")}{" "}
                    <span className="font-bold italic">{t("archivesPage.archivesIntro.text5")} </span>
                    {t("archivesPage.archivesIntro.text6")}
                </p>
                <p className="absolute top-[566px] right-[252px] font-manrope font-medium text-[22px] leading-[32px] text-brown3 w-[433px]">
                    {t("archivesPage.archivesIntro.text7")}
                </p>
                <p className="absolute top-[749px] right-[289px] font-liana font-medium text-[29.7px] leading-[29.7px] text-brown3 w-[396px]">
                    {t("archivesPage.archivesIntro.text8")}
                </p>
                <p className="absolute bottom-[180px] right-[100px] font-liana font-medium text-[62.3px] bg-clip-text text-[transparent] bg-search-intro-gradient-1 leading-[55px] transform rotate-[90deg] pl-[10px]">
                    {t("archivesPage.archivesIntro.text9")}
                </p>
            </div>
        </div>
    );
};

export default ArchivesIntro;
