import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";

const langService: LanguageService = languageServiceFactory();

const DNAIntro: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="relative bg-beige1 h-[951px] overflow-visible overflow-x-clip">
            <div className="absolute left-0 right-0 h-full mx-auto w-[1840px] flex justify-between">
                <div className="z-10">
                    <img
                        className="absolute left-[-35px]"
                        src="/img/dna-genealogy-page/DNAIntro.png"
                        alt="Search Intro"
                    />
                </div>
                <div className="relative text-left">
                    <p className="font-cormorant font-thin text-[122px] bg-clip-text text-[transparent] bg-search-intro-gradient-1 leading-[147.7px] mt-[139px] mr-[260px]">
                        {t("DNAGenealogyPage.DNAIntro.text1")}{" "}
                    </p>
                    <p
                        className={`font-cormorant font-medium ${
                            langService.isUkrainian ? "text-[44px]" : "text-[40px]"
                        } leading-[47px] text-brown3 mt-[58px] w-[829px]`}
                    >
                        {t("DNAGenealogyPage.DNAIntro.text2")}{" "}
                    </p>
                    <p className="font-liana font-medium text-[50px] leading-[53.5px] text-brown3 mt-[123px]">
                        {t("DNAGenealogyPage.DNAIntro.text3")}{" "}
                    </p>
                    <p className="font-liana font-medium text-[50px] leading-[53.5px] text-brown3">
                        {t("DNAGenealogyPage.DNAIntro.text4")}{" "}
                    </p>
                    <p className="absolute right-3 bottom-[400px] font-liana font-medium text-[62.3px] bg-clip-text text-[transparent] bg-search-intro-gradient-1 leading-[55px] transform rotate-[90deg] pl-[20px]">
                        {t("DNAGenealogyPage.DNAIntro.text5")}{" "}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DNAIntro;
