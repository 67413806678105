import { FC, useEffect, useState } from "react";
import * as d3 from "d3";
import { GeoJSON } from "../../types/GeoJSON";
import { FeatureCollection, Feature, MultiPolygon } from "geojson";

interface MapComponentProps {
    geoData: GeoJSON[];
    currentDistrictId: string;
    setCurrentDistrictId: (value: string) => void;
}

const MapComponent: FC<MapComponentProps> = (props) => {
    const [UkraineBorder, setUkraineBorder] = useState<FeatureCollection<MultiPolygon> | null>(null);
    const [regionsBorders, setRegionsBorders] = useState<FeatureCollection<MultiPolygon> | null>(null);

    useEffect(() => {
        fetch("json/country.json")
            .then((response) => response.json())
            .then((data) => setUkraineBorder(data))
            .catch((error) => console.error(error));

        fetch("json/regions.json")
            .then((response) => response.json())
            .then((data) => setRegionsBorders(data))
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        if (!props.geoData?.length || !UkraineBorder || !regionsBorders) {
            return;
        }

        const width = 1050;
        const height = 800;

        const element = d3.select("#map svg");
        if (!element.empty()) {
            return;
        }

        const svg = d3.select("#map").append("svg").attr("width", width).attr("height", height);

        const projection = d3
            .geoMercator()
            .center([31.1053, 48.3206])
            .scale(3125)
            .translate([width / 2, height / 2]);

        const path = d3.geoPath().projection(projection);

        svg.append("path")
            .datum(UkraineBorder?.features[0])
            .attr("d", path)
            .attr("fill", "none")
            .attr("stroke", "#7c7662")
            .attr("stroke-width", 3);

        const features: Feature<MultiPolygon, { id: number; districtId: string; color: string }>[] = props.geoData.map(
            (polygon, index) => ({
                type: "Feature",
                properties: { id: index, districtId: polygon.id, color: polygon.color || "#F8E9C0" },
                geometry: polygon,
            })
        );

        svg.selectAll(".district")
            .data(features)
            .enter()
            .append("a")
            .append("path")
            .attr("class", "district")
            .attr("d", path)
            .attr("fill", (d) => (props.currentDistrictId === d.properties.districtId ? "#ff0000" : d.properties.color))
            .attr("stroke", "#7c7662")
            .attr("stroke-width", 0.1)
            .style("cursor", "pointer")
            .on("click", (_e, d) => props.setCurrentDistrictId(d.properties.districtId))
            .on("mouseover", function (_e, _d) {
                d3.select(this).attr("fill", "#fff");
            })
            .on("mouseout", function (_e, d) {
                d3.select(this).attr(
                    "fill",
                    props.currentDistrictId === d.properties.districtId ? "#ff0000" : d.properties.color
                );
            });

        svg.selectAll("path.region")
            .data(regionsBorders?.features)
            .enter()
            .append("path")
            .attr("class", "region")
            .attr("d", path)
            .attr("fill", "none")
            .attr("stroke", "#7c7662")
            .attr("stroke-width", 1);

        return () => {
            d3.select("#map svg").remove();
        };
    }, [props.geoData, UkraineBorder, regionsBorders]);
    return <div className="w-[1072px] bg-beige1" id="map"></div>;
};

export default MapComponent;
