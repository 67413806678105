import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResidentAutocompleteDTO, SearchResidentsDTO } from "../../domain/models/dto/ResidentDTO";
import { autocompletePatternMin } from "../../Config";
import { DEFAULT_PAGE, GET_ALL_RECORDS } from "../../domain/constants";
import residentServiceFactory from "../../services/ResidentServiceImpl";
import CustomInput from "../shared/form-inputs/CustomInput";
import useCustomFormik from "../../custom-hooks/useCustomFormik";
import { SearchResidentsForm as ISearchResidentsForm } from "../../domain/models/Resident";
import { searchResidentsFields, searchResidentsSchemaFactory } from "../../validation-schemas/SearchResidentsSchema";
import { InputType } from "../../types/InputType";
import LoadingPanel from "../shared/loading-panel/LoadingPanel";
import InputTipSVG from "../shared/svg/InputTipSVG";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { useClickOutOfElement } from "../../custom-hooks/base-hooks/useClickOutOfElement";
import Tooltip from "../shared/Tooltip";
import { NameFormattingServiceImpl } from "../../services/NameFormattingService";
import { useSearchParams } from "react-router-dom";

interface SearchResidentsFormProps {
    setResidents: Dispatch<SetStateAction<SearchResidentsDTO[]>>;
    setFathersMode: (value: boolean) => void;
    setMothersMode: (value: boolean) => void;
    resetMapLayers: () => void;
    setFileName: (value: string) => void;
}

const residentService = residentServiceFactory();
const langService = languageServiceFactory();

const SearchResidentsForm: FC<SearchResidentsFormProps> = (props) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [autocompleted, setAutocompleted] = useState<ResidentAutocompleteDTO[]>([]);

    const getAutocompletedName = (dto: ResidentAutocompleteDTO) => {
        return dto.lastNameUk || dto.lastNameEn;
    };

    const { form } = useCustomFormik<ISearchResidentsForm>({
        initial: {
            lastNameFathers: searchParams.get("lastNameFathers") || "",
            lastNameMothers: searchParams.get("lastNameMothers") || "",
        },
        fields: searchResidentsFields,
        schema: searchResidentsSchemaFactory(t),
        onSubmit: async (values, handlers) => {
            if (!values.lastNameFathers && !values.lastNameMothers) {
                return;
            }
            setLoading(true);
            props.resetMapLayers();
            const result = await residentService.searchResidents(values, langService.currentLang);
            if (result) {
                props.setResidents(result);
            }
            handlers.setTouched({});
            setLoading(false);

            let fileName = "";
            if (values.lastNameFathers) {
                fileName += NameFormattingServiceImpl.normalizeName(values.lastNameFathers);
                props.setFathersMode(true);
            }
            if (values.lastNameMothers) {
                fileName += NameFormattingServiceImpl.normalizeName(
                    values.lastNameMothers,
                    values.lastNameFathers ? "_" : ""
                );
                props.setMothersMode(true);
            }
            props.setFileName(fileName);

            const newParams = new URLSearchParams(searchParams);
            newParams.set("lastNameFathers", values.lastNameFathers || "");
            newParams.set("lastNameMothers", values.lastNameMothers || "");
            setSearchParams(newParams);
        },
    });

    useEffect(() => {
        if (!!searchParams.get("lastNameFathers") || !!searchParams.get("lastNameMothers")) {
            form.handleSubmit();
        }
    }, []);

    const currentField = useMemo(() => {
        const res = Object.entries(form.touched).find(([_key, value]) => !!value);
        return res?.length ? res[0] : "";
    }, [form.touched]);

    const lastNameHandler = (field: keyof ISearchResidentsForm) => {
        return async (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            form.setFieldValue(field, value);
            if (value.length < autocompletePatternMin) {
                setAutocompleted([]);
                return;
            }
            // timeoutService.updateTimeout(async () => {
            setAutocompleted(
                await residentService.autocompleteNames(GET_ALL_RECORDS, DEFAULT_PAGE, value, langService.currentLang)
            );
            // }, AUTOCOMPLETE_TIMEOUT_MS);
        };
    };

    const lastNameOnFocus = (field: keyof ISearchResidentsForm) => {
        return () => {
            setAutocompleted([]);
            form.setTouched({ [field]: !form.touched[field] });
        };
    };

    const applyAutocompleted = (item: ResidentAutocompleteDTO) => {
        form.setTouched({});
        form.setFieldValue(currentField, getAutocompletedName(item));
    };

    const getLastNameStyles = () => {
        return currentField === "lastNameMothers" ? " left-[388px]" : "";
    };

    const autocompletePopupRef = useRef();
    useClickOutOfElement(autocompletePopupRef, () => {
        form.setTouched({});
    });

    return (
        <div className="relative flex items-center ml-[31px]">
            <LoadingPanel isVisible={loading} />
            <CustomInput
                type={searchResidentsFields.lastNameFathers.type as InputType}
                labelKey={searchResidentsFields.lastNameFathers.placeholderKey}
                labelClassName="font-manrope !font-normal text-[22px] leading-[32px] text-brown3 mb-0"
                value={form.values.lastNameFathers}
                name="lastNameFathers"
                errorKey={form.errors.lastNameFathers}
                onChange={lastNameHandler("lastNameFathers")}
                onFocus={lastNameOnFocus("lastNameFathers")}
                className="!bg-beige1 font-manrope !text-brown3 border-l-0 border-t-0 border-r-0 border-b rounded-none !h-[40px] w-[360px] border-brown3 shadow-none focus:outline-none focus:border-l-transparent focus:border-t-transparent focus:border-r-transparent focus:border-b-brown3 focus:ring-0 mr-[28px]"
            />
            <Tooltip
                children={<InputTipSVG className="absolute left-[330px] top-[40px] cursor-help" />}
                content={t("surnameMapPage.surnameMapSection.text16")}
                wrapperClassName="font-manrope text-brown3 border-b border-brown3 bg-beige4 shadow-beige4Section min-h-[40px] w-[260px] px-[10px] py-[5px] bottom-[70px] left-[100px]"
            />
            <CustomInput
                type={searchResidentsFields.lastNameMothers.type as InputType}
                labelKey={searchResidentsFields.lastNameMothers.placeholderKey}
                labelClassName="font-manrope !font-normal text-[22px] leading-[32px] text-brown3 mb-0"
                value={form.values.lastNameMothers}
                name="lastNameMothers"
                errorKey={form.errors.lastNameMothers}
                onChange={lastNameHandler("lastNameMothers")}
                onFocus={lastNameOnFocus("lastNameMothers")}
                className="!bg-beige1 font-manrope !text-brown3 border-l-0 border-t-0 border-r-0 border-b rounded-none !h-[40px] w-[360px] border-brown3 shadow-none focus:outline-none focus:border-l-transparent focus:border-t-transparent focus:border-r-transparent focus:border-b-brown3 focus:ring-0 mr-[28px]"
            />
            <Tooltip
                children={<InputTipSVG className="absolute left-[720px] top-[40px] cursor-help" />}
                content={t("surnameMapPage.surnameMapSection.text17")}
                wrapperClassName="font-manrope text-brown3 border-b border-brown3 bg-beige4 shadow-beige4Section min-h-[40px] w-[260px] px-[10px] py-[5px] bottom-[70px] left-[490px]"
            />
            <button
                type="button"
                onClick={() => form.handleSubmit()}
                className="bg-button-gradient w-[200px] h-[76px] font-semibold py-2.5 px-6 rounded-full mb-[20px]"
            >
                <div className="flex justify-center items-center">
                    <p className="font-cormorant leading-[34px] text-[26px] bg-clip-text text-[transparent] bg-button-text-gradient mr-[10px]">
                        {t("surnameMapPage.surnameMapSection.search")}
                    </p>
                </div>
            </button>
            {currentField && autocompleted.length && !loading ? (
                <div
                    ref={autocompletePopupRef}
                    className={
                        "absolute z-10 top-[75px] bg-beige4 w-[360px] h-[250px] shadow-beige4Section flex flex-col outline-none overflow-y-scroll" +
                        getLastNameStyles()
                    }
                >
                    {autocompleted.map((item, index) => (
                        <div
                            key={"mapPage-autocomplete-lastName-" + index}
                            className="cursor-pointer font-manrope px-[10px] py-[4px] text-[22px] text-brown3 leading-[32px] hover:bg-beige1"
                        >
                            <p onClick={() => applyAutocompleted(item)}>{getAutocompletedName(item)}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default SearchResidentsForm;
