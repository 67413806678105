import { FC, useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SurnameMapSection from "./SurnameMapSection";
import ContributionSection from "./ContributionSection";
import districtServiceFactory from "../../services/DistrictServiceImpl";
import LoadingPanel from "../shared/loading-panel/LoadingPanel";
import { DistrictsCollection } from "../../domain/models/District";
import { NullableCollection } from "../../types/ApiResults";

const districtService = districtServiceFactory();

const SearchPage: FC = () => {
    const [loading, setLoading] = useState(false);
    const [districts, setDistricts] = useState<DistrictsCollection>(NullableCollection);
    useEffect(() => {
        (async () => {
            setLoading(true);
            setDistricts(await districtService.getAllDistricts());
            setLoading(false);
        })();
    }, []);

    return (
        <>
            <LoadingPanel isVisible={loading} />
            <Header />
            <SurnameMapSection districts={districts.rows} />
            <ContributionSection />
            <Footer />
        </>
    );
};

export default SearchPage;
