import _ from "lodash";
import { District } from "../domain/models/District";
import { DistrictDTO } from "../domain/models/dto/DistrictDTO";
import { GeoJSON } from "../types/GeoJSON";
import { BaseModelImpl } from "./BaseModelImpl";

export default class DistrictModel
    extends BaseModelImpl<DistrictDTO>
    implements District
{
    constructor(data?: DistrictDTO) {
        super();
        this.assignData(data);
    }

    get district() {
        if (!this.districtUk && !this.districtEn) {
            return "";
        }
        return (
            _.get(this, "districtUk", "") + "/" + _.get(this, "districtEn", "")
        );
    }

    get geoStr() {
        if (!this.geo) {
            return "";
        }
        return JSON.stringify(_.get(this, "geo", {}));
    }

    id: string;
    districtUk: string;
    districtEn: string;
    geo: GeoJSON;
    createdAt: string;
    updatedAt: string;
}
