import { FC } from "react";
import { useTranslation } from "react-i18next";
import ArchivesTableRow from "./ArchivesTableRow";
import { Archive } from "../../domain/models/Archive";

interface CentralArchivesTableProps {
    archives: Archive[];
}

const CentralArchivesTable: FC<CentralArchivesTableProps> = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <p className="font-cormorant text-[93px] leading-[82.8px] text-brown3 mb-[75px]">
                {t("archivesPage.central")}
            </p>
            <div className="grid grid-cols-2 border border-b-0 border-r-0 border-brown3">
                {props.archives.map((archive, index) => (
                    <ArchivesTableRow archive={archive} index={index} key={`central-archives-table-row-${index}`} />
                ))}
            </div>
        </>
    );
};

export default CentralArchivesTable;
