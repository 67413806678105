import { Dispatch, FC, SetStateAction, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthProvider";
import AdminLangSelect from "./AdminLangSelect";
import { useClickOutOfElement } from "../../custom-hooks/base-hooks/useClickOutOfElement";
import MenuIconSVG from "../shared/svg/admin-icons/MenuIconSVG";
import SidebarLogoutSVG from "../shared/svg/admin-icons/SidebarLogoutSVG";
import ResidentsPageIconSVG from "../shared/svg/admin-icons/ResidentsPageIconSVG";
import { Link, useLocation } from "react-router-dom";
import ArchivesPageIconSVG from "../shared/svg/admin-icons/ArchivesPageIconSVG";
import DistrictsPageIconSVG from "../shared/svg/admin-icons/DistrictsPageIconSVG";
import SettingsPageIconSVG from "../shared/svg/admin-icons/SettingsPageIconSVG";

interface AdminSidebarProps {
    isExpanded: boolean;
    setIsExpanded: Dispatch<SetStateAction<boolean>>;
}

const AdminSidebar: FC<AdminSidebarProps> = (props) => {
    const { t } = useTranslation();
    const { signOut } = useAuth();
    const sidebarRef = useRef(null);
    const location = useLocation()?.pathname;
    useClickOutOfElement(sidebarRef, () => props.setIsExpanded(false));

    const residents = location.includes("/admin/residents");
    const archives = location.includes("/admin/archives");
    const districts = location.includes("/admin/districts");
    const settings = location.includes("/admin/settings");

    return (
        <div
            ref={sidebarRef}
            className={`absolute z-[100] flex flex-col items-center justify-between h-full bg-gray-200 transition-all ${
                props.isExpanded ? "w-[260px]" : "w-[60px]"
            } ${!props.isExpanded ? "w-[60px]" : "w-[260px]"}`}
        >
            <div className="relative w-full">
                <div
                    title={t("admin.sidebar.expand")}
                    className={`flex items-center cursor-pointer ${
                        props.isExpanded ? "justify-start pl-[20px]" : "justify-center"
                    } hover:bg-gray-300 w-full h-[60px]`}
                    onClick={() => props.setIsExpanded(!props.isExpanded)}
                >
                    <MenuIconSVG className="absolute left-[20px] fill-black w-5" />
                    {props.isExpanded ? (
                        <p className="whitespace-nowrap font-manrope font-bold text-[18px] text-gray-600 ml-[35px]">
                            {t("admin.sidebar.menu")}
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
                <Link
                    title={t("admin.sidebar.residentsPage")}
                    className={`flex items-center cursor-pointer ${
                        props.isExpanded ? "justify-start pl-[20px]" : "justify-center"
                    } hover:bg-gray-300 w-full h-[60px] ${residents ? "bg-gray-300" : ""}`}
                    to={"/admin/residents"}
                >
                    <ResidentsPageIconSVG className="absolute left-[16px] fill-transparent w-7" />
                    {props.isExpanded ? (
                        <p className="whitespace-nowrap font-manrope font-bold text-[18px] text-gray-600 ml-[35px]">
                            {t("admin.sidebar.residentsPage")}
                        </p>
                    ) : (
                        <></>
                    )}
                </Link>
                <Link
                    title={t("admin.sidebar.archivesPage")}
                    className={`flex items-center cursor-pointer ${
                        props.isExpanded ? "justify-start pl-[20px]" : "justify-center"
                    } hover:bg-gray-300 w-full h-[60px] ${archives ? "bg-gray-300" : ""}`}
                    to={"/admin/archives"}
                >
                    <ArchivesPageIconSVG className="absolute left-[16px] fill-transparent w-7" />
                    {props.isExpanded ? (
                        <p className="whitespace-nowrap font-manrope font-bold text-[18px] text-gray-600 ml-[35px]">
                            {t("admin.sidebar.archivesPage")}
                        </p>
                    ) : (
                        <></>
                    )}
                </Link>
                <Link
                    title={t("admin.sidebar.districtsPage")}
                    className={`flex items-center cursor-pointer ${
                        props.isExpanded ? "justify-start pl-[20px]" : "justify-center"
                    } hover:bg-gray-300 w-full h-[60px] ${districts ? "bg-gray-300" : ""}`}
                    to={"/admin/districts"}
                >
                    <DistrictsPageIconSVG className="absolute left-[16px] fill-transparent w-7" />
                    {props.isExpanded ? (
                        <p className="whitespace-nowrap font-manrope font-bold text-[18px] text-gray-600 ml-[35px]">
                            {t("admin.sidebar.districtsPage")}
                        </p>
                    ) : (
                        <></>
                    )}
                </Link>
                <Link
                    title={t("admin.sidebar.settingsPage")}
                    className={`flex items-center cursor-pointer ${
                        props.isExpanded ? "justify-start pl-[20px]" : "justify-center"
                    } hover:bg-gray-300 w-full h-[60px] ${settings ? "bg-gray-300" : ""}`}
                    to={"/admin/settings"}
                >
                    <SettingsPageIconSVG className="absolute left-[16px] fill-transparent w-7" />
                    {props.isExpanded ? (
                        <p className="whitespace-nowrap font-manrope font-bold text-[18px] text-gray-600 ml-[35px]">
                            {t("admin.sidebar.settingsPage")}
                        </p>
                    ) : (
                        <></>
                    )}
                </Link>
            </div>
            <div className="relative w-full">
                <AdminLangSelect
                    isExpanded={props.isExpanded}
                    additionalButtonClassName={`absolute ${props.isExpanded ? "left-[-8px]" : "left-[12px]"}`}
                    additionalPopupClassName={`absolute bg-gray-300 ${
                        props.isExpanded ? "left-[-15px] bottom-[20px]" : "left-[65px] bottom-[-55px]"
                    }`}
                />
                <div
                    title={t("admin.sidebar.logOut")}
                    className={`flex items-center cursor-pointer ${
                        props.isExpanded ? "justify-start pl-[16px]" : "justify-center"
                    } hover:bg-gray-300 w-full h-[60px]`}
                    onClick={() => signOut()}
                >
                    <SidebarLogoutSVG className="absolute left-[16px] fill-black w-6 ml-[4px]" />
                    {props.isExpanded ? (
                        <p className="whitespace-nowrap font-manrope font-bold text-[18px] text-gray-600 ml-[35px]">
                            {t("admin.sidebar.logOut")}
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminSidebar;
