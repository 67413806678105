import { FC } from "react";
import LogoExtendedSVG from "../shared/svg/logos/LogoExtendedSVG";
import ButtonActionArrowSVG from "../shared/svg/arrows/ButtonActionArrowSVG";
import ShowMoreArrowSVG from "../shared/svg/arrows/ShowMoreArrowSVG";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HeroSection: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="relative z-0">
            <div className="bg-beige1 py-8 h-[951px] overflow-visible overflow-x-clip">
                <div className="max-w-[1910px] mx-auto text-center">
                    <div className="relative">
                        {/* Top Left Image */}
                        <div className="absolute left-[320px] top-[-200px] w-[400px] h-[285px] transform rotate-[-30deg] bg-beige1 p-[20px] shadow-familyPhoto">
                            <img src="/img/about-page/heroSection1.png" alt="Top Left" />
                        </div>
                        {/* Top Right Image */}
                        <div className="absolute right-[330px] top-[-225px] w-[400px] h-[305px] transform rotate-[23deg] bg-beige1 p-[20px] shadow-familyPhoto">
                            <img src="/img/about-page/heroSection2.png" alt="Top Right" />
                        </div>
                        {/* Middle Left Image */}
                        <div className="absolute left-[-50px] top-[150px] w-[415px] h-[488px] transform rotate-[-17.2deg] bg-beige1 p-[20px] shadow-familyPhoto">
                            <img src="/img/about-page/heroSection3.png" alt="Middle Left" />
                        </div>
                        {/* Middle Right Image */}
                        <div className="absolute right-[-65px] top-[100px] w-[420px] h-[520px] transform rotate-[16.2deg] bg-beige1 p-[20px] shadow-familyPhoto">
                            <img src="/img/about-page/heroSection4.png" alt="Middle Right" />
                        </div>

                        <div className="flex flex-col justify-center items-center relative p-8">
                            <LogoExtendedSVG className={"inline-block mt-[46px] "} />
                            <p className="font-cormorant font-medium text-[44.5px] leading-[47.5px] mt-4 text-brown1">
                                {t("aboutPage.heroSection.text1")} <br />
                                {t("aboutPage.heroSection.text2")}{" "}
                                <span className="font-semibold">{t("aboutPage.heroSection.text3")}</span> <br />
                                {t("aboutPage.heroSection.text4")}
                                <span className="font-semibold">{t("aboutPage.heroSection.text5")}</span>
                            </p>
                            <p className="font-liana text-[35.5px] leading-[35.5px] mt-4 text-brown1">
                                {t("aboutPage.heroSection.text6")}
                                <br /> {t("aboutPage.heroSection.text7")}
                            </p>
                            <Link
                                to="/search"
                                className="flex items-center justify-center bg-button-gradient w-[392px] h-[76px] mt-[100px] font-semibold py-2.5 px-6 rounded-full"
                            >
                                <div className="flex justify-center items-center">
                                    <p className="font-cormorant leading-[34px] text-[26px] bg-clip-text text-[transparent] bg-button-text-gradient mr-[10px]">
                                        {t("aboutPage.heroSection.searchButton")}
                                    </p>
                                    <ButtonActionArrowSVG />
                                </div>
                            </Link>
                            <div>
                                <ShowMoreArrowSVG className="mt-[90px]" />
                            </div>
                        </div>
                        {/* Bottom Left Image */}
                        <div className="absolute z-0 left-[190px] bottom-[-25px] w-[380px] h-[450px] transform rotate-[20.7deg] bg-beige1 p-[20px] shadow-familyPhoto">
                            <img src="/img/about-page/heroSection5.png" alt="Bottom Left" />
                        </div>
                        {/* Bottom Right Image */}
                        <div className="absolute z-0 right-[165px] bottom-[-40px] w-[380px] h-[450px] transform rotate-[-16.3deg] bg-beige1 p-[20px] shadow-familyPhoto">
                            <img src="/img/about-page/heroSection6.png" alt="Bottom Right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
