import { ChangeEvent, ReactNode } from "react";
import { InputType } from "../../../types/InputType";
import { FieldMetaData } from "../../../types/SchemaFactory";
import CustomInput from "./CustomInput";
import { FormikProps } from "formik";
import MinusIconSVG from "../svg/admin-icons/MinusIconSVG";

interface CustomInputListProps<T> {
    maxLength: number;
    form: FormikProps<T>;
    field: keyof T;
    meta: FieldMetaData;
    pattern?: RegExp;
    addButtonLabel: string;
    inputComponentFactory?: (item: string, errorKey: string, onChange: (value: string) => void) => ReactNode;
}

function CustomInputList<T>({
    maxLength,
    form,
    field,
    meta,
    pattern,
    addButtonLabel,
    inputComponentFactory,
}: CustomInputListProps<T>) {
    const isMaxed = (form.values[field] as [])?.length >= maxLength;
    const addItem = () => {
        if (isMaxed) {
            return;
        }
        form.setFieldValue(field as string, [...(form.values[field] as []), ""]);
    };

    const updateItem = (index: number) => {
        return (e: ChangeEvent<HTMLInputElement>) => {
            const list = form.values[field] as (number | string)[];
            list[index] = e.target.value;
            form.setFieldValue(field as string, list);
        };
    };

    const updateItemByValue = (index: number) => {
        return (value: string) => {
            const list = form.values[field] as (number | string)[];
            list[index] = value;
            form.setFieldValue(field as string, list);
        };
    };

    const removeItem = (index: number) => {
        form.setFieldValue(
            field as string,
            (form.values[field] as []).filter((_item, i) => i !== index)
        );
    };

    const errors = form.errors[field] as string[];
    return (
        <div className="grid grid-cols-2 gap-x-[30px] items-end">
            {(form.values[field] as [])?.map((item, index) => (
                <div className="flex items-end" key={`custom-input-list-${field as string}-${index}`}>
                    {(inputComponentFactory &&
                        inputComponentFactory(item, errors ? errors[index] : "", updateItemByValue(index))) || (
                        <CustomInput
                            type={meta.type as InputType}
                            labelKey={meta.placeholderKey}
                            errorKey={errors ? errors[index] : ""}
                            value={item}
                            name={field as string}
                            placeholderKey={meta.placeholderKey}
                            onChange={updateItem(index)}
                            pattern={pattern}
                            className="w-[270px]"
                        />
                    )}
                    <button
                        className="flex items-center mb-[24px] ml-[5px] text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-4 py-2.5 text-center h-[44px]"
                        onClick={() => removeItem(index)}
                    >
                        <MinusIconSVG className="w-[15px] fill-white" />
                    </button>
                </div>
            ))}
            {isMaxed ? (
                <></>
            ) : (
                <button
                    className="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-4 py-2.5 text-center h-[44px] w-[140px] mb-[24px] mt-[5px]"
                    onClick={addItem}
                >
                    <p>{addButtonLabel}</p>
                </button>
            )}
        </div>
    );
}

export default CustomInputList;
