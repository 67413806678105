import { useAuth } from "../../context/AuthProvider";
import LoadingPanel from "../shared/loading-panel/LoadingPanel";
import { Navigate, Route, Routes } from "react-router-dom";
import UnAuthRoutes from "../../routes/UnauthenticatedRoutes";
import AdminRoutes from "../../routes/AdminRoutes";

function App() {
    const { loading, user } = useAuth();

    if (loading) {
        return <LoadingPanel isVisible={loading} />;
    }

    return (
        <Routes>
            <Route path="/*" element={<UnAuthRoutes />} />
            {user?.isSuperAdmin ? (
                <Route path="/admin/*" element={<AdminRoutes />} />
            ) : (
                <Route path="/admin/*" element={<Navigate to="/login" />} />
            )}
        </Routes>
    );
}

export default App;
