import { FC } from "react";

interface EnvelopeIconSVGProps {
    className?: string;
}

const EnvelopeIconSVG: FC<EnvelopeIconSVGProps> = (props) => {
    return (
        <svg
            className={props.className}
            width="31"
            height="23"
            viewBox="0 0 31 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.00557709 3.61095L15.333 11.2737L30.6603 3.61095C30.6036 2.63431 30.1756 1.71633 29.464 1.045C28.7524 0.373679 27.8111 -0.000179368 26.8328 6.4559e-08H3.83311C2.85483 -0.000179368 1.91349 0.373679 1.2019 1.045C0.490311 1.71633 0.0623175 2.63431 0.00557709 3.61095Z"
                fill="#EBDCB5"
            />
            <path
                d="M30.6662 7.89307L15.3331 15.5596L-3.8147e-05 7.89307V19.1667C-3.8147e-05 20.1834 0.403824 21.1584 1.1227 21.8773C1.84158 22.5962 2.81659 23 3.83324 23H26.8329C27.8496 23 28.8246 22.5962 29.5435 21.8773C30.2623 21.1584 30.6662 20.1834 30.6662 19.1667V7.89307Z"
                fill="#EBDCB5"
            />
        </svg>
    );
};

export default EnvelopeIconSVG;
