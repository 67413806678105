import { ResidentMapper } from "../domain/mappers/ResidentMapper";
import { District } from "../domain/models/District";
import { Resident } from "../domain/models/Resident";
import { ResidentDTO } from "../domain/models/dto/ResidentDTO";
import ResidentModel from "../models/ResidentModel";
import districtMapperFactory from "./DistrictMapper";
import { SimpleMapper } from "./SimpleMapper";

class ResidentMapperImpl extends SimpleMapper<Resident, ResidentDTO> {
    protected entityConstructor = ResidentModel;

    protected toDTOFields: string[] = [
        "firstNameUk",
        "firstNameEn",
        "lastNameUk",
        "lastNameEn",
        "regionUk",
        "regionEn",
        "districtId",
        "localityUk",
        "localityEn",
    ];

    protected fromDTOFields: string[] = [
        "id",
        "createdAt",
        "updatedAt",
        ...this.toDTOFields,
    ];

    fromDTOExtended(dto: ResidentDTO, districts: District[]): Resident {
        const model = super.fromDTO(dto);
        model.district = dto.districtId
            ? districts.find((item) => item.id === dto.districtId)
            : ({} as District);
        return model;
    }
}

export default function residentMapperFactory(): ResidentMapper<
    Resident,
    ResidentDTO
> {
    return new ResidentMapperImpl();
}
