import { FC } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../../shared/modals/BaseModal";

interface SettingsSuccessModalProps {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    closeHandler: () => void;
}

const SettingsSuccessModal: FC<SettingsSuccessModalProps> = (props) => {
    const { t } = useTranslation();
    return (
        <BaseModal
            isVisible={props.isVisible}
            setIsVisible={props.setIsVisible}
            title={t("admin.settingsPage.changePassword.successTitle1")}
            confirmTitle={t("admin.common.ok")}
            cancelTitle={t("admin.common.ok")}
            buttonElement={<></>}
            disableCancelButton
            confirmHandler={props.closeHandler}
            closeHandler={props.closeHandler}
            additionalWrapClassName="w-[450px]"
        >
            {t("admin.settingsPage.changePassword.successTitle2")}
        </BaseModal>
    );
};

export default SettingsSuccessModal;
