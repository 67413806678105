import { FC } from "react";
import { useTranslation } from "react-i18next";

const LegendBlock: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="absolute top-[600px] flex flex-col justify-between border border-brown3 py-[30px] px-[25px] ml-[20px]">
            <div className="flex">
                <div className="flex justify-between items-center w-[76px]">
                    <div className="w-[22px] h-[22px] bg-[#E9EBFF] border border-brown3"></div>
                    <div className="w-[22px] h-[22px] bg-[#D2EAB0] border border-brown3"></div>
                    <div className="w-[22px] h-[22px] bg-[#FFE8AD] border border-brown3"></div>
                </div>
                <p className="font-manrope text-[18px] text-brown3 leading-[26px] ml-[15px]">
                    {t("surnameMapPage.surnameMapSection.legend.indicator1")}
                </p>
            </div>
            <div className="flex mt-[5px]">
                <div className="flex justify-between items-center w-[76px]">
                    <div className="w-[22px] h-[22px] bg-[#C7CCF9] border border-brown3"></div>
                    <div className="w-[22px] h-[22px] bg-[#B0D67C] border border-brown3"></div>
                    <div className="w-[22px] h-[22px] bg-[#FFD56C] border border-brown3"></div>
                </div>
                <p className="font-manrope text-[18px] text-brown3 leading-[26px] ml-[15px]">
                    {t("surnameMapPage.surnameMapSection.legend.indicator2")}
                </p>
            </div>
            <div className="flex mt-[5px]">
                <div className="flex justify-between items-center w-[76px]">
                    <div className="w-[22px] h-[22px] bg-[#8891E3] border border-brown3"></div>
                    <div className="w-[22px] h-[22px] bg-[#84AF48] border border-brown3"></div>
                    <div className="w-[22px] h-[22px] bg-[#FFB23F] border border-brown3"></div>
                </div>
                <p className="font-manrope text-[18px] text-brown3 leading-[26px] ml-[15px]">
                    {t("surnameMapPage.surnameMapSection.legend.indicator3")}
                </p>
            </div>
            <div className="flex mt-[5px]">
                <div className="flex justify-between items-center w-[76px]">
                    <div className="w-[22px] h-[22px] bg-[#4F5ABF] border border-brown3"></div>
                    <div className="w-[22px] h-[22px] bg-[#5B8521] border border-brown3"></div>
                    <div className="w-[22px] h-[22px] bg-[#FF8F27] border border-brown3"></div>
                </div>
                <p className="font-manrope text-[18px] text-brown3 leading-[26px] ml-[15px]">
                    {t("surnameMapPage.surnameMapSection.legend.indicator4")}
                </p>
            </div>
        </div>
    );
};

export default LegendBlock;
