import { DEFAULT_PAGE, GET_ALL_RECORDS } from "../domain/constants";
import { Langs } from "../domain/enums/common/LangsEnum";
import { District, DistrictsCollection } from "../domain/models/District";
import { DistrictDTO } from "../domain/models/dto/DistrictDTO";
import { DistrictService } from "../domain/services/DistrictService";
import districtMapperFactory from "../mappers/DistrictMapper";
import { NullableCollection, RemoveResponse } from "../types/ApiResults";
import { BaseServiceImpl } from "./BaseServiceImpl";
import languageServiceFactory from "./LanguageServiceImpl";

class DistrictServiceImpl extends BaseServiceImpl implements DistrictService {
    static _instance: DistrictService;
    private _districts: DistrictsCollection;

    private constructor() {
        super();
    }

    static getInstance(): DistrictService {
        if (!DistrictServiceImpl._instance) {
            DistrictServiceImpl._instance = new DistrictServiceImpl();
        }
        return DistrictServiceImpl._instance;
    }

    getNullableDistrictDTO(): DistrictDTO {
        return {
            id: null,
            districtUk: "admin.residentsPage.tableColumns.districtName",
            districtEn: "admin.residentsPage.tableColumns.districtName",
            geo: { type: "MultiPolygon", coordinates: [[[[0.0, 0.0]]]] },
            createdAt: null,
            updatedAt: null,
        };
    }

    async getAllDistricts(): Promise<DistrictsCollection> {
        try {
            if (!this._districts) {
                const langService = languageServiceFactory();
                const mapper = districtMapperFactory();
                const result = await this.getCollection<DistrictDTO>(
                    `/api/v1/districts/?page=${GET_ALL_RECORDS}&limit=${DEFAULT_PAGE}&lang=${langService.currentLang}`,
                    []
                );
                const DTOs = [this.getNullableDistrictDTO(), ...result.rows];
                this._districts = {
                    ...result,
                    rows: DTOs.concat().map((item) => mapper.fromDTO(item)),
                };
            }
            return this._districts;
        } catch (err) {
            console.log("Error in DistrictServiceImpl.getDistricts");
            console.log(err);
        }
    }

    getCachedDistrict(districtId: string): District {
        return (this._districts || NullableCollection).rows.find(
            (item) => item.id === districtId
        );
    }

    async getDistricts(
        page: number,
        limit: number,
        lang: Langs,
        dto: DistrictDTO = {} as DistrictDTO
    ): Promise<DistrictsCollection> {
        try {
            const mapper = districtMapperFactory();
            let url = `/api/v1/districts/?page=${page}&limit=${limit}&lang=${lang}`;
            Object.entries(dto).map(([key, value]) => {
                if (key && value) {
                    url += `&${key}=${value}`;
                }
            });
            const result = await this.getCollection<DistrictDTO>(url, []);
            return {
                ...result,
                rows: result.rows.map((item) => mapper.fromDTO(item)),
            };
        } catch (err) {
            console.log("Error in DistrictServiceImpl.getDistricts");
            console.log(err);
        }
    }

    async createDistrict(district: District): Promise<District> {
        try {
            const mapper = districtMapperFactory();
            return this.post("/api/v1/districts", mapper.toDTO(district));
        } catch (err) {
            console.log("Error in DistrictServiceImpl.createDistrict");
            console.log(err);
        }
    }

    async updateDistrict(district: District): Promise<District> {
        try {
            if (!district?.id) {
                return null;
            }
            const mapper = districtMapperFactory();
            return this.patch(
                `/api/v1/districts/${district.id}`,
                mapper.toDTO(district)
            );
        } catch (err) {
            console.log("Error in DistrictServiceImpl.updateDistrict");
            console.log(err);
        }
    }

    async deleteDistrict(districtId: string): Promise<RemoveResponse> {
        try {
            if (!districtId) {
                return { status: false };
            }
            return this.delete(`/api/v1/districts/${districtId}`);
        } catch (err) {
            console.log("Error in DistrictServiceImpl.deleteDistrict");
            console.log(err);
        }
    }
}

export default function districtServiceFactory(): DistrictService {
    return DistrictServiceImpl.getInstance();
}
