import { SimpleSearchDTO } from "../models/dto/ResidentDTO";

export enum MapLayerMode {
    FATHERS,
    MOTHERS,
    COMBINED,
}

export const getDistrictColor = (dto: SimpleSearchDTO) => {
    const combined = dto.fathersCount + dto.mothersCount;
    return {
        [MapLayerMode.FATHERS]:
            dto.fathersCount <= 0
                ? "#F8E9C0"
                : dto.fathersCount >= 1 && dto.fathersCount <= 10
                ? "#E9EBFF"
                : dto.fathersCount >= 11 && dto.fathersCount <= 20
                ? "#C7CCF9"
                : dto.fathersCount >= 21 && dto.fathersCount <= 50
                ? "#8891E3"
                : "#4F5ABF",
        [MapLayerMode.MOTHERS]:
            dto.mothersCount <= 0
                ? "#F8E9C0"
                : dto.mothersCount >= 1 && dto.mothersCount <= 10
                ? "#D2EAB0"
                : dto.mothersCount >= 11 && dto.mothersCount <= 20
                ? "#B0D67C"
                : dto.mothersCount >= 21 && dto.mothersCount <= 50
                ? "#84AF48"
                : "#5B8521",
        [MapLayerMode.COMBINED]:
            combined <= 0
                ? "#F8E9C0"
                : combined >= 1 && combined <= 10
                ? "#FFE8AD"
                : combined >= 11 && combined <= 20
                ? "#FFD56C"
                : combined >= 21 && combined <= 50
                ? "#FFB23F"
                : "#FF8F27",
    };
};
