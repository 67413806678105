import { FC } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SearchIntro from "./SearchIntro";
import FamilyInterviewAdvice from "./FamilyInterviewAdvice";
import InterviewInstructions from "./InterviewInstructions";
import ArchivesConsultationGuide from "./ArchivesConsultationGuide";
import ResearchPreparationTips from "./ResearchPreparationTips";

const RecommendationsPage: FC = () => {
    return (
        <>
            <Header />
            <SearchIntro />
            <FamilyInterviewAdvice />
            <InterviewInstructions />
            <ArchivesConsultationGuide />
            <ResearchPreparationTips />
            <Footer />
        </>
    );
};

export default RecommendationsPage;
