import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { ResidentDTO } from "../domain/models/dto/ResidentDTO";

export const residentFields: Fields<ResidentDTO> = {
    firstNameUk: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.firstNameUk`,
        type: "text",
        initial: "",
    },
    firstNameEn: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.firstNameEn`,
        type: "text",
        initial: "",
    },
    lastNameUk: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.lastNameUk`,
        type: "text",
        initial: "",
    },
    lastNameEn: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.lastNameEn`,
        type: "text",
        initial: "",
    },
    regionUk: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.regionUk`,
        type: "text",
        initial: "",
        optional: true,
    },
    regionEn: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.regionEn`,
        type: "text",
        initial: "",
        optional: true,
    },
    localityUk: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.localityUk`,
        type: "text",
        initial: "",
        optional: true,
    },
    localityEn: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.localityEn`,
        type: "text",
        initial: "",
        optional: true,
    },
    districtId: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.districtName`,
        type: "text",
        initial: null,
        optional: true,
    },
};

export const residentSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, residentFields);
};
