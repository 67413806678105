import { FC, useState } from "react";
import CustomInput from "../../shared/form-inputs/CustomInput";
import { InputType } from "../../../types/InputType";
import { FormikProps } from "formik";
import BaseModal from "../../shared/modals/BaseModal";
import { useTranslation } from "react-i18next";
import FilterIconSVG from "../../shared/svg/admin-icons/FilterIconSVG";
import { englishSymbols, ukrainianSymbols } from "../../../domain/constants";
import { DistrictDTO } from "../../../domain/models/dto/DistrictDTO";
import { districtFields } from "../../../validation-schemas/DistrictSchema";

interface DistrictsSearchFiltersFormProps {
    form: FormikProps<DistrictDTO>;
    filtersFields: DistrictDTO;
}

const DistrictsSearchFiltersForm: FC<DistrictsSearchFiltersFormProps> = (props) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const confirmHandler = () => {
        setIsVisible(false);
        props.form.handleSubmit();
    };
    const closeHandler = () => {
        props.form.resetForm();
    };
    const onOpenHandler = () => {
        props.form.setValues(props.filtersFields);
    };
    return (
        <BaseModal
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            title={t("admin.common.filters")}
            confirmTitle={t("admin.common.apply")}
            cancelTitle={t("admin.common.cancel")}
            buttonElement={
                <button
                    data-modal-target="static-modal"
                    data-modal-toggle="static-modal"
                    className="flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center max-h-10 ml-4"
                    type="button"
                    title={t("admin.common.filters")}
                    onClick={() => setIsVisible(true)}
                >
                    <FilterIconSVG className="w-[20px] fill-white" />
                </button>
            }
            confirmHandler={confirmHandler}
            closeHandler={closeHandler}
            onOpen={onOpenHandler}
            additionalWrapClassName="w-[450px]"
        >
            <CustomInput
                type={districtFields.districtUk.type as InputType}
                value={props.form.values.districtUk}
                name="districtUk"
                placeholderKey={districtFields.districtUk.placeholderKey}
                errorKey={props.form.errors.districtUk as string}
                onChange={props.form.handleChange}
                pattern={ukrainianSymbols}
            />
            <CustomInput
                type={districtFields.districtEn.type as InputType}
                value={props.form.values.districtEn}
                name="districtEn"
                placeholderKey={districtFields.districtEn.placeholderKey}
                errorKey={props.form.errors.districtEn as string}
                onChange={props.form.handleChange}
                pattern={englishSymbols}
            />
        </BaseModal>
    );
};

export default DistrictsSearchFiltersForm;
