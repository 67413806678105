import { SelectProps, SelectValue } from "../types/SelectProps";

export function getEnumKeys<T extends object>(enumeration: T) {
    return Object.keys(enumeration).filter((key) => isNaN(Number(key)));
}

export function enumToSelectProps<T extends Record<string, SelectValue>>(
    enumeration: T,
    labelKey: string
): SelectProps[] {
    return getEnumKeys(enumeration).map((value) => {
        return {
            labelKey: `${labelKey}.${enumeration[value as keyof T]}`,
            value: enumeration[value as keyof T],
        };
    });
}

export function enumValueToSelectProps(
    value: SelectValue,
    labelKey: string
): SelectProps {
    return {
        labelKey: `${labelKey}.${value}`,
        value,
    };
}
