import PhoneInput from "react-phone-number-input";
import { FieldMetaData } from "../../../types/SchemaFactory";
import languageServiceFactory from "../../../services/LanguageServiceImpl";
import "react-phone-number-input/style.css";
import CommonError from "./CommonError";
import { useTranslation } from "react-i18next";

interface PhoneInputProps<T> {
    meta: FieldMetaData;
    field: keyof T;
    value: string;
    errorKey: string;
    onChange: (value: string) => void;
}

const languageService = languageServiceFactory();

function PhoneInputComponent<T>(props: PhoneInputProps<T>) {
    const { t } = useTranslation();
    return (
        <div>
            {props.meta.placeholderKey && (
                <label className="block mb-2 text-lg font-semibold text-[#344054]" htmlFor={props.meta.placeholderKey}>
                    {t(props.meta.placeholderKey)}
                </label>
            )}
            <PhoneInput
                tabIndex="phone"
                placeholder={t(props.meta.placeholderKey)}
                name={props.field}
                value={props.value}
                onChange={props.onChange}
                international={true}
                limitMaxLength={true}
                labels={languageService.getCurrentPhoneNumberLocale()}
                countries={["UA"]}
            />
            <CommonError errorKey={props.errorKey} name={props.field as string} />
        </div>
    );
}

export default PhoneInputComponent;
