import { FC, Fragment } from "react";
import { Resident } from "../../../domain/models/Resident";
import EditPencilIconSVG from "../../shared/svg/admin-icons/EditPencilIconSVG";
import ResidentDeletionButton from "./ResidentDeletionButton";
import { ResidentFields } from "../../../domain/enums/ResidentFieldsEnum";

interface ResidentsTableRowProps {
    resident: Resident;
    updateHandler: (resident: Resident) => void;
    deleteHandler: (resident: Resident) => void;
}

const ResidentsTableRow: FC<ResidentsTableRowProps> = (props) => {
    const updateResidentHandler = () => {
        props.updateHandler(props.resident);
    };

    return (
        <tr className="bg-white border-b hover:bg-gray-200">
            {Object.values(ResidentFields).map((value, index) => {
                if (value === ResidentFields.ID) {
                    return <Fragment key={`td-residents-table-${index}`}></Fragment>;
                }
                return (
                    <td
                        key={`td-residents-table-${index}`}
                        className="px-2 py-1 truncate text-center max-w-[95px] border"
                        title={props.resident[value]}
                    >
                        {props.resident[value]}
                    </td>
                );
            })}
            <td className="px-2 py-2 border">
                <div
                    onClick={updateResidentHandler}
                    className="flex bg-green-600 hover:bg-green-700 items-center justify-center focus:ring-4 rounded-lg h-7 w-8"
                >
                    <EditPencilIconSVG iconColor="white" />
                </div>
            </td>
            <td className="py-2 px-2 border">
                <ResidentDeletionButton resident={props.resident} deleteHandler={props.deleteHandler} />
            </td>
        </tr>
    );
};

export default ResidentsTableRow;
