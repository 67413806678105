import { FC } from "react";

interface DownloadButtonProps {
    title: string;
    disable: boolean;
    label: string;
}

const DownloadButton: FC<DownloadButtonProps> = (props) => {
    return (
        <div
            title={props.title}
            className={`${
                props.disable ? "opacity-50" : ""
            } cursor-pointer flex items-center h-[44px] px-4 py-2 bg-gradient-to-r from-[#d2b18a] to-[#6e4b36] text-white font-semibold rounded-full shadow-md`}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v7.586l2.293-2.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 11.586V4a1 1 0 011-1zM4 14a1 1 0 100 2h12a1 1 0 100-2H4z"
                    clipRule="evenodd"
                />
            </svg>
            {props.label}
        </div>
    );
};

export default DownloadButton;
