import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { ArchiveDTO } from "../domain/models/dto/ArchiveDTO";
import { archiveFields } from "./ArchiveSchema";
import { enumToSelectProps } from "../tools/EnumTools";
import { ArchivesTypes } from "../domain/enums/ArchivesTypesEnum";

export const archivesSearchFiltersFields: Fields<ArchiveDTO> = {
    ...archiveFields,
    nameUk: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.firstNameUk`,
        type: "text",
        initial: "",
        optional: true,
    },
    nameEn: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.firstNameEn`,
        type: "text",
        initial: "",
        optional: true,
    },
    addressUk: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.lastNameUk`,
        type: "text",
        initial: "",
        optional: true,
    },
    addressEn: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.lastNameEn`,
        type: "text",
        initial: "",
        optional: true,
    },
    type: {
        min: 0,
        max: 100,
        placeholderKey: `admin.archivesPage.tableColumns.type`,
        type: "select",
        initial: { value: null, labelKey: "" },
        selectOptions: enumToSelectProps(ArchivesTypes, `archivesPage.type`),
        optional: true,
    },
};

export const archivesSearchFiltersSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, archivesSearchFiltersFields);
};
