import { FC, Fragment } from "react";
import EditPencilIconSVG from "../../shared/svg/admin-icons/EditPencilIconSVG";
import { Archive } from "../../../domain/models/Archive";
import { ArchiveFields } from "../../../domain/enums/ArchiveFieldsEnum";
import ArchiveDeletionButton from "./ArchiveDeletionButton";
import { useTranslation } from "react-i18next";

interface ArchivesTableRowProps {
    archive: Archive;
    updateHandler: (archive: Archive) => void;
    deleteHandler: (archive: Archive) => void;
}

const ArchivesTableRow: FC<ArchivesTableRowProps> = (props) => {
    const updateArchiveHandler = () => {
        props.updateHandler(props.archive);
    };

    const { t } = useTranslation();

    return (
        <tr className="bg-white border-b hover:bg-gray-200">
            {Object.values(ArchiveFields).map((value, index) => {
                if (value === ArchiveFields.ID) {
                    return <Fragment key={`td-archives-table-${index}`}></Fragment>;
                }
                const str =
                    value === ArchiveFields.TYPE
                        ? t(`archivesPage.type.${props.archive[value]}`)
                        : props.archive[value];
                return (
                    <td
                        key={`td-archives-table-${index}`}
                        className={`px-2 py-1 ${
                            value === ArchiveFields.TYPE ? "max-w-[45px]" : "max-w-[95px]"
                        } truncate text-center border`}
                    >
                        {str}
                    </td>
                );
            })}

            <td className="px-2 py-2 border">
                <div
                    onClick={updateArchiveHandler}
                    className="flex bg-green-600 hover:bg-green-700 items-center justify-center focus:ring-4 rounded-lg h-7 w-8"
                >
                    <EditPencilIconSVG iconColor="white" />
                </div>
            </td>
            <td className="py-2 px-2 border">
                <ArchiveDeletionButton archive={props.archive} deleteHandler={props.deleteHandler} />
            </td>
        </tr>
    );
};

export default ArchivesTableRow;
