import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { UserLoginDTO } from "../domain/models/dto/UserLoginDTO";

export const userLoginFields: Fields<UserLoginDTO> = {
    email: {
        min: 1,
        max: 128,
        placeholderKey: `login.fields.email`,
        type: "email",
        initial: "",
    },
    password: {
        min: 1,
        max: 128,
        placeholderKey: `login.fields.password`,
        type: "text",
        initial: "",
    },
};

export const userLoginSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, userLoginFields);
};
