import { FC } from "react";
import { useTranslation } from "react-i18next";

interface CommonErrorProps {
    errorKey: string;
    name: string;
}

const CommonError: FC<CommonErrorProps> = (props) => {
    const { t } = useTranslation();
    return (
        <p className={`mt-1 ml-3 text-sm font-normal text-red-500`}>
            {props.errorKey ? t(props.errorKey) : props.name ? "\u00A0" : ""}
        </p>
    );
};

export default CommonError;
