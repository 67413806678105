import { FC } from "react";
import { LanguageService } from "../../domain/services/LanguageService";
import ButtonActionArrowSVG from "../shared/svg/arrows/ButtonActionArrowSVG";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const langService: LanguageService = languageServiceFactory();

const ArchivesConsultationGuide: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full bg-genealogist-text-block-gradient py-[103px] flex justify-center shadow-beige4Section overflow-x-clip">
            <div className="max-w-[1920px] w-full px-[170px] flex flex-col items-center">
                <div className="text-center">
                    <p
                        className={`font-manrope ${
                            langService.isUkrainian ? "text-[22px]" : "text-[20px]"
                        } text-brown3 leading-[32px]`}
                    >
                        {t("searchRecommendationsPage.archivesConsultationGuide.text1")}
                    </p>
                    <p className="font-cormorant text-[67.2px] text-brown3 leading-[70px] mt-[12px] mb-[56px]">
                        {t("searchRecommendationsPage.archivesConsultationGuide.text2")}
                    </p>
                </div>
                <div className="flex items-center justify-between w-full">
                    <div
                        className={`${
                            langService.isEnglish ? "h-[386px]" : "h-[326px]"
                        } font-manrope text-[22px] text-brown3 leading-[32px] bg-genealogist-text-block-gradient shadow-lg pl-[36px] pr-[58px] py-[50px] rounded-[29px] w-[490px] border border-brown3`}
                    >
                        <p className={langService.isEnglish ? "mt-[20px]" : ""}>
                            {t("searchRecommendationsPage.archivesConsultationGuide.text3")}
                        </p>
                        <div>
                            {t("searchRecommendationsPage.archivesConsultationGuide.text4")}{" "}
                            <span className="font-bold">
                                {t("searchRecommendationsPage.archivesConsultationGuide.text5")}
                            </span>{" "}
                            {t("searchRecommendationsPage.archivesConsultationGuide.text6")}
                        </div>
                    </div>
                    <div
                        className={`${
                            langService.isEnglish ? "h-[386px]" : "h-[326px]"
                        } font-manrope text-[22px] text-brown3 leading-[32px] bg-genealogist-text-block-gradient shadow-lg pl-[36px] pr-[58px] py-[50px] rounded-[29px] w-[490px] border border-brown3`}
                    >
                        <p className={langService.isEnglish ? "mt-[20px]" : ""}>
                            {t("searchRecommendationsPage.archivesConsultationGuide.text7")}
                        </p>
                        <p className="mt-[40px]">{t("searchRecommendationsPage.archivesConsultationGuide.text8")}</p>
                    </div>
                    <div
                        className={`${
                            langService.isEnglish ? "h-[386px]" : "h-[326px]"
                        } font-manrope text-[22px] text-brown3 leading-[32px] bg-genealogist-text-block-gradient shadow-lg pl-[36px] pr-[30px] py-[50px] rounded-[29px] w-[490px] border border-brown3`}
                    >
                        <p>{t("searchRecommendationsPage.archivesConsultationGuide.text9")}</p>
                    </div>
                </div>
                <Link
                    to="/archives"
                    className="bg-button-gradient w-[392px] h-[76px] mt-[58px] font-semibold py-2.5 px-6 rounded-full"
                >
                    <div className="flex justify-center items-center pt-[10px]">
                        <p className="font-cormorant leading-[34px] text-[26px] bg-clip-text text-[transparent] bg-button-text-gradient mr-[10px]">
                            {t("searchRecommendationsPage.archivesConsultationGuide.text10")}
                        </p>
                        <ButtonActionArrowSVG />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default ArchivesConsultationGuide;
