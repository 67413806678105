// Responsiveness
export const MIN_DESKTOP_WIDTH = 744;
export const AVG_DESKTOP_WIDTH = 1024;

// Pagination
export const DEFAULT_PAGE = 1;
export const DEFAULT_ROWS = 0;
export const GET_ALL_RECORDS = -1;
export const PAGINATION_NUMBER = 2;

// Timeouts
export const RESPONSE_MESSAGE_VISIBILITY_MS = 3000;
export const AUTOCOMPLETE_TIMEOUT_MS = 0;

// Project
export const defaultProjectEmail = "info@historygenerations.life";
export const defaultAutocompletePatternMin = 5;

// Regular expressions
export const englishSymbols = /^[A-Za-z\s.,!?@\-_0-9]*$/;
export const ukrainianSymbols = /^[А-Яа-яЄєІіЇїҐґ\s.,!?@\-_0-9]*$/;
export const ukrainianPhone = /^\+38[\d\s\(\)-]+$/;

// Forms
export const defaultInputsAmount = 5;
