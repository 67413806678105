import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import DeleteTrashIconSVG from "../../shared/svg/admin-icons/DeleteTrashIconSVG";
import EditPencilIconSVG from "../../shared/svg/admin-icons/EditPencilIconSVG";
import { Archive } from "../../../domain/models/Archive";
import { ArchiveFields } from "../../../domain/enums/ArchiveFieldsEnum";
import ArchivesTableRow from "./ArchivesTableRow";

interface ArchivesTableProps {
    archives: Archive[];
    updateHandler: (archive: Archive) => void;
    deleteHandler: (archive: Archive) => void;
}

const ArchivesTable: FC<ArchivesTableProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="w-[95%] h-[calc(100vh-150px)] ml-auto mr-auto relative overflow-auto shadow-md rounded-xl">
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="sticky top-0 text-[14px] font-extralight leading-[16px] text-gray-700 bg-gray-300">
                    <tr className="text-center px-2 py-3 group relative">
                        {Object.values(ArchiveFields).map((value, index) => {
                            if (value === ArchiveFields.ID) {
                                return <Fragment key={`th-archives-table-${index}`}></Fragment>;
                            }
                            return (
                                <th key={`th-archives-table-${value}`} className="px-2 py-3 group border-x border-y-0">
                                    {t(`admin.archivesPage.tableColumns.${value}`)}
                                </th>
                            );
                        })}
                        <th className="px-2 py-3 group border-x border-y-0 w-8">
                            <div className="ml-[8px]">
                                <EditPencilIconSVG iconColor="black" />
                            </div>
                        </th>
                        <th className="px-2 py-3 group border-x border-y-0 w-8">
                            <div className="ml-[8px]">
                                <DeleteTrashIconSVG iconColor="black" />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.archives.map((archive) => (
                        <ArchivesTableRow
                            key={`tr-archives-table-${archive.id}`}
                            archive={archive}
                            updateHandler={props.updateHandler}
                            deleteHandler={props.deleteHandler}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ArchivesTable;
