import { Role } from "../domain/enums/RoleEnum";
import { UserDTO } from "../domain/models/dto/UserDTO";
import { User } from "../domain/models/User";
import { BaseModelImpl } from "./BaseModelImpl";

export default class UserModel extends BaseModelImpl<UserDTO> implements User {
    constructor(data?: UserDTO) {
        super();
        this.assignData(data);
    }

    id?: string = null;
    firstName: string = "";
    lastName: string = "";
    role: Role;

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`.trim();
    }

    get isSuperAdmin(): boolean {
        return this.role === Role.SUPER_ADMIN;
    }
}
