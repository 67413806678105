import { FC, useState } from "react";
import CustomInput from "../../shared/form-inputs/CustomInput";
import { ResidentDTO } from "../../../domain/models/dto/ResidentDTO";
import { residentFields } from "../../../validation-schemas/ResidentSchema";
import { InputType } from "../../../types/InputType";
import { FormikProps } from "formik";
import CustomDropdown from "../../shared/form-inputs/CustomDropdown";
import { SelectProps } from "../../../types/SelectProps";
import BaseModal from "../../shared/modals/BaseModal";
import { useTranslation } from "react-i18next";
import FilterIconSVG from "../../shared/svg/admin-icons/FilterIconSVG";
import { englishSymbols, ukrainianSymbols } from "../../../domain/constants";

interface ResidentsSearchFiltersFormProps {
    form: FormikProps<ResidentDTO>;
    filtersFields: ResidentDTO;
    districts: SelectProps[];
}

const ResidentsSearchFiltersForm: FC<ResidentsSearchFiltersFormProps> = (props) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const confirmHandler = () => {
        setIsVisible(false);
        props.form.handleSubmit();
    };
    const closeHandler = () => {
        props.form.resetForm();
    };
    const onOpenHandler = () => {
        props.form.setValues(props.filtersFields);
    };
    const getDefaultDistrict = () => {
        return (
            props.districts.find((district) => district.value === props.filtersFields.districtId) || props.districts[0]
        );
    };
    return (
        <BaseModal
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            title={t("admin.common.filters")}
            confirmTitle={t("admin.common.apply")}
            cancelTitle={t("admin.common.cancel")}
            buttonElement={
                <button
                    data-modal-target="static-modal"
                    data-modal-toggle="static-modal"
                    className="flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center max-h-10 ml-4"
                    type="button"
                    title={t("admin.common.filters")}
                    onClick={() => setIsVisible(true)}
                >
                    <FilterIconSVG className="w-[20px] fill-white" />
                </button>
            }
            confirmHandler={confirmHandler}
            closeHandler={closeHandler}
            onOpen={onOpenHandler}
            additionalWrapClassName="w-[450px]"
        >
            <CustomInput
                type={residentFields.firstNameUk.type as InputType}
                value={props.form.values.firstNameUk}
                name="firstNameUk"
                placeholderKey={residentFields.firstNameUk.placeholderKey}
                errorKey={props.form.errors.firstNameUk as string}
                onChange={props.form.handleChange}
                pattern={ukrainianSymbols}
            />
            <CustomInput
                type={residentFields.firstNameEn.type as InputType}
                value={props.form.values.firstNameEn}
                name="firstNameEn"
                placeholderKey={residentFields.firstNameEn.placeholderKey}
                errorKey={props.form.errors.firstNameEn as string}
                onChange={props.form.handleChange}
                pattern={englishSymbols}
            />
            <CustomInput
                type={residentFields.lastNameUk.type as InputType}
                value={props.form.values.lastNameUk}
                name="lastNameUk"
                placeholderKey={residentFields.lastNameUk.placeholderKey}
                errorKey={props.form.errors.lastNameUk as string}
                onChange={props.form.handleChange}
                pattern={ukrainianSymbols}
            />
            <CustomInput
                type={residentFields.lastNameEn.type as InputType}
                value={props.form.values.lastNameEn}
                name="lastNameEn"
                placeholderKey={residentFields.lastNameEn.placeholderKey}
                errorKey={props.form.errors.lastNameEn as string}
                onChange={props.form.handleChange}
                pattern={englishSymbols}
            />
            <CustomInput
                type={residentFields.regionUk.type as InputType}
                value={props.form.values.regionUk}
                name="regionUk"
                placeholderKey={residentFields.regionUk.placeholderKey}
                errorKey={props.form.errors.regionUk as string}
                onChange={props.form.handleChange}
                pattern={ukrainianSymbols}
            />
            <CustomInput
                type={residentFields.regionEn.type as InputType}
                value={props.form.values.regionEn}
                name="regionEn"
                placeholderKey={residentFields.regionEn.placeholderKey}
                errorKey={props.form.errors.regionEn as string}
                onChange={props.form.handleChange}
                pattern={englishSymbols}
            />
            <CustomDropdown
                defaultValue={getDefaultDistrict()}
                errorKey={props.form.errors.district as string}
                data={props.districts}
                dropDownCallback={(value) => props.form.setFieldValue("districtId", value)}
                buttonClassName="w-full"
                wrapperClassName="block mb-6"
                dropdownClassName="max-h-[170px] w-[386px]"
            />
            <CustomInput
                type={residentFields.localityUk.type as InputType}
                value={props.form.values.localityUk}
                name="localityUk"
                placeholderKey={residentFields.localityUk.placeholderKey}
                errorKey={props.form.errors.localityUk as string}
                onChange={props.form.handleChange}
                pattern={ukrainianSymbols}
            />
            <CustomInput
                type={residentFields.localityEn.type as InputType}
                value={props.form.values.localityEn}
                name="localityEn"
                placeholderKey={residentFields.localityEn.placeholderKey}
                errorKey={props.form.errors.localityEn as string}
                onChange={props.form.handleChange}
                pattern={englishSymbols}
            />
        </BaseModal>
    );
};

export default ResidentsSearchFiltersForm;
