import { FC, useState } from "react";
import CentralArchivesTable from "./CentralArchivesTable";
import { Archive } from "../../domain/models/Archive";
import RegionalArchivesTable from "./RegionalArchivesTable";
import SectoralArchivesTable from "./SectoralArchivesTable";
import ShowMoreArrowSVG from "../shared/svg/arrows/ShowMoreArrowSVG";

interface ArchivesListProps {
    regional: Archive[];
    central: Archive[];
    sectoral: Archive[];
}

const ArchivesList: FC<ArchivesListProps> = (props) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className="relative bg-beige4 shadow-beige4Section w-full overflow-hidden">
            <div
                className={`${expanded ? "pb-[130px]" : "max-h-[2687px]"} max-w-[1920px] mx-auto px-[190px] pt-[90px]`}
            >
                <CentralArchivesTable archives={props.central} />
                <RegionalArchivesTable archives={props.regional} />
                <SectoralArchivesTable archives={props.sectoral} />
            </div>

            {!expanded && (
                <div className="absolute bottom-0 left-0 w-full h-[750px] bg-gradient-to-t from-beige4 to-transparent pointer-events-none"></div>
            )}

            <button
                onClick={() => setExpanded(!expanded)}
                className="absolute left-0 right-0 bottom-[35px] m-auto w-fit"
            >
                <ShowMoreArrowSVG className={`${expanded ? "transform rotate-180" : ""}`} />
            </button>
        </div>
    );
};

export default ArchivesList;
