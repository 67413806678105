import { FC } from "react";
import { useTranslation } from "react-i18next";

const ContactsIntro: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="relative bg-beige1 h-[951px] overflow-hidden">
            <div className="absolute left-0 right-0 mx-auto h-full w-[1910px]">
                <img
                    className="absolute bottom-[0px]"
                    src="/img/contacts-page/contactsIntro.jpg"
                    alt="Contacts Intro"
                />
                <div className="absolute text-left right-[213px] top-[107px] w-[862px]">
                    <p className="font-cormorant font-thin text-[45px] bg-clip-text text-[transparent] bg-search-intro-gradient-1 leading-[43.12px]">
                        {t("contactsPage.contactsIntro.text1")}
                    </p>
                    <p className="font-cormorant font-thin text-[130px] bg-clip-text text-[transparent] bg-search-intro-gradient-1 leading-[114.4px]">
                        {t("contactsPage.contactsIntro.text2")}
                    </p>
                </div>
                <p className="absolute top-[428px] right-[230px] font-liana font-medium text-[33px] leading-[37.3px] text-brown3 w-[845px]">
                    {t("contactsPage.contactsIntro.text3")}
                </p>
                <p className="absolute top-[523px] right-[309px] font-manrope font-medium text-[22px] leading-[32px] text-brown3 w-[766px]">
                    {t("contactsPage.contactsIntro.text4")}
                </p>
                <p className="absolute top-[677px] right-[391px] font-manrope font-medium text-[22px] leading-[32px] text-brown3 w-[684px]">
                    {t("contactsPage.contactsIntro.text5")}{" "}
                    <span className="font-bold">{t("contactsPage.contactsIntro.text6")} </span>
                    {t("contactsPage.contactsIntro.text7")}{" "}
                    <span className="font-bold">{t("contactsPage.contactsIntro.text8")}. </span>
                    {t("contactsPage.contactsIntro.text9")}{" "}
                    <span className="font-bold">{t("contactsPage.contactsIntro.text10")}.</span>
                </p>
                <p className="absolute top-[616px] right-[100px] font-liana font-medium text-[62.3px] bg-clip-text text-[transparent] bg-search-intro-gradient-1 leading-[55px] transform rotate-[90deg] pl-[10px]">
                    {t("contactsPage.contactsIntro.text11")}
                </p>
            </div>
        </div>
    );
};

export default ContactsIntro;
