import { FC, useState } from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";

const MainAdminComponent: FC = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div className="flex justify-between">
            <div className="relative w-60px h-[100vh]">
                <AdminSidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            </div>
            <div className={`relative w-[calc(100vw-60px)]`}>
                {isExpanded && <div className="absolute inset-0 bg-black opacity-30 z-10 pointer-events-auto"></div>}
                <main className="">
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default MainAdminComponent;
