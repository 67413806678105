import { FC } from "react";
import { useTranslation } from "react-i18next";

const ResearchDesignOptions: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="bg-beige1 text-center h-[1028px] pt-[141px] pb-[194px] overflow-x-clip">
            <p className="font-cormorant text-[40px] text-brown3 leading-[46px]">
                {t("DNAGenealogyPage.researchDesignOptions.text1")} <br />
                <span className="font-bold">{t("DNAGenealogyPage.researchDesignOptions.text2")} </span>
                {t("DNAGenealogyPage.researchDesignOptions.text3")} <br />
                {t("DNAGenealogyPage.researchDesignOptions.text4")}
            </p>
            <div className="flex justify-center mt-[110px]">
                <div className="relative flex justify-center w-[310px]">
                    <span className="absolute right-[265px] bottom-[-30px] font-cormorant text-[244px] leading-[296px] text-transparent bg-clip-text bg-search-intro-gradient-2 opacity-10">
                        1
                    </span>
                    <p className="font-manrope text-[22px] text-left text-brown3 leading-[32px] w-[242px]">
                        {t("DNAGenealogyPage.researchDesignOptions.text5")}
                    </p>
                </div>
                <div className="border-l border-brown11 h-[132px] mx-[26px]"></div>
                <div className="relative flex justify-center w-[388px] pl-[71px]">
                    <span className="absolute right-[295px] bottom-[-30px] font-cormorant text-[244px] leading-[296px] text-transparent bg-clip-text bg-search-intro-gradient-2 opacity-10">
                        2
                    </span>
                    <p className="font-manrope text-[22px] text-left text-brown3 leading-[32px] w-[317px]">
                        {t("DNAGenealogyPage.researchDesignOptions.text6")}
                    </p>
                </div>
                <div className="border-l border-brown11 h-[132px] mx-[26px]"></div>
                <div className="relative flex justify-center w-[290px]">
                    <span className="absolute right-[190px] bottom-[-20px] font-cormorant text-[244px] leading-[296px] text-transparent bg-clip-text bg-search-intro-gradient-2 opacity-10">
                        3
                    </span>
                    <p className="font-manrope text-[22px] text-left text-brown3 leading-[32px] w-[226px] ml-[75px]">
                        {t("DNAGenealogyPage.researchDesignOptions.text7")}
                    </p>
                </div>
                <div className="border-l border-brown11 h-[132px] mx-[26px]"></div>
                <div className="relative flex justify-center w-[407px]">
                    <span className="absolute right-[290px] bottom-[-25px] font-cormorant text-[244px] leading-[296px] text-transparent bg-clip-text bg-search-intro-gradient-2 opacity-10">
                        4
                    </span>
                    <p className="font-manrope text-[22px] text-left text-brown3 leading-[32px] w-[315px] ml-[75px]">
                        {t("DNAGenealogyPage.researchDesignOptions.text8")}
                    </p>
                </div>
            </div>
            <div className={`border-b border-brown3 w-[1241px] mx-auto mt-[110px]`}></div>
            <p className="font-manrope text-[22px] text-center text-brown3 leading-[32px] mt-[63px]">
                {t("DNAGenealogyPage.researchDesignOptions.text9")} <br />
                {t("DNAGenealogyPage.researchDesignOptions.text10")} <br />
                <span className="underline">{t("DNAGenealogyPage.researchDesignOptions.text11")}</span>
            </p>
        </div>
    );
};

export default ResearchDesignOptions;
