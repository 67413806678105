import { FC } from "react";

interface SidebarLogoutSVGProps {
    className?: string;
}

const SidebarLogoutSVG: FC<SidebarLogoutSVGProps> = (props) => {
    return (
        <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99998 14H1.99998V1.99998H9.99998V2.99998H12V0H0V16H12V13H10V14H9.99998Z" fill="#323232" />
            <path
                d="M12.293 4.29297L10.8789 5.70702L12.1719 6.99998H7V9H12.1719L10.8789 10.293L12.293 11.707L16 7.99997L12.293 4.29297Z"
                fill="#323232"
            />
        </svg>
    );
};

export default SidebarLogoutSVG;
