import { FC, useRef, useState } from "react";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { getCurrentLanguageTranslation, Langs } from "../../domain/enums/common/LangsEnum";
import { useTranslation } from "react-i18next";
import CurrentLangIcon from "../shared/CurrentLangIcon";
import { useClickOutOfElement } from "../../custom-hooks/base-hooks/useClickOutOfElement";

interface LangSelectProps {
    additionalButtonClassName?: string;
    additionalPopupClassName?: string;
}

const langService: LanguageService = languageServiceFactory();

const LangSelect: FC<LangSelectProps> = (props) => {
    const { t } = useTranslation();
    const [isSelectLangVisible, setSelectLangVisibility] = useState<boolean>(false);
    const languagesListRef = useRef(null);

    const handleChangeLang = (lang: Langs) => {
        langService.setCurrentLang(lang);
        langService.notifyListeners(lang);
        setSelectLangVisibility(false);
    };

    useClickOutOfElement(languagesListRef, () => setSelectLangVisibility(false));

    return (
        <div ref={languagesListRef} className="relative flex items-center justify-center z-[1000]">
            <button
                type="button"
                onClick={() => setSelectLangVisibility(!isSelectLangVisible)}
                data-dropdown-toggle="language-dropdown-menu"
                className={`inline-flex items-center font-medium justify-center text-sm text-gray-900 rounded-lg cursor-pointer ${props.additionalButtonClassName}`}
            >
                <CurrentLangIcon className="rounded-[100%] cursor-pointer" />
            </button>
            <div
                className={`${
                    isSelectLangVisible ? "visible" : "hidden"
                } z-[100] my-4 list-none bg-beige4 shadow-beige4Section rounded-lg ${props.additionalPopupClassName}`}
                id="language-dropdown-menu"
            >
                <ul className="py-2 font-medium" role="none">
                    {langService.langs.map(
                        (lang) =>
                            !langService.isCurrentLang(lang) && (
                                <li onClick={() => handleChangeLang(lang)} key={`li-language-selection-${lang}`}>
                                    <button className="block px-4 py-2 text-sm text-gray-700" role="menuitem">
                                        <div className="font-cormorant font-medium text-[22px] inline-flex items-center hover:underline">
                                            <CurrentLangIcon
                                                className="rounded-[100%] cursor-pointer mr-[10px]"
                                                lang={lang}
                                            />
                                            {getCurrentLanguageTranslation(t, lang).label}
                                        </div>
                                    </button>
                                </li>
                            )
                    )}
                </ul>
            </div>
        </div>
    );
};

export default LangSelect;
