import { FC, useState } from "react";
import LoadingPanel from "../../shared/loading-panel/LoadingPanel";
import Toast from "../../shared/Toast";
import { ResponseMessageObj, defaultResponseMessageObj } from "../../../types/ApiResults";
import ChangePasswordForm from "./ChangePasswordForm";

const Settings: FC = () => {
    const [loading, setLoading] = useState(false);
    const [messageObj, setMessageObj] = useState<ResponseMessageObj>(defaultResponseMessageObj);

    const resetMessageObj = () => {
        setMessageObj(defaultResponseMessageObj);
    };

    return (
        <div className="relative z-0">
            <LoadingPanel isVisible={loading} />
            <ChangePasswordForm />
            <Toast messageObj={messageObj} resetMessageObj={resetMessageObj} />
        </div>
    );
};

export default Settings;
