import { FC, Fragment } from "react";
import EditPencilIconSVG from "../../shared/svg/admin-icons/EditPencilIconSVG";
import { District } from "../../../domain/models/District";
import { DistrictFields } from "../../../domain/enums/DistrictFieldsEnum";
import DistrictDeletionButton from "./DistrictDeletionButton";

interface DistrictsTableRowProps {
    district: District;
    updateHandler: (district: District) => void;
    deleteHandler: (district: District) => void;
}

const DistrictsTableRow: FC<DistrictsTableRowProps> = (props) => {
    const updateDistrictHandler = () => {
        props.updateHandler(props.district);
    };

    return (
        <tr className="bg-white border-b hover:bg-gray-200">
            {Object.values(DistrictFields).map((value, index) => {
                if (value === DistrictFields.ID) {
                    return <Fragment key={`td-districts-table-${index}`}></Fragment>;
                }
                return (
                    <td
                        key={`td-districts-table-${index}`}
                        className={`px-2 py-1 ${
                            value === DistrictFields.GEO_STR ? "max-w-[45px]" : "max-w-[95px]"
                        } truncate text-center border`}
                    >
                        {props.district[value]}
                    </td>
                );
            })}

            <td className="px-2 py-2 border">
                <div
                    onClick={updateDistrictHandler}
                    className="flex bg-green-600 hover:bg-green-700 items-center justify-center focus:ring-4 rounded-lg h-7 w-8"
                >
                    <EditPencilIconSVG iconColor="white" />
                </div>
            </td>
            <td className="py-2 px-2 border">
                <DistrictDeletionButton district={props.district} deleteHandler={props.deleteHandler} />
            </td>
        </tr>
    );
};

export default DistrictsTableRow;
