import { useState, useEffect } from "react";
import { errorServiceFactory } from "../../services/ErrorServiceImpl";
import { StatusCodes } from "http-status-codes";

const errorService = errorServiceFactory();

export function useErrorHandler(errorType: StatusCodes) {
    const [errorKey, setErrorKey] = useState("");

    useEffect(() => {
        errorService.resetErrorResponseSubject();
        errorService.subscribeOnError(errorType, () => {
            setErrorKey(`errors.response.${errorType}`);
        });
        return () => {
            errorService.resetErrorResponseSubject();
        };
    }, []);

    const resetErrors = () => {
        setErrorKey("");
        errorService.resetErrorResponseSubject();
    };

    const isError = () => {
        return errorService.getStatusFromErrorResponseSubject(errorType);
    };

    return {
        errorKey,
        setErrorKey,
        resetErrors,
        isError,
    };
}
