import { FC } from "react";

interface BottomCalloutLineSVGProps {
    className?: string;
}

const BottomCalloutLineSVG: FC<BottomCalloutLineSVGProps> = (props) => {
    return (
        <svg
            className={props.className}
            width="398"
            height="28"
            viewBox="0 0 398 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M2.5 3L43.5 27H398" stroke="#6A4F3E" />
            <circle cx="3" cy="3" r="3" fill="#6A4F3E" />
        </svg>
    );
};

export default BottomCalloutLineSVG;
