import { FC } from "react";
import StartSearchButtonArrowSVG from "../shared/svg/arrows/ButtonActionArrowSVG";
import { useTranslation } from "react-i18next";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { Link } from "react-router-dom";

const langService: LanguageService = languageServiceFactory();

const ResourceHelperSection: FC = () => {
    const { t } = useTranslation();
    return (
        <div id="resourceHelperSection" className="relative h-[637px] overflow-x-clip">
            <div className="absolute left-0 right-0 mx-auto w-full h-full flex">
                <div className="relative flex-grow bg-brown6">
                    <div className="mx-auto max-w-[800px] h-full">
                        <p className="absolute max-w-[571px] top-[93px] right-[79px] font-cormorant font-thin text-[45px] text-beige6 leading-[54.5px]">
                            {t("aboutPage.resourceHelperSection.text1")}
                        </p>
                        <ul className="absolute max-w-[574px] top-[240px] right-[87px] list-disc list-outside">
                            <li className="font-manrope font-light text-[22px] text-beige4 leading-[25.3px] mb-[25px]">
                                {t("aboutPage.resourceHelperSection.text2")}
                            </li>
                            <li className="font-manrope font-light text-[22px] text-beige4 leading-[25.3px] mb-[25px]">
                                {t("aboutPage.resourceHelperSection.text3")}
                            </li>
                            <li className="font-manrope font-light text-[22px] text-beige4 leading-[25.3px] mb-[25px]">
                                {t("aboutPage.resourceHelperSection.text4")}
                            </li>
                            <li className="font-manrope font-light text-[22px] text-beige4 leading-[25.3px] mb-[25px]">
                                {t("aboutPage.resourceHelperSection.text5")}
                            </li>
                            <li className="font-manrope font-light text-[22px] text-beige4 leading-[25.3px] mb-[25px]">
                                {t("aboutPage.resourceHelperSection.text6")}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="relative flex-grow bg-beige4 shadow-beige4Section">
                    <div className="mx-auto max-w-[1000px] h-full">
                        <p className="absolute max-w-[633px] top-[128px] left-[132px] font-liana text-[89.5px] text-brown3 leading-[89.5px]">
                            {t("aboutPage.resourceHelperSection.text7")}
                        </p>
                        <p className="absolute max-w-[688px] top-[265px] left-[132px] font-manrope font-normal text-[22px] text-brown3 leading-[32px]">
                            {t("aboutPage.resourceHelperSection.text8")} <br />{" "}
                            {t("aboutPage.resourceHelperSection.text9")}
                        </p>
                        <Link
                            to={"/search"}
                            className="absolute top-[433px] left-[132px] flex justify-center items-center bg-button-gradient max-w-[332px] h-[76px] font-semibold py-2.5 px-6 rounded-full"
                        >
                            <p
                                className={`font-cormorant leading-[34px] ${
                                    langService.isEnglish ? "text-[24px]" : "text-[26px]"
                                } bg-clip-text text-[transparent] bg-button-text-gradient mr-[10px]`}
                            >
                                {t("navigation.surnameMap")}
                            </p>
                            <StartSearchButtonArrowSVG />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResourceHelperSection;
