import { FC, useState } from "react";
import BaseModal from "../../shared/modals/BaseModal";
import { useTranslation } from "react-i18next";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";
import CustomInput from "../../shared/form-inputs/CustomInput";
import { InputType } from "../../../types/InputType";
import PlusIconSVG from "../../shared/svg/admin-icons/PlusIconSVG";
import { get } from "lodash";
import { englishSymbols, ukrainianSymbols } from "../../../domain/constants";
import { DistrictDTO } from "../../../domain/models/dto/DistrictDTO";
import { districtFields, districtSchemaFactory } from "../../../validation-schemas/DistrictSchema";
import { District } from "../../../domain/models/District";
import CustomTextArea from "../../shared/form-inputs/CustomTextArea";
import CommonError from "../../shared/form-inputs/CommonError";
import { useErrorHandler } from "../../../custom-hooks/base-hooks/useErrorHandler";
import { StatusCodes } from "http-status-codes";
import transliterationServiceFactory from "../../../services/TransliterationServiceImpl";

interface DistrictCreationModalFormProps {
    selectedDistrict: District;
    resetSelectedDistrict: () => void;
    createOrUpdateDistrict: (dto: DistrictDTO) => Promise<void>;
}

const transliterationService = transliterationServiceFactory();

const DistrictCreationModalForm: FC<DistrictCreationModalFormProps> = (props) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    const { errorKey, setErrorKey, resetErrors, isError } = useErrorHandler(StatusCodes.BAD_REQUEST);
    const { form } = useCustomFormik<DistrictDTO>({
        initial: props.selectedDistrict ? { ...props.selectedDistrict, geo: props.selectedDistrict.geoStr } : null,
        fields: districtFields,
        schema: districtSchemaFactory(t),
        onSubmit: async (values, handlers) => {
            try {
                resetErrors();
                await props.createOrUpdateDistrict({ ...values, geo: JSON.parse(values.geo as string) });
                if (!isError()) {
                    setIsVisible(false);
                    handlers.resetForm();
                }
            } catch (error) {
                setErrorKey(error instanceof SyntaxError ? "errors.validation.syntaxError" : "errors.common");
            }
        },
    });

    const closeHandler = () => {
        resetErrors();
        props.resetSelectedDistrict();
        form.resetForm();
    };

    const onInputBlur = (source: keyof DistrictDTO, target: keyof DistrictDTO) => {
        return () => {
            const value = get(form, "values." + source);
            if (!value || value === get(props.selectedDistrict, source)) return;
            form.setFieldValue(target, transliterationService.transliterate(value).toUpperCase());
        };
    };

    return (
        <BaseModal
            isVisible={!!props.selectedDistrict?.id || isVisible}
            setIsVisible={setIsVisible}
            title={t(`admin.common.${props.selectedDistrict?.id ? "update" : "create"}`)}
            confirmTitle={t(`admin.common.apply`)}
            cancelTitle={t(`admin.common.cancel`)}
            buttonElement={
                <button
                    data-modal-target="static-modal"
                    data-modal-toggle="static-modal"
                    className={`flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center max-h-10`}
                    type="button"
                    title={t(`admin.common.${props.selectedDistrict?.id ? "update" : "create"}`)}
                    onClick={() => setIsVisible(true)}
                >
                    <PlusIconSVG className="w-[25px] fill-white" />
                </button>
            }
            confirmHandler={form.handleSubmit}
            closeHandler={closeHandler}
            additionalWrapClassName="relative w-[750px]"
        >
            <div className="flex justify-between">
                <CustomInput
                    type={districtFields.districtUk.type as InputType}
                    labelKey={districtFields.districtUk.placeholderKey}
                    value={form.values.districtUk}
                    name="districtUk"
                    placeholderKey={districtFields.districtUk.placeholderKey}
                    errorKey={form.errors.districtUk as string}
                    onChange={form.handleChange}
                    onBlur={onInputBlur("districtUk", "districtEn")}
                    pattern={ukrainianSymbols}
                    className="w-[325px]"
                />
                <CustomInput
                    type={districtFields.districtEn.type as InputType}
                    labelKey={districtFields.districtEn.placeholderKey}
                    value={form.values.districtEn}
                    name="districtEn"
                    placeholderKey={districtFields.districtEn.placeholderKey}
                    errorKey={form.errors.districtEn as string}
                    onChange={form.handleChange}
                    pattern={englishSymbols}
                    className="w-[325px]"
                />
            </div>
            <CustomTextArea
                labelKey={districtFields.geo.placeholderKey}
                value={form.values.geo as string}
                name="geo"
                placeholderKey={districtFields.geo.placeholderKey}
                errorKey={form.errors.geo as string}
                onChange={form.handleChange}
                className="w-[680px] h-[300px]"
            />
            <CommonError name="" errorKey={errorKey} />
        </BaseModal>
    );
};

export default DistrictCreationModalForm;
