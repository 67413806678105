import { FC } from "react";
import ResidentCreationModalForm from "./ResidentCreationModalForm";
import { ResidentDTO } from "../../../domain/models/dto/ResidentDTO";
import ResidentsSearchFiltersForm from "./ResidentsSearchFiltersForm";
import { FormikProps } from "formik";
import { SelectProps, SelectValue } from "../../../types/SelectProps";
import CustomDropdown from "../../shared/form-inputs/CustomDropdown";
import { PaginationEnum } from "../../../domain/enums/common/PaginationEnum";
import { enumToSelectProps, enumValueToSelectProps } from "../../../tools/EnumTools";
import { useTranslation } from "react-i18next";
import FilterResetIconSVG from "../../shared/svg/admin-icons/FilterResetIconSVG";

interface ResidentsHeaderProps {
    selectedResident: ResidentDTO;
    searchFiltersForm: FormikProps<ResidentDTO>;
    districts: SelectProps[];
    filtersFields: ResidentDTO;
    resetSelectedResident: () => void;
    createOrUpdateResident: (dto: ResidentDTO) => Promise<void>;
    limitHandler: (value: SelectValue) => void;
    resetFiltersHandler: () => void;
}

const ResidentsHeader: FC<ResidentsHeaderProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="bg-gray-100 w-[95%] mt-[10px] mr-auto mb-[10px] py-2 px-3 ml-auto flex items-center shadow-md rounded-xl">
            <div className="pr-4 border-r-[1px] border-gray-300">
                <ResidentCreationModalForm
                    selectedResident={props.selectedResident}
                    districts={props.districts}
                    resetSelectedResident={props.resetSelectedResident}
                    createOrUpdateResident={props.createOrUpdateResident}
                />
            </div>
            <div className="flex items-center pr-4 border-r-[1px] border-gray-300">
                <ResidentsSearchFiltersForm
                    form={props.searchFiltersForm}
                    filtersFields={props.filtersFields}
                    districts={props.districts}
                />
                <button
                    className="flex items-center text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center h-10 ml-2"
                    onClick={props.resetFiltersHandler}
                    title={t("admin.common.filtersReset")}
                >
                    <FilterResetIconSVG className="w-[20px] fill-white" />
                </button>
            </div>
            <div className="flex items-center pr-4">
                <h3 className="mr-2 ml-4 text-[16px] font-semibold text-gray-500">{t("pagination.show")}</h3>
                <CustomDropdown
                    defaultValue={enumValueToSelectProps(PaginationEnum.SHOW_20, "pagination")}
                    data={enumToSelectProps(PaginationEnum, "pagination")}
                    dropDownCallback={props.limitHandler}
                    dropdownClassName="w-[80px]"
                />
            </div>
        </div>
    );
};

export default ResidentsHeader;
