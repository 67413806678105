import { FC } from "react";

interface HomeButtonSVGProps {
    fill?: string;
}

const HomeButtonSVG: FC<HomeButtonSVGProps> = (props) => {
    const fill = props.fill ? props.fill : "#fff";
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={fill} stroke="none">
                <path
                    d="M2495 4626 c-16 -8 -510 -495 -1097 -1083 -954 -955 -1067 -1072
-1074 -1106 -9 -51 1 -94 31 -132 46 -62 65 -65 349 -65 l256 0 0 -828 c0
-899 -1 -881 56 -994 59 -117 196 -220 327 -248 35 -7 181 -10 425 -8 356 3
372 4 398 24 15 11 37 33 48 48 20 27 21 42 26 566 5 524 6 539 26 566 51 68
53 69 294 69 241 0 243 -1 294 -69 20 -27 21 -42 26 -566 5 -524 6 -539 26
-566 11 -15 33 -37 48 -48 26 -20 42 -21 398 -24 244 -2 390 1 425 8 131 28
268 131 327 248 57 113 56 95 56 994 l0 828 256 0 c284 0 303 3 349 65 30 38
40 81 31 132 -7 34 -120 151 -1074 1107 -625 625 -1080 1074 -1099 1082 -40
17 -89 17 -128 0z"
                />
            </g>
        </svg>
    );
};

export default HomeButtonSVG;
