import { FC } from "react";
import DNAIntro from "./DNAIntro";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ResearchBenefits from "./ResearchBenefits";
import ResearchDesignOptions from "./ResearchDesignOptions";

const DNAGenealogyPage: FC = () => {
    return (
        <>
            <Header />
            <DNAIntro />
            <ResearchBenefits />
            <ResearchDesignOptions />
            <Footer />
        </>
    );
};

export default DNAGenealogyPage;
