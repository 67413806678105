import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { SearchResidentsForm } from "../domain/models/Resident";

export const searchResidentsFields: Fields<SearchResidentsForm> = {
    lastNameFathers: {
        min: 1,
        max: 128,
        placeholderKey: `surnameMapPage.surnameMapSection.lastNameFathers`,
        type: "text",
        initial: "",
        optional: true,
    },
    lastNameMothers: {
        min: 1,
        max: 128,
        placeholderKey: `surnameMapPage.surnameMapSection.lastNameMothers`,
        type: "text",
        initial: "",
        optional: true,
    },
};

export const searchResidentsSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, searchResidentsFields);
};
