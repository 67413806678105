import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";

const langService: LanguageService = languageServiceFactory();

const FamilyInterviewAdvice: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="relative flex h-[1142px]">
            <div className="absolute left-0 right-0 mx-auto w-full h-full flex overflow-x-clip">
                <div className="relative z-10 flex-grow bg-button-gradient h-full w-[845px]">
                    <div className="absolute right-[85px] top-[15px] w-[415px] h-[485px] transform rotate-[10.5deg] bg-gradient-to-b from-[#A69168] to-[#A18767] p-[20px] shadow-familyPhoto">
                        <img
                            className="max-w-none w-full"
                            src="/img/recommendations-page/familyInterviewAdvice1.png"
                            alt="Top"
                        />
                    </div>
                    <div className="absolute right-[400px] top-[300px] w-[395px] h-[510px] transform rotate-[-15.7deg] bg-gradient-to-b from-[#D5C79C] to-[#B69B7B] p-[20px] shadow-familyPhoto">
                        <img
                            className="max-w-none w-full"
                            src="/img/recommendations-page/familyInterviewAdvice2.png"
                            alt="Middle"
                        />
                    </div>
                    <div className="absolute right-[10px] top-[535px] w-[440px] h-[575px] transform rotate-[16.5deg] bg-gradient-to-b from-[#D7CAA1] to-[#B69B7B] p-[20px] shadow-familyPhoto">
                        <img
                            className="max-w-none w-full"
                            src="/img/recommendations-page/familyInterviewAdvice3.png"
                            alt="Bottom"
                        />
                    </div>
                </div>
                <div className="relative flex-grow bg-beige1 h-full w-[1076px] shadow-beige4Section">
                    <p
                        className={`absolute top-[146px] left-[135px] ${
                            langService.isUkrainian ? "w-[535px]" : "w-[640px]"
                        } font-manrope font-normal text-[22px] text-brown3 leading-[32px]`}
                    >
                        {t("searchRecommendationsPage.familyInterviewAdvice.text1")} <br />
                        {t("searchRecommendationsPage.familyInterviewAdvice.text2")}
                    </p>
                    <div
                        className={`absolute top-[220px] left-[135px] font-cormorant ${
                            langService.isUkrainian ? "w-[613px] text-[67.2px]" : "w-[675px] text-[64.2px]"
                        } text-brown3 leading-[70px]`}
                    >
                        <p className="mt-[40px]">{t("searchRecommendationsPage.familyInterviewAdvice.text3")}</p>
                        <span className="font-bold">{t("searchRecommendationsPage.familyInterviewAdvice.text4")}</span>
                    </div>
                    <p
                        className={`absolute left-[135px] w-[690px] font-manrope font-normal ${
                            langService.isUkrainian ? "text-[22px] top-[520px]" : "text-[20px] top-[590px]"
                        } text-brown3 leading-[32px]`}
                    >
                        {t("searchRecommendationsPage.familyInterviewAdvice.text5")}
                    </p>
                    <div
                        className={`absolute left-[135px] w-[690px] font-manrope font-normal ${
                            langService.isUkrainian ? "text-[22px] top-[650px]" : "text-[20px] top-[710px]"
                        } text-brown3 leading-[32px]`}
                    >
                        <p>{t("searchRecommendationsPage.familyInterviewAdvice.text6")}</p>
                        <p>{t("searchRecommendationsPage.familyInterviewAdvice.text7")}</p>
                        <span className="font-bold">{t("searchRecommendationsPage.familyInterviewAdvice.text8")}</span>
                    </div>
                    <div
                        className={`absolute left-[135px] w-[692px] ${
                            langService.isUkrainian ? "top-[800px] h-[100px]" : "top-[880px] h-[20px]"
                        } border-b border-brown3`}
                    ></div>
                    <p className="absolute top-[890px] left-[135px] w-[692px] font-manrope font-lighter text-[18px] text-brown3 leading-[26px] mt-[38px]">
                        {t("searchRecommendationsPage.familyInterviewAdvice.text9")} <br />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FamilyInterviewAdvice;
