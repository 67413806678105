import { FC } from "react";

interface TopCalloutLineSVGProps {
    className?: string;
}

const TopCalloutLineSVG: FC<TopCalloutLineSVGProps> = (props) => {
    return (
        <svg
            className={props.className}
            width="342"
            height="44"
            viewBox="0 0 342 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3 40.5L43 0.5H342" stroke="#6A4F3E" />
            <circle cx="3" cy="41" r="3" fill="#6A4F3E" />
        </svg>
    );
};

export default TopCalloutLineSVG;
