import { FC } from "react";

interface FacebookIconSVGProps {
    className?: string;
}

const FacebookIconSVG: FC<FacebookIconSVGProps> = (props) => {
    return (
        <svg
            className={props.className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M34 17.0426C34 7.63509 26.384 0 17 0C7.616 0 0 7.63509 0 17.0426C0 25.2912 5.848 32.1594 13.6 33.7444V22.1554H10.2V17.0426H13.6V12.782C13.6 9.49273 16.269 6.81704 19.55 6.81704H23.8V11.9298H20.4C19.465 11.9298 18.7 12.6967 18.7 13.6341V17.0426H23.8V22.1554H18.7V34C27.285 33.1479 34 25.8877 34 17.0426Z"
                fill="#EBDCB5"
            />
        </svg>
    );
};

export default FacebookIconSVG;
