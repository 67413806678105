import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../../shared/modals/BaseModal";
import DeleteTrashIconSVG from "../../shared/svg/admin-icons/DeleteTrashIconSVG";
import { Archive } from "../../../domain/models/Archive";

interface ArchiveDeletionButtonProps {
    archive: Archive;
    deleteHandler: (resident: Archive) => void;
}

const ArchiveDeletionButton: FC<ArchiveDeletionButtonProps> = (props) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    const deleteArchiveHandler = () => {
        props.deleteHandler(props.archive);
    };

    return (
        <BaseModal
            buttonElement={
                <div
                    onClick={() => setIsVisible(true)}
                    className="flex items-center justify-center bg-red-600 hover:bg-red-700 focus:ring-4 rounded-lg h-7 w-8"
                >
                    <DeleteTrashIconSVG iconColor="white" />
                </div>
            }
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            title={t("admin.common.delete")}
            confirmTitle={t("admin.common.apply")}
            cancelTitle={t("admin.common.cancel")}
            confirmHandler={deleteArchiveHandler}
            additionalConfirmButtonClassName="bg-red-600 hover:bg-red-700 focus:ring-4"
        >
            <div>{t("admin.common.deleteText")}</div>
        </BaseModal>
    );
};

export default ArchiveDeletionButton;
