import { FC } from "react";
import TopCalloutLineSVG from "../shared/svg/callout-lines/TopCalloutLineSVG";
import MiddleCalloutLineSVG from "../shared/svg/callout-lines/MiddleCalloutLineSVG";
import BottomCalloutLineSVG from "../shared/svg/callout-lines/BottomCalloutLineSVG";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { useTranslation } from "react-i18next";

const langService: LanguageService = languageServiceFactory();

const GenealogistInfoSection: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="bg-beige4 py-16 px-8 flex flex-col items-center shadow-beige4Section overflow-x-clip">
            <div className="text-center max-w-[800px]">
                <h2 className="font-cormorant text-[45px] font-medium leading-[54.5px] text-brown1 mt-[62px]">
                    {t("aboutPage.genealogistInfoSection.text1")}{" "}
                    <span className="font-semibold">{t("aboutPage.genealogistInfoSection.text2")}</span>
                    {"."}
                </h2>
            </div>

            <div className="flex items-center">
                {/* Left Section */}
                <div className="bg-genealogist-text-block-gradient shadow-lg px-[36px] py-[76px] rounded-[29px] w-[490px] text-brown1 border border-brown3">
                    <h3 className="font-cormorant font-normal text-[24.2px] leading-[29.3px] mb-4">
                        {langService.isEnglish ? t("aboutPage.genealogistInfoSection.text12") + " " : ""}
                        <span className={`${langService.isEnglish ? "font-semibold" : ""}`}>
                            {t("aboutPage.genealogistInfoSection.text3")}{" "}
                        </span>
                        <span className={`${langService.isUkrainian ? "font-semibold" : ""}`}>
                            {t("aboutPage.genealogistInfoSection.text4")}
                        </span>
                    </h3>
                    <p className="font-manrope text-[18px] leading-[24.6px]">
                        {t("aboutPage.genealogistInfoSection.text5")}
                        <br />
                        {t("aboutPage.genealogistInfoSection.text6")}
                    </p>
                    <p className="font-liana text-[23.1px] leading-[31.6px] mt-4">
                        {t("aboutPage.genealogistInfoSection.text7")}
                    </p>
                </div>

                {/* Middle Section */}
                <div className="ml-[63px] mt-[37px] mr-[37px]">
                    <img src="/img/about-page/genealogistInfoBlock.png" alt="Genealogist" />
                </div>

                {/* Right Section */}
                <div className="relative">
                    <p className="font-manrope text-[22px] text-brown3 leading-[30px] w-[296px] ml-[44px] mb-[17.5px]">
                        {t("aboutPage.genealogistInfoSection.text8")}{" "}
                    </p>
                    <TopCalloutLineSVG className="" />
                    <p className="font-manrope text-[22px] text-brown3 leading-[30px] w-[296px] mt-[36px] ml-[44px] mb-[17.5px]">
                        {t("aboutPage.genealogistInfoSection.text9")}{" "}
                    </p>
                    <MiddleCalloutLineSVG className="" />
                    <p className="font-manrope text-[22px] text-brown3 leading-[30px] w-[419px] mt-[45px] ml-[44px] mb-[-10px]">
                        {t("aboutPage.genealogistInfoSection.text10")} <br />{" "}
                        {t("aboutPage.genealogistInfoSection.text11")}
                    </p>
                    <BottomCalloutLineSVG className="" />
                </div>
            </div>
        </div>
    );
};

export default GenealogistInfoSection;
