import { FC, useState } from "react";
import BaseModal from "../../shared/modals/BaseModal";
import { useTranslation } from "react-i18next";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";
import CustomInput from "../../shared/form-inputs/CustomInput";
import { InputType } from "../../../types/InputType";
import PlusIconSVG from "../../shared/svg/admin-icons/PlusIconSVG";
import { ResidentDTO } from "../../../domain/models/dto/ResidentDTO";
import { residentFields, residentSchemaFactory } from "../../../validation-schemas/ResidentSchema";
import CustomDropdown from "../../shared/form-inputs/CustomDropdown";
import { SelectProps } from "../../../types/SelectProps";
import { get } from "lodash";
import { englishSymbols, ukrainianSymbols } from "../../../domain/constants";
import transliterationServiceFactory from "../../../services/TransliterationServiceImpl";

interface ResidentCreationModalFormProps {
    selectedResident: ResidentDTO;
    districts: SelectProps[];
    resetSelectedResident: () => void;
    createOrUpdateResident: (dto: ResidentDTO) => Promise<void>;
}

const transliterationService = transliterationServiceFactory();

const ResidentCreationModalForm: FC<ResidentCreationModalFormProps> = (props) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    const { form } = useCustomFormik<ResidentDTO>({
        initial: props.selectedResident,
        fields: residentFields,
        schema: residentSchemaFactory(t),
        onSubmit: async (values, handlers) => {
            await props.createOrUpdateResident(values);
            setIsVisible(false);
            handlers.resetForm();
        },
    });

    const closeHandler = () => {
        props.resetSelectedResident();
        form.resetForm();
    };

    const onInputBlur = (source: keyof ResidentDTO, target: keyof ResidentDTO) => {
        return () => {
            const value = get(form, "values." + source);
            if (!value || value === get(props.selectedResident, source)) return;
            form.setFieldValue(target, transliterationService.transliterate(value).toUpperCase());
        };
    };

    const getDefaultDistrict = () => {
        return props.districts.find((item) => item.value === props.selectedResident?.districtId) || props.districts[0];
    };

    return (
        <BaseModal
            isVisible={!!props.selectedResident?.id || isVisible}
            setIsVisible={setIsVisible}
            title={t(`admin.common.${props.selectedResident?.id ? "update" : "create"}`)}
            confirmTitle={t(`admin.common.apply`)}
            cancelTitle={t(`admin.common.cancel`)}
            buttonElement={
                <button
                    data-modal-target="static-modal"
                    data-modal-toggle="static-modal"
                    className={`flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center max-h-10`}
                    type="button"
                    title={t(`admin.common.${props.selectedResident?.id ? "update" : "create"}`)}
                    onClick={() => setIsVisible(true)}
                >
                    <PlusIconSVG className="w-[25px] fill-white" />
                </button>
            }
            confirmHandler={form.handleSubmit}
            closeHandler={closeHandler}
            additionalWrapClassName="w-[600px]"
        >
            <div className="flex justify-between">
                <CustomInput
                    type={residentFields.firstNameUk.type as InputType}
                    labelKey={residentFields.firstNameUk.placeholderKey}
                    value={form.values.firstNameUk}
                    name="firstNameUk"
                    placeholderKey={residentFields.firstNameUk.placeholderKey}
                    errorKey={form.errors.firstNameUk as string}
                    onChange={form.handleChange}
                    onBlur={onInputBlur("firstNameUk", "firstNameEn")}
                    pattern={ukrainianSymbols}
                    className="w-[255px]"
                />
                <CustomInput
                    type={residentFields.firstNameEn.type as InputType}
                    labelKey={residentFields.firstNameEn.placeholderKey}
                    value={form.values.firstNameEn}
                    name="firstNameEn"
                    placeholderKey={residentFields.firstNameEn.placeholderKey}
                    errorKey={form.errors.firstNameEn as string}
                    onChange={form.handleChange}
                    pattern={englishSymbols}
                    className="w-[255px]"
                />
            </div>
            <div className="flex justify-between">
                <CustomInput
                    type={residentFields.lastNameUk.type as InputType}
                    labelKey={residentFields.lastNameUk.placeholderKey}
                    value={form.values.lastNameUk}
                    name="lastNameUk"
                    placeholderKey={residentFields.lastNameUk.placeholderKey}
                    errorKey={form.errors.lastNameUk as string}
                    onChange={form.handleChange}
                    onBlur={onInputBlur("lastNameUk", "lastNameEn")}
                    pattern={ukrainianSymbols}
                    className="w-[255px]"
                />
                <CustomInput
                    type={residentFields.lastNameEn.type as InputType}
                    labelKey={residentFields.lastNameEn.placeholderKey}
                    value={form.values.lastNameEn}
                    name="lastNameEn"
                    placeholderKey={residentFields.lastNameEn.placeholderKey}
                    errorKey={form.errors.lastNameEn as string}
                    onChange={form.handleChange}
                    pattern={englishSymbols}
                    className="w-[255px]"
                />
            </div>
            <div className="flex justify-between">
                <CustomInput
                    type={residentFields.regionUk.type as InputType}
                    labelKey={residentFields.regionUk.placeholderKey}
                    value={form.values.regionUk}
                    name="regionUk"
                    placeholderKey={residentFields.regionUk.placeholderKey}
                    errorKey={form.errors.regionUk as string}
                    onChange={form.handleChange}
                    onBlur={onInputBlur("regionUk", "regionEn")}
                    pattern={ukrainianSymbols}
                    className="w-[255px]"
                />
                <CustomInput
                    type={residentFields.regionEn.type as InputType}
                    labelKey={residentFields.regionEn.placeholderKey}
                    value={form.values.regionEn}
                    name="regionEn"
                    placeholderKey={residentFields.regionEn.placeholderKey}
                    errorKey={form.errors.regionEn as string}
                    onChange={form.handleChange}
                    pattern={englishSymbols}
                    className="w-[255px]"
                />
            </div>
            <CustomDropdown
                labelKey={residentFields.districtId.placeholderKey}
                errorKey={form.errors.district as string}
                defaultValue={getDefaultDistrict()}
                data={props.districts}
                dropDownCallback={(value) => form.setFieldValue("districtId", value)}
                buttonClassName="w-[255px]"
                wrapperClassName="block mb-6"
                dropdownClassName="max-h-[140px] w-[255px]"
            />
            <div className="flex justify-between">
                <CustomInput
                    type={residentFields.localityUk.type as InputType}
                    labelKey={residentFields.localityUk.placeholderKey}
                    value={form.values.localityUk}
                    name="localityUk"
                    placeholderKey={residentFields.localityUk.placeholderKey}
                    errorKey={form.errors.localityUk as string}
                    onChange={form.handleChange}
                    onBlur={onInputBlur("localityUk", "localityEn")}
                    pattern={ukrainianSymbols}
                    className="w-[255px]"
                />
                <CustomInput
                    type={residentFields.localityEn.type as InputType}
                    labelKey={residentFields.localityEn.placeholderKey}
                    value={form.values.localityEn}
                    name="localityEn"
                    placeholderKey={residentFields.localityEn.placeholderKey}
                    errorKey={form.errors.localityEn as string}
                    onChange={form.handleChange}
                    pattern={englishSymbols}
                    className="w-[255px]"
                />
            </div>
        </BaseModal>
    );
};

export default ResidentCreationModalForm;
