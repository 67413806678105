import { ChangeEvent, FC, FocusEventHandler, FormEvent } from "react";
import { InputType } from "../../../types/InputType";
import { useTranslation } from "react-i18next";
import CommonError from "./CommonError";

interface CustomInputProps {
    type: InputType;
    labelKey?: string;
    labelClassName?: string;
    value?: string | number;
    name?: string;
    placeholderKey?: string;
    errorKey?: string;
    disabled?: boolean;
    className?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: FocusEventHandler;
    onFocus?: FocusEventHandler;
    pattern?: RegExp;
}

const CustomInput: FC<CustomInputProps> = (props) => {
    const { t } = useTranslation();

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(e);
    };

    const onInput = (e: FormEvent<HTMLInputElement>) => {
        if (props.pattern && !props.pattern.test(e.currentTarget.value)) {
            e.currentTarget.value = (props.value as string) || "";
        }
    };

    return (
        <div className="flex flex-col">
            {props.labelKey && (
                <label
                    className={`block mb-2 text-lg font-semibold text-[#344054] ${props.labelClassName}`}
                    htmlFor={props.labelKey}
                >
                    {t(props.labelKey)}
                </label>
            )}
            <input
                autoComplete="off"
                type={props.type}
                id={props.labelKey}
                value={props.value || ""}
                name={props.name || ""}
                placeholder={t(props.placeholderKey)}
                onChange={onChange}
                onInput={onInput}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                disabled={props.disabled}
                className={`px-3 py-2 text-base text-[#344054] bg-white border-2 border-[#d0d5dd] shadow-sm rounded-lg placeholder-[#667085] ${props.className}`}
            />
            <CommonError errorKey={props.errorKey} name={props.name} />
        </div>
    );
};

export default CustomInput;
