import * as Yup from "yup";
import YupPassword from "yup-password";
import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";

YupPassword(Yup);

export function schemaFactory<T extends Object>(
    t: TFunction,
    fields: Fields<T>
) {
    return Yup.object().shape(
        Object.entries(fields).reduce((acc, [key, value]) => {
            let schema:
                | Yup.StringSchema
                | Yup.NumberSchema
                | Yup.BooleanSchema
                | Yup.MixedSchema<any>
                | Yup.ArraySchema<any, any, any> = Yup.string();
            if (value.type === "number") {
                schema = Yup.number();
            } else if (value.type === "select") {
                schema = Yup.mixed().oneOf(
                    value.selectOptions?.map((option) => option.value) ?? [],
                    t("errors.validation.invalidOption")
                );
            } else if (value.type === "boolean") {
                schema = Yup.boolean();
            } else if (value.type === "email") {
                schema = Yup.string().email(
                    t("errors.validation.invalidEmail")
                );
            } else if (value.type === "password") {
                schema = Yup.string()
                    .password()
                    .minSymbols(1, t("errors.validation.password.minSymbols"))
                    .minLowercase(
                        2,
                        t("errors.validation.password.minLowercase")
                    )
                    .minUppercase(
                        1,
                        t("errors.validation.password.minUppercase")
                    )
                    .minNumbers(1, t("errors.validation.password.minNumbers"));
            } else if (value.pattern) {
                schema = Yup.string().matches(
                    value.pattern,
                    t(value.patternErrorKey)
                );
            }

            if (
                schema instanceof Yup.StringSchema ||
                schema instanceof Yup.NumberSchema
            ) {
                schema = schema
                    .min(value.min, t("errors.validation.min") + value.min)
                    .max(value.max, t("errors.validation.max") + value.max);
            }

            schema = value.optional
                ? schema.optional().nullable()
                : schema.required(t("errors.validation.required"));

            if (value.array) {
                schema = Yup.array().of(schema);
            }

            acc[key] = schema;
            return acc;
        }, {} as Yup.ObjectShape)
    );
}
