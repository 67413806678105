import { FC, useRef, useState } from "react";
import DropdownArrow from "../svg/arrows/DropdownArrowSVG";
import { useTranslation } from "react-i18next";
import { SelectProps, SelectValue } from "../../../types/SelectProps";
import { useClickOutOfElement } from "../../../custom-hooks/base-hooks/useClickOutOfElement";
import CommonError from "./CommonError";

interface CustomDropdownProps {
    defaultValue?: SelectProps;
    data: SelectProps[];
    errorKey?: string;
    dropDownCallback: (value: SelectValue) => void;
    labelKey?: string;
    buttonClassName?: string;
    wrapperClassName?: string;
    dropdownClassName?: string;
}

const CustomDropdown: FC<CustomDropdownProps> = (props) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [currentOption, setCurrentOption] = useState<SelectProps>(props.defaultValue);
    const dropDownRef = useRef();

    useClickOutOfElement(dropDownRef, () => setIsVisible(false));

    const dropDownHandler = (option: SelectProps) => {
        setCurrentOption(option);
        props.dropDownCallback(option.value);
        setIsVisible(false);
    };

    return (
        <div className={`relative ${props.wrapperClassName}`} ref={dropDownRef}>
            {props.labelKey && (
                <label className="block mb-2 text-lg font-semibold text-[#344054]" htmlFor={props.labelKey}>
                    {t(props.labelKey)}
                </label>
            )}
            <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className={`flex justify-between text-gray-500 bg-white border-2 border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center ${props.buttonClassName}`}
                type="button"
                onClick={() => setIsVisible(!isVisible)}
            >
                {t(currentOption?.labelKey)} <DropdownArrow />
            </button>

            {/* <!-- Dropdown menu --> */}
            <div
                id="dropdown"
                className={`absolute z-10 ${
                    isVisible ? "visible" : "hidden"
                } bg-white divide-y divide-gray-100 rounded-lg shadow`}
            >
                <ul
                    className={`py-2 text-sm text-gray-700 ${props.dropdownClassName} overflow-auto`}
                    aria-labelledby="dropdownDefaultButton"
                >
                    {props.data.map((option) => (
                        <li onClick={() => dropDownHandler(option)} key={`li-custom-dropdown-${option.value}`}>
                            <button className="block px-4 py-2 hover:bg-gray-100">{t(option.labelKey)}</button>
                        </li>
                    ))}
                </ul>
            </div>
            <CommonError errorKey={props.errorKey} name="" />
        </div>
    );
};
export default CustomDropdown;
