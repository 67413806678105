import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";

const langService: LanguageService = languageServiceFactory();

const FamilyStoriesSection: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="pt-[176px] pb-[197px] h-[1063px] bg-[#f3e7c4]">
            <div className="absolute left-0 right-0 mx-auto max-w-[1530px] grid grid-cols-2 gap-[62px] overflow-x-clip">
                <div
                    className={`font-cormorant font-light ${
                        langService.isEnglish ? "text-[56.2px]" : "text-[67.2px]"
                    } text-brown3 leading-[81.4px]`}
                >
                    <p className="mb-[30px]">{t("aboutPage.familyStoriesSection.text1")}</p>
                    <p className="mb-[46px]">
                        <span className="font-semibold italic">{t("aboutPage.familyStoriesSection.text2")} </span>
                        {t("aboutPage.familyStoriesSection.text3")}
                    </p>
                    <p
                        className={`font-liana ${
                            langService.isEnglish ? "text-[43.1px]" : "text-[48.1px]"
                        } leading-[42.8px]`}
                    >
                        {t("aboutPage.familyStoriesSection.text4")}
                    </p>
                </div>
                <div className="max-w-[600px]">
                    <p className="font-manrope font-normal text-[22px] text-brown3 leading-[32px] mb-[40px]">
                        {t("aboutPage.familyStoriesSection.text5")}
                    </p>
                    <p className="font-manrope font-normal text-[22px] text-brown3 leading-[32px] mb-[40px]">
                        <span className="font-semibold">{t("aboutPage.familyStoriesSection.text6")}</span>{" "}
                        {t("aboutPage.familyStoriesSection.text7")}
                    </p>
                    <ul className="list-disc list-inside">
                        <li className="font-manrope font-normal text-[22px] text-brown3 leading-[32px] mb-[25px]">
                            {t("aboutPage.familyStoriesSection.text8")}
                        </li>
                        <li className="font-manrope font-normal text-[22px] text-brown3 leading-[32px] mb-[25px]">
                            {t("aboutPage.familyStoriesSection.text9")}
                        </li>
                        <li className="font-manrope font-normal text-[22px] text-brown3 leading-[32px]">
                            {t("aboutPage.familyStoriesSection.text10")}
                        </li>
                    </ul>
                    <p
                        className={`font-liana text-[32.1px] text-brown3 leading-[35px] ${
                            langService.isEnglish ? "mt-[100.5px]" : "mt-[72.5px]"
                        }`}
                    >
                        {t("aboutPage.familyStoriesSection.text11")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FamilyStoriesSection;
