import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
    ResponseMessageObj,
    defaultResponseMessageObj,
} from "../types/ApiResults";
import { ResponseMessage } from "../domain/enums/common/ResponseMessageEnum";

function useToastMessage() {
    const [message, setMessage] = useState<ResponseMessageObj>(
        defaultResponseMessageObj
    );

    return {
        message,
        setMessage: (messageKey: string, messageType: ResponseMessage) =>
            setMessage({
                id: uuidv4(),
                messageType,
                messageKey,
            }),
        resetMessage: () => setMessage(defaultResponseMessageObj),
    };
}
export default useToastMessage;
