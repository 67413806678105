import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { NullableCollection, ResponseMessageObj, defaultResponseMessageObj } from "../../../types/ApiResults";
import { DEFAULT_PAGE } from "../../../domain/constants";
import { PaginationEnum } from "../../../domain/enums/common/PaginationEnum";
import { ResponseMessage } from "../../../domain/enums/common/ResponseMessageEnum";
import { SelectValue } from "../../../types/SelectProps";
import LoadingPanel from "../../shared/loading-panel/LoadingPanel";
import Toast from "../../shared/Toast";
import Pagination from "../../shared/Pagination";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";
import { useTranslation } from "react-i18next";
import languageServiceFactory from "../../../services/LanguageServiceImpl";
import { District, DistrictsCollection } from "../../../domain/models/District";
import { DistrictDTO } from "../../../domain/models/dto/DistrictDTO";
import districtServiceFactory from "../../../services/DistrictServiceImpl";
import DistrictsHeader from "./DistrictsHeader";
import {
    districtsSearchFiltersFields,
    districtsSearchFiltersSchemaFactory,
} from "../../../validation-schemas/DistrictsSearchFiltersSchema";
import DistrictsTable from "./DistrictsTable";

const districtService = districtServiceFactory();
const langService = languageServiceFactory();

const Districts: FC = () => {
    const { t } = useTranslation();
    const [districts, setDistricts] = useState<DistrictsCollection>(NullableCollection);

    const [selectedDistrict, setSelectedDistrict] = useState<District>(null);

    const [reloadPage, setReloadPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [currentLimit, setCurrentLimit] = useState(PaginationEnum.SHOW_20);
    const [messageObj, setMessageObj] = useState<ResponseMessageObj>(defaultResponseMessageObj);

    const getDistricts = async (values?: DistrictDTO) => {
        setLoading(true);
        const districtsResult = await districtService.getDistricts(
            currentPage,
            currentLimit,
            langService.currentLang,
            values
        );
        if (districtsResult) {
            setDistricts(districtsResult);
            resetSelectedDistrict();
            setReloadPage(false);
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.SUCCESS,
                messageKey: "messages.received",
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
        setLoading(false);
    };

    const [searchFiltersFields, setSearchFiltersFields] = useState<DistrictDTO>({} as DistrictDTO);
    const searchFiltersForm = useCustomFormik<DistrictDTO>({
        initial: null,
        fields: districtsSearchFiltersFields,
        schema: districtsSearchFiltersSchemaFactory(t),
        onSubmit: async (values) => {
            setSearchFiltersFields(values);
            setCurrentPage(DEFAULT_PAGE);
            await getDistricts(values);
        },
    });

    useEffect(() => {
        getDistricts(searchFiltersFields);
    }, [currentLimit, currentPage, reloadPage, langService.currentLang]);

    const createOrUpdateDistrict = async (values: DistrictDTO) => {
        const result = { status: false };
        if (selectedDistrict?.id) {
            selectedDistrict.assignData(values);
            result.status = !!(await districtService.updateDistrict(selectedDistrict))?.id;
        } else {
            result.status = !!(await districtService.createDistrict(values as District))?.id;
        }
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.SUCCESS,
                messageKey: `messages.${selectedDistrict?.id ? "updated" : "created"}`,
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const deleteDistrict = async (district: District) => {
        const result = await districtService.deleteDistrict(district?.id);
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "messages.deleted",
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const limitHandler = (value: SelectValue) => {
        setCurrentLimit(value as number);
    };

    const pageHandler = (value: number) => {
        setCurrentPage(value);
    };

    const resetSelectedDistrict = () => {
        setSelectedDistrict(null);
    };

    const resetMessageObj = () => {
        setMessageObj(defaultResponseMessageObj);
    };

    const resetFiltersHandler = () => {
        searchFiltersForm.form.resetForm();
        setSearchFiltersFields({} as DistrictDTO);
        setReloadPage(true);
    };

    return (
        <div className="relative z-0">
            <LoadingPanel isVisible={loading} />
            <DistrictsHeader
                selectedDistrict={selectedDistrict}
                searchFiltersForm={searchFiltersForm.form}
                filtersFields={searchFiltersFields}
                resetSelectedDistrict={resetSelectedDistrict}
                createOrUpdateDistrict={createOrUpdateDistrict}
                limitHandler={limitHandler}
                resetFiltersHandler={resetFiltersHandler}
            />
            <DistrictsTable
                districts={districts.rows}
                updateHandler={setSelectedDistrict}
                deleteHandler={deleteDistrict}
            />
            <Pagination currentPage={currentPage} totalPages={districts.totalPages} pageHandler={pageHandler} />
            <Toast messageObj={messageObj} resetMessageObj={resetMessageObj} />
        </div>
    );
};

export default Districts;
