import { FC, useEffect, useMemo, useState } from "react";
import ResidentsHeader from "./ResidentsHeader";
import ResidentsTable from "./ResidentsTable";
import { v4 as uuidv4 } from "uuid";
import { Resident, ResidentsCollection } from "../../../domain/models/Resident";
import { NullableCollection, ResponseMessageObj, defaultResponseMessageObj } from "../../../types/ApiResults";
import { DEFAULT_PAGE } from "../../../domain/constants";
import { PaginationEnum } from "../../../domain/enums/common/PaginationEnum";
import residentServiceFactory from "../../../services/ResidentServiceImpl";
import { ResponseMessage } from "../../../domain/enums/common/ResponseMessageEnum";
import { ResidentDTO } from "../../../domain/models/dto/ResidentDTO";
import { SelectProps, SelectValue } from "../../../types/SelectProps";
import LoadingPanel from "../../shared/loading-panel/LoadingPanel";
import Toast from "../../shared/Toast";
import Pagination from "../../shared/Pagination";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";
import {
    residentsSearchFiltersFields,
    residentsSearchFiltersSchemaFactory,
} from "../../../validation-schemas/ResidentsSearchFiltersSchema";
import { useTranslation } from "react-i18next";
import { DistrictsCollection } from "../../../domain/models/District";
import districtServiceFactory from "../../../services/DistrictServiceImpl";
import languageServiceFactory from "../../../services/LanguageServiceImpl";

const residentService = residentServiceFactory();
const districtService = districtServiceFactory();
const langService = languageServiceFactory();

const Residents: FC = () => {
    const { t } = useTranslation();
    const [residents, setResidents] = useState<ResidentsCollection>(NullableCollection);
    const [districts, setDistricts] = useState<DistrictsCollection>(NullableCollection);

    const [selectedResident, setSelectedResident] = useState<Resident>(null);

    const [reloadPage, setReloadPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [currentLimit, setCurrentLimit] = useState(PaginationEnum.SHOW_20);
    const [messageObj, setMessageObj] = useState<ResponseMessageObj>(defaultResponseMessageObj);

    const districtsSelectProps: SelectProps[] = useMemo(() => {
        return districts.rows.map((item) => {
            return {
                labelKey: langService.isUkrainian ? item.districtUk : item.districtEn,
                value: item.id,
            };
        });
    }, [districts]);

    const getResidents = async (values?: ResidentDTO) => {
        setLoading(true);
        const [residentsResult, districtsResult] = await Promise.all([
            residentService.getResidents(currentPage, currentLimit, values, langService.currentLang),
            districtService.getAllDistricts(),
        ]);
        if (residentsResult && districtsResult) {
            setResidents(residentsResult);
            setDistricts(districtsResult);
            resetSelectedResident();
            setReloadPage(false);
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.SUCCESS,
                messageKey: "messages.received",
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
        setLoading(false);
    };

    const [searchFiltersFields, setSearchFiltersFields] = useState<ResidentDTO>({} as ResidentDTO);
    const searchFiltersForm = useCustomFormik<ResidentDTO>({
        initial: null,
        fields: residentsSearchFiltersFields,
        schema: residentsSearchFiltersSchemaFactory(t),
        onSubmit: async (values) => {
            setSearchFiltersFields(values);
            setCurrentPage(DEFAULT_PAGE);
            await getResidents(values);
        },
    });

    useEffect(() => {
        getResidents(searchFiltersFields);
    }, [currentLimit, currentPage, reloadPage, langService.currentLang]);

    const createOrUpdateResident = async (values: ResidentDTO) => {
        const result = { status: false };
        if (selectedResident?.id) {
            selectedResident.assignData(values);
            result.status = !!(await residentService.updateResident(selectedResident))?.id;
        } else {
            result.status = !!(await residentService.createResident(values as Resident))?.id;
        }
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.SUCCESS,
                messageKey: `messages.${selectedResident?.id ? "updated" : "created"}`,
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const deleteResident = async (resident: Resident) => {
        const result = await residentService.deleteResident(resident?.id);
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "messages.deleted",
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const limitHandler = (value: SelectValue) => {
        setCurrentLimit(value as number);
    };

    const pageHandler = (value: number) => {
        setCurrentPage(value);
    };

    const resetSelectedResident = () => {
        setSelectedResident(null);
    };

    const resetMessageObj = () => {
        setMessageObj(defaultResponseMessageObj);
    };

    const resetFiltersHandler = () => {
        searchFiltersForm.form.resetForm();
        setSearchFiltersFields({} as ResidentDTO);
        setReloadPage(true);
    };

    return (
        <div className="relative z-0">
            <LoadingPanel isVisible={loading} />
            <ResidentsHeader
                selectedResident={selectedResident}
                searchFiltersForm={searchFiltersForm.form}
                districts={districtsSelectProps}
                filtersFields={searchFiltersFields}
                resetSelectedResident={resetSelectedResident}
                createOrUpdateResident={createOrUpdateResident}
                limitHandler={limitHandler}
                resetFiltersHandler={resetFiltersHandler}
            />
            <ResidentsTable
                residents={residents.rows}
                updateHandler={setSelectedResident}
                deleteHandler={deleteResident}
            />
            <Pagination currentPage={currentPage} totalPages={residents.totalPages} pageHandler={pageHandler} />
            <Toast messageObj={messageObj} resetMessageObj={resetMessageObj} />
        </div>
    );
};

export default Residents;
