import { FC, useState } from "react";
import URLIconSVG from "../svg/URLIconSVG";
import Toast from "../Toast";
import useToastMessage from "../../../custom-hooks/useToastMessage";
import { ResponseMessage } from "../../../domain/enums/common/ResponseMessageEnum";

const CopyURL: FC = () => {
    const [isRippled, setIsRippled] = useState(false);
    const { message, setMessage, resetMessage } = useToastMessage();

    const handleCopyUrl = async () => {
        const currentUrl = window.location.href;
        await navigator.clipboard.writeText(currentUrl);
        setMessage("messages.copied", ResponseMessage.SUCCESS);

        setIsRippled(true);
        setTimeout(() => setIsRippled(false), 500);
    };

    return (
        <div className="relative">
            <button
                onClick={handleCopyUrl}
                className="relative overflow-hidden flex items-center bg-gradient-to-r from-[#D49450] to-[#9C5C34] text-beige1 font-bold py-[6px] px-4 rounded-full"
            >
                <span
                    className={`absolute w-32 h-32 bg-white opacity-20 rounded-full transform scale-0 ${
                        isRippled ? "animate-ripple" : ""
                    }`}
                />
                <URLIconSVG className="fill-beige1 mr-2" />
                URL
            </button>
            <Toast
                messageObj={message}
                resetMessageObj={resetMessage}
                wrapClassName="!bg-beige4"
                successIconWrapClassName="!bg-beige4"
                dangerIconWrapClassName="!bg-beige4"
                warningIconWrapClassName="!bg-beige4"
                closeButtonClassName="!bg-beige4 !text-brown3"
                labelClassName="font-manrope text-brown3"
                staticClassName="bottom-[-10px] left-[125px] !w-[135px]"
                staticType
                singleMessageMode
            />
        </div>
    );
};

export default CopyURL;
