import { FC } from "react";
import LogoSVG from "../shared/svg/logos/LogoSVG";
import { useTranslation } from "react-i18next";
import LangSelect from "./LangSelect";
import { Link } from "react-router-dom";

const Header: FC = () => {
    const { t } = useTranslation();
    return (
        <header className="relative z-50 font-cormorant text-[22px] bg-beige1 py-[16px] h-[137px] flex items-center overflow-x-clip">
            <div className="absolute z-50 left-0 right-0 mx-auto max-w-[1531px] flex justify-between items-center">
                <nav className="flex text-brown1 font-medium">
                    <Link to="/about" className="mr-[30px] hover:underline hover:font-bold">
                        {t("navigation.aboutProject")}
                    </Link>
                    <Link to="/search" className="mr-[30px] hover:underline hover:font-bold">
                        {t("navigation.surnameMap")}
                    </Link>
                    <Link to="/recommendations" className="hover:underline hover:font-bold">
                        {t("navigation.recommendations")}
                    </Link>
                </nav>
                <LogoSVG className="absolute left-0 right-0 mx-auto w-fit" fill="#6A4F3E" />
                <nav className="flex text-brown1 font-medium items-center">
                    <Link to="/archives" className="mr-[30px] hover:underline hover:font-bold">
                        {t("navigation.archives")}
                    </Link>
                    <Link to="/dna-genealogy" className="mr-[30px] hover:underline hover:font-bold">
                        {t("navigation.dnaGenealogy")}
                    </Link>
                    <Link to="/contacts" className="mr-[30px] hover:underline hover:font-bold">
                        {t("navigation.contacts")}
                    </Link>
                    <LangSelect additionalPopupClassName="absolute right-[0px] top-[35px]" />
                </nav>
            </div>
            <div className="absolute left-0 right-0 mx-auto top-[136px] max-w-[1531px] h-[1px] bg-brown1"></div>
        </header>
    );
};

export default Header;
