import { FC, useState } from "react";
import CustomInput from "../../shared/form-inputs/CustomInput";
import { InputType } from "../../../types/InputType";
import { FormikProps } from "formik";
import BaseModal from "../../shared/modals/BaseModal";
import { useTranslation } from "react-i18next";
import FilterIconSVG from "../../shared/svg/admin-icons/FilterIconSVG";
import { englishSymbols, ukrainianSymbols } from "../../../domain/constants";
import { ArchiveDTO } from "../../../domain/models/dto/ArchiveDTO";
import { archiveFields } from "../../../validation-schemas/ArchiveSchema";
import CustomDropdown from "../../shared/form-inputs/CustomDropdown";
import { enumToSelectProps, enumValueToSelectProps } from "../../../tools/EnumTools";
import { ArchivesTypes } from "../../../domain/enums/ArchivesTypesEnum";

interface ArchivesSearchFiltersFormProps {
    form: FormikProps<ArchiveDTO>;
    filtersFields: ArchiveDTO;
}

const ArchivesSearchFiltersForm: FC<ArchivesSearchFiltersFormProps> = (props) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const confirmHandler = () => {
        setIsVisible(false);
        props.form.handleSubmit();
    };
    const closeHandler = () => {
        props.form.resetForm();
    };
    const onOpenHandler = () => {
        props.form.setValues(props.filtersFields);
    };
    return (
        <BaseModal
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            title={t("admin.common.filters")}
            confirmTitle={t("admin.common.apply")}
            cancelTitle={t("admin.common.cancel")}
            buttonElement={
                <button
                    data-modal-target="static-modal"
                    data-modal-toggle="static-modal"
                    className="flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center max-h-10 ml-4"
                    type="button"
                    title={t("admin.common.filters")}
                    onClick={() => setIsVisible(true)}
                >
                    <FilterIconSVG className="w-[20px] fill-white" />
                </button>
            }
            confirmHandler={confirmHandler}
            closeHandler={closeHandler}
            onOpen={onOpenHandler}
            additionalWrapClassName="w-[450px]"
        >
            <CustomInput
                type={archiveFields.nameUk.type as InputType}
                value={props.form.values.nameUk}
                name="nameUk"
                placeholderKey={archiveFields.nameUk.placeholderKey}
                errorKey={props.form.errors.nameUk as string}
                onChange={props.form.handleChange}
                pattern={ukrainianSymbols}
            />
            <CustomInput
                type={archiveFields.nameEn.type as InputType}
                value={props.form.values.nameEn}
                name="nameEn"
                placeholderKey={archiveFields.nameEn.placeholderKey}
                errorKey={props.form.errors.nameEn as string}
                onChange={props.form.handleChange}
                pattern={englishSymbols}
            />
            <CustomDropdown
                labelKey={archiveFields.type.placeholderKey}
                errorKey={props.form.errors.type}
                defaultValue={enumValueToSelectProps(props.form.values.type, `archivesPage.type`)}
                data={enumToSelectProps(ArchivesTypes, `archivesPage.type`)}
                dropDownCallback={(value) => props.form.setFieldValue("type", value)}
                dropdownClassName="z-[100] w-[188px]"
                wrapperClassName="mb-6"
            />
            <CustomInput
                type={archiveFields.addressUk.type as InputType}
                value={props.form.values.addressUk}
                name="addressUk"
                placeholderKey={archiveFields.addressUk.placeholderKey}
                errorKey={props.form.errors.addressUk as string}
                onChange={props.form.handleChange}
                pattern={ukrainianSymbols}
            />
            <CustomInput
                type={archiveFields.addressEn.type as InputType}
                value={props.form.values.addressEn}
                name="addressEn"
                placeholderKey={archiveFields.addressEn.placeholderKey}
                errorKey={props.form.errors.addressEn as string}
                onChange={props.form.handleChange}
                pattern={englishSymbols}
            />
        </BaseModal>
    );
};

export default ArchivesSearchFiltersForm;
