import _ from "lodash";
import { Mapper } from "../domain/mappers/Mapper";
import { Archive } from "../domain/models/Archive";
import { ArchiveDTO } from "../domain/models/dto/ArchiveDTO";
import ArchiveModel from "../models/ArchiveModel";
import { SimpleMapper } from "./SimpleMapper";
import { getFormatedPhone } from "../tools/FormatingTools";

class ArchiveMapperImpl extends SimpleMapper<Archive, ArchiveDTO> {
    protected entityConstructor = ArchiveModel;

    protected toDTOFields: string[] = [
        "nameUk",
        "nameEn",
        "addressUk",
        "addressEn",
        "phone",
        "email",
        "site",
        "type",
    ];

    protected fromDTOFields: string[] = [
        "id",
        "createdAt",
        "updatedAt",
        ...this.toDTOFields,
    ];

    toDTO(model: Archive): ArchiveDTO {
        const dto = super.toDTO(model);
        dto.phone = _.get(model, "phone", [])
            .filter((item) => item)
            .map(getFormatedPhone);
        dto.email = _.get(model, "email", []).filter((item) => item);
        return dto;
    }
}

export default function archiveMapperFactory(): Mapper<Archive, ArchiveDTO> {
    return new ArchiveMapperImpl();
}
