import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";

const langService: LanguageService = languageServiceFactory();

const ResearchBenefits: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full bg-beige1 shadow-beige4Section flex justify-center overflow-x-clip">
            <div className="relative max-w-[1840px] w-full pl-[198px] h-[1141px] text-center">
                <h2
                    className={`relative text-left font-cormorant font-light ${
                        langService.isUkrainian ? "text-[67px]" : "text-[82px]"
                    } text-brown3 leading-[81px] pt-[118px] z-10`}
                >
                    {t("DNAGenealogyPage.researchBenefits.text1")}{" "}
                    <span className="font-bold">{t("DNAGenealogyPage.researchBenefits.text2")} </span>
                    {t("DNAGenealogyPage.researchBenefits.text3")}
                </h2>
                <div className="flex mt-[98px]">
                    <div className="text-left w-[600px] mr-[215px]">
                        <p className="font-manrope text-[22px] leading-[32px] text-brown3">
                            {t("DNAGenealogyPage.researchBenefits.text4")}
                        </p>
                        <p className="font-manrope text-[22px] leading-[32px] text-brown3 mt-[51px]">
                            {t("DNAGenealogyPage.researchBenefits.text5")}
                        </p>
                        <p className="font-manrope font-bold text-[22px] leading-[32px] text-brown3 mt-[50px]">
                            {t("DNAGenealogyPage.researchBenefits.text6")}
                        </p>
                        <p className="font-manrope text-[22px] leading-[32px] text-brown3">
                            {t("DNAGenealogyPage.researchBenefits.text7")}{" "}
                        </p>
                    </div>
                    <div className="relative left-[40px]">
                        <div className="absolute left-[305px] top-[-55px] w-[385px] h-[485px] transform rotate-[10.5deg] bg-gradient-to-b from-[#FAECC4] to-[#E9DCB2] p-[20px] shadow-familyPhoto">
                            <img
                                className="max-w-none w-full"
                                src="/img/dna-genealogy-page/researchBenefits1.png"
                                alt="Top"
                            />
                        </div>
                        <div className="absolute left-[-140px] top-[50px] w-[400px] h-[515px] transform rotate-[-8.7deg] bg-gradient-to-b from-[#FAECC4] to-[#E9DCB2] p-[20px] shadow-familyPhoto">
                            <img
                                className="max-w-none w-full"
                                src="/img/dna-genealogy-page/researchBenefits2.png"
                                alt="Middle"
                            />
                        </div>
                        <div className="absolute left-[130px] top-[215px] w-[450px] h-[625px] transform rotate-[4.8deg] bg-gradient-to-b from-[#FAECC4] to-[#E9DCB2] p-[20px] shadow-familyPhoto">
                            <img
                                className="max-w-none w-full"
                                src="/img/dna-genealogy-page/researchBenefits3.png"
                                alt="Bottom"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResearchBenefits;
