import { FC } from "react";
import { FormikProps } from "formik";
import { SelectValue } from "../../../types/SelectProps";
import CustomDropdown from "../../shared/form-inputs/CustomDropdown";
import { PaginationEnum } from "../../../domain/enums/common/PaginationEnum";
import { enumToSelectProps, enumValueToSelectProps } from "../../../tools/EnumTools";
import { useTranslation } from "react-i18next";
import FilterResetIconSVG from "../../shared/svg/admin-icons/FilterResetIconSVG";
import { DistrictDTO } from "../../../domain/models/dto/DistrictDTO";
import DistrictCreationModalForm from "./DistrictCreationModalForm";
import DistrictsSearchFiltersForm from "./DistrictsSearchFiltersForm";
import { District } from "../../../domain/models/District";

interface DistrictsHeaderProps {
    selectedDistrict: District;
    searchFiltersForm: FormikProps<DistrictDTO>;
    filtersFields: DistrictDTO;
    resetSelectedDistrict: () => void;
    createOrUpdateDistrict: (dto: DistrictDTO) => Promise<void>;
    limitHandler: (value: SelectValue) => void;
    resetFiltersHandler: () => void;
}

const DistrictsHeader: FC<DistrictsHeaderProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="bg-gray-100 w-[95%] mt-[10px] mr-auto mb-[10px] py-2 px-3 ml-auto flex items-center shadow-md rounded-xl">
            <div className="pr-4 border-r-[1px] border-gray-300">
                <DistrictCreationModalForm
                    selectedDistrict={props.selectedDistrict}
                    resetSelectedDistrict={props.resetSelectedDistrict}
                    createOrUpdateDistrict={props.createOrUpdateDistrict}
                />
            </div>
            <div className="flex items-center pr-4 border-r-[1px] border-gray-300">
                <DistrictsSearchFiltersForm form={props.searchFiltersForm} filtersFields={props.filtersFields} />
                <button
                    className="flex items-center text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center h-10 ml-2"
                    onClick={props.resetFiltersHandler}
                    title={t("admin.common.filtersReset")}
                >
                    <FilterResetIconSVG className="w-[20px] fill-white" />
                </button>
            </div>
            <div className="flex items-center pr-4">
                <h3 className="mr-2 ml-4 text-[16px] font-semibold text-gray-500">{t("pagination.show")}</h3>
                <CustomDropdown
                    defaultValue={enumValueToSelectProps(PaginationEnum.SHOW_20, "pagination")}
                    data={enumToSelectProps(PaginationEnum, "pagination")}
                    dropDownCallback={props.limitHandler}
                    dropdownClassName="w-[80px]"
                />
            </div>
        </div>
    );
};

export default DistrictsHeader;
