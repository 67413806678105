import _ from "lodash";
import { District } from "../domain/models/District";
import { Resident } from "../domain/models/Resident";
import { ResidentDTO } from "../domain/models/dto/ResidentDTO";
import { BaseModelImpl } from "./BaseModelImpl";

export default class ResidentModel
    extends BaseModelImpl<ResidentDTO>
    implements Resident
{
    constructor(data?: ResidentDTO) {
        super();
        this.assignData(data);
    }

    id?: string;

    firstNameUk: string;
    firstNameEn: string;

    lastNameUk: string;
    lastNameEn: string;

    regionUk: string;
    regionEn: string;

    districtId: string;

    localityUk: string;
    localityEn: string;

    createdAt?: string;
    updatedAt?: string;

    district: District;

    get districtName() {
        if (!this.district) {
            return "";
        }
        return (
            _.get(this, "district.districtUk", "") +
            "/" +
            _.get(this, "district.districtEn", "")
        );
    }

    get firstName() {
        if (!this.firstNameUk && !this.firstNameEn) {
            return "";
        }
        return (
            _.get(this, "firstNameUk", "") +
            "/" +
            _.get(this, "firstNameEn", "")
        );
    }

    get lastName() {
        if (!this.lastNameUk && !this.lastNameEn) {
            return "";
        }
        return (
            _.get(this, "lastNameUk", "") + "/" + _.get(this, "lastNameEn", "")
        );
    }

    get region() {
        if (!this.regionUk && !this.regionEn) {
            return "";
        }
        return _.get(this, "regionUk", "") + "/" + _.get(this, "regionEn", "");
    }

    get locality() {
        if (!this.localityUk && !this.localityEn) {
            return "";
        }
        return (
            _.get(this, "localityUk", "") + "/" + _.get(this, "localityEn", "")
        );
    }
}
