import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Residents from "../components/admin/residents-page/Residents";
import MainAdminComponent from "../components/admin/MainAdminComponent";
import Archives from "../components/admin/archives-page/Archives";
import Districts from "../components/admin/districts-page/Districts";
import Settings from "../components/admin/settings-page/Settings";

const AdminRoutes: FC = () => {
    return (
        <Routes>
            <Route path="/" element={<MainAdminComponent />}>
                <Route path="/" element={<Navigate to="/admin/residents" />} />
                <Route path="/residents" element={<Residents />} />
                <Route path="/archives" element={<Archives />} />
                <Route path="/districts" element={<Districts />} />
                <Route path="/settings" element={<Settings />} />
            </Route>
        </Routes>
    );
};

export default AdminRoutes;
