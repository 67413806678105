import { Langs } from "../domain/enums/common/LangsEnum";
import {
    Resident,
    ResidentsCollection,
    SearchResidentsForm,
} from "../domain/models/Resident";
import {
    ResidentAutocompleteDTO,
    ResidentDTO,
    SearchResidentsDTO,
} from "../domain/models/dto/ResidentDTO";
import { ResidentService } from "../domain/services/ResidentService";
import residentMapperFactory from "../mappers/ResidentMapper";
import { RemoveResponse } from "../types/ApiResults";
import { BaseServiceImpl } from "./BaseServiceImpl";
import districtServiceFactory from "./DistrictServiceImpl";

class ResidentServiceImpl extends BaseServiceImpl implements ResidentService {
    async getResidents(
        page: number,
        limit: number,
        dto: ResidentDTO = {} as ResidentDTO,
        lang: Langs
    ): Promise<ResidentsCollection> {
        try {
            const mapper = residentMapperFactory();
            const districtService = districtServiceFactory();
            let url = `/api/v1/residents/?page=${page}&limit=${limit}&lang=${lang}`;
            Object.entries(dto).map(([key, value]) => {
                if (key && value) {
                    url += `&${key}=${value}`;
                }
            });
            const [result, districts] = await Promise.all([
                this.getCollection<ResidentDTO>(url, []),
                districtService.getAllDistricts(),
            ]);
            return {
                ...result,
                rows: result.rows.map((item) =>
                    mapper.fromDTOExtended(item, districts.rows)
                ),
            };
        } catch (err) {
            console.log("Error in ResidentServiceImpl.getResidents");
            console.log(err);
        }
    }

    async createResident(resident: Resident): Promise<Resident> {
        try {
            const mapper = residentMapperFactory();
            return this.post("/api/v1/residents", mapper.toDTO(resident));
        } catch (err) {
            console.log("Error in ResidentServiceImpl.createResident");
            console.log(err);
        }
    }

    async updateResident(resident: Resident): Promise<Resident> {
        try {
            if (!resident?.id) {
                return null;
            }
            const mapper = residentMapperFactory();
            return this.patch(
                `/api/v1/residents/${resident.id}`,
                mapper.toDTO(resident)
            );
        } catch (err) {
            console.log("Error in ResidentServiceImpl.updateResident");
            console.log(err);
        }
    }

    async deleteResident(residentId: string): Promise<RemoveResponse> {
        try {
            if (!residentId) {
                return { status: false };
            }
            return this.delete(`/api/v1/residents/${residentId}`);
        } catch (err) {
            console.log("Error in ResidentServiceImpl.deleteResident");
            console.log(err);
        }
    }

    async autocompleteNames(
        page: number,
        limit: number,
        lastName: string,
        lang: Langs
    ): Promise<ResidentAutocompleteDTO[]> {
        try {
            const url = `/api/v1/residents/autocomplete/?page=${page}&limit=${limit}&lastName=${lastName}&lang=${lang}`;
            return this.get<ResidentAutocompleteDTO[]>(url, []);
        } catch (err) {
            console.log("Error in ResidentServiceImpl.autocompleteNames");
            console.log(err);
        }
    }

    async searchResidents(
        form: SearchResidentsForm,
        lang: Langs
    ): Promise<SearchResidentsDTO[]> {
        try {
            if (!form?.lastNameFathers && !form?.lastNameMothers) {
                return [];
            }
            let url = "/api/v1/residents/search/?";
            const params = new URLSearchParams();
            if (form?.lastNameFathers) {
                params.append("lastNameFathers", form.lastNameFathers);
            }
            if (form?.lastNameMothers) {
                params.append("lastNameMothers", form.lastNameMothers);
            }
            if (lang) {
                params.append("lang", lang);
            }
            url += params.toString();
            return this.get<SearchResidentsDTO[]>(url, []);
        } catch (err) {
            console.log("Error in ResidentServiceImpl.searchResidents");
            console.log(err);
        }
    }

    getMockSearchResidents(): SearchResidentsDTO[] {
        return Array.from({ length: 14 }, (_v, _i) => {
            return {
                regionUk: "",
                regionEn: "",
                districtId: "",
                localityUk: "",
                localityEn: "",
                fathersCount: null,
                mothersCount: null,
            };
        });
    }
}

export default function residentServiceFactory(): ResidentService {
    return new ResidentServiceImpl();
}
