import { FC, useState } from "react";
import BaseModal from "../../shared/modals/BaseModal";
import { useTranslation } from "react-i18next";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";
import CustomInput from "../../shared/form-inputs/CustomInput";
import { InputType } from "../../../types/InputType";
import PlusIconSVG from "../../shared/svg/admin-icons/PlusIconSVG";
import { get } from "lodash";
import { defaultInputsAmount, englishSymbols, ukrainianSymbols } from "../../../domain/constants";
import { ArchiveDTO } from "../../../domain/models/dto/ArchiveDTO";
import { archiveFields, archiveSchemaFactory } from "../../../validation-schemas/ArchiveSchema";
import CustomInputList from "../../shared/form-inputs/CustomInputList";
import CustomDropdown from "../../shared/form-inputs/CustomDropdown";
import { enumToSelectProps, enumValueToSelectProps } from "../../../tools/EnumTools";
import { ArchivesTypes } from "../../../domain/enums/ArchivesTypesEnum";
import PhoneInputComponent from "../../shared/form-inputs/PhoneInput";
import transliterationServiceFactory from "../../../services/TransliterationServiceImpl";

interface ArchiveCreationModalFormProps {
    selectedArchive: ArchiveDTO;
    resetSelectedArchive: () => void;
    createOrUpdateArchive: (dto: ArchiveDTO) => Promise<void>;
}

const transliterationService = transliterationServiceFactory();

const ArchiveCreationModalForm: FC<ArchiveCreationModalFormProps> = (props) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    const { form } = useCustomFormik<ArchiveDTO>({
        initial: props.selectedArchive,
        fields: archiveFields,
        schema: archiveSchemaFactory(t),
        onSubmit: async (values, handlers) => {
            await props.createOrUpdateArchive(values);
            setIsVisible(false);
            handlers.resetForm();
        },
    });

    const closeHandler = () => {
        props.resetSelectedArchive();
        form.resetForm();
    };

    const onInputBlur = (source: keyof ArchiveDTO, target: keyof ArchiveDTO) => {
        return () => {
            const value = get(form, "values." + source);
            if (!value) return;
            form.setFieldValue(target, transliterationService.transliterate(value));
        };
    };

    return (
        <BaseModal
            isVisible={!!props.selectedArchive?.id || isVisible}
            setIsVisible={setIsVisible}
            title={t(`admin.common.${props.selectedArchive?.id ? "update" : "create"}`)}
            confirmTitle={t(`admin.common.apply`)}
            cancelTitle={t(`admin.common.cancel`)}
            buttonElement={
                <button
                    data-modal-target="static-modal"
                    data-modal-toggle="static-modal"
                    className={`flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center max-h-10`}
                    type="button"
                    title={t(`admin.common.${props.selectedArchive?.id ? "update" : "create"}`)}
                    onClick={() => setIsVisible(true)}
                >
                    <PlusIconSVG className="w-[25px] fill-white" />
                </button>
            }
            confirmHandler={form.handleSubmit}
            closeHandler={closeHandler}
            additionalWrapClassName="relative w-[750px]"
        >
            <div className="flex justify-between">
                <CustomInput
                    type={archiveFields.nameUk.type as InputType}
                    labelKey={archiveFields.nameUk.placeholderKey}
                    value={form.values.nameUk}
                    name="nameUk"
                    placeholderKey={archiveFields.nameUk.placeholderKey}
                    errorKey={form.errors.nameUk as string}
                    onChange={form.handleChange}
                    onBlur={onInputBlur("nameUk", "nameEn")}
                    pattern={ukrainianSymbols}
                    className="w-[325px]"
                />
                <CustomInput
                    type={archiveFields.nameEn.type as InputType}
                    labelKey={archiveFields.nameEn.placeholderKey}
                    value={form.values.nameEn}
                    name="nameEn"
                    placeholderKey={archiveFields.nameEn.placeholderKey}
                    errorKey={form.errors.nameEn as string}
                    onChange={form.handleChange}
                    pattern={englishSymbols}
                    className="w-[325px]"
                />
            </div>
            <div className="flex justify-between mb-[10px]">
                <CustomInput
                    type={archiveFields.addressUk.type as InputType}
                    labelKey={archiveFields.addressUk.placeholderKey}
                    value={form.values.addressUk}
                    name="addressUk"
                    placeholderKey={archiveFields.addressUk.placeholderKey}
                    errorKey={form.errors.addressUk as string}
                    onChange={form.handleChange}
                    onBlur={onInputBlur("addressUk", "addressEn")}
                    pattern={ukrainianSymbols}
                    className="w-[325px]"
                />
                <CustomInput
                    type={archiveFields.addressEn.type as InputType}
                    labelKey={archiveFields.addressEn.placeholderKey}
                    value={form.values.addressEn}
                    name="addressEn"
                    placeholderKey={archiveFields.addressEn.placeholderKey}
                    errorKey={form.errors.addressEn as string}
                    onChange={form.handleChange}
                    pattern={englishSymbols}
                    className="w-[325px]"
                />
            </div>
            <CustomDropdown
                labelKey={archiveFields.type.placeholderKey}
                defaultValue={enumValueToSelectProps(form.values.type, `archivesPage.type`)}
                errorKey={form.errors.type}
                data={enumToSelectProps(ArchivesTypes, `archivesPage.type`)}
                dropDownCallback={(value) => form.setFieldValue("type", value)}
                wrapperClassName="block mb-8"
                buttonClassName="w-[325px]"
                dropdownClassName="w-[325px]"
            />
            <CustomInputList
                addButtonLabel={t("admin.archivesPage.creationModal.addPhone")}
                maxLength={defaultInputsAmount}
                form={form}
                field="phone"
                meta={archiveFields.phone}
                inputComponentFactory={(item: string, errorKey: string, onChange: (value: string) => void) => (
                    <PhoneInputComponent
                        meta={archiveFields.phone}
                        field="phone"
                        value={item}
                        errorKey={errorKey}
                        onChange={onChange}
                    />
                )}
            />
            <CustomInputList
                addButtonLabel={t("admin.archivesPage.creationModal.addEmail")}
                maxLength={defaultInputsAmount}
                form={form}
                field="email"
                meta={archiveFields.email}
            />
            <CustomInput
                type={archiveFields.site.type as InputType}
                labelKey={archiveFields.site.placeholderKey}
                value={form.values.site}
                name="site"
                placeholderKey={archiveFields.site.placeholderKey}
                errorKey={form.errors.site as string}
                onChange={form.handleChange}
                pattern={englishSymbols}
                className="w-[325px]"
            />
        </BaseModal>
    );
};

export default ArchiveCreationModalForm;
