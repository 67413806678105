import { FC, useEffect, useState } from "react";
import { ResponseMessage } from "../../domain/enums/common/ResponseMessageEnum";
import { RESPONSE_MESSAGE_VISIBILITY_MS } from "../../domain/constants";
import { useTranslation } from "react-i18next";
import { ResponseMessageObj } from "../../types/ApiResults";

interface ToastProps {
    messageObj: ResponseMessageObj;
    resetMessageObj: () => void;
    staticType?: boolean;
    staticClassName?: string;
    singleMessageMode?: boolean;
    wrapClassName?: string;
    successIconWrapClassName?: string;
    dangerIconWrapClassName?: string;
    warningIconWrapClassName?: string;
    closeButtonClassName?: string;
    labelClassName?: string;
}

const Toast: FC<ToastProps> = (props) => {
    const { t } = useTranslation();
    const [messageQueue, setMessageQueue] = useState<ResponseMessageObj[]>([]);

    useEffect(() => {
        if (props.messageObj.id) {
            setMessageQueue((prevQueue) =>
                props.singleMessageMode ? [props.messageObj] : [props.messageObj, ...prevQueue]
            );
            setTimeout(() => {
                handleRemoveMessage(props.messageObj);
            }, RESPONSE_MESSAGE_VISIBILITY_MS);
        }
    }, [props.messageObj.id]);

    const handleRemoveMessage = (msgObj: ResponseMessageObj) => {
        setMessageQueue((prevQueue) => prevQueue.filter((msg) => msg.id !== msgObj.id));
        props.resetMessageObj();
    };

    const renderIcon = (type: ResponseMessage) => {
        switch (type) {
            case ResponseMessage.SUCCESS:
                return (
                    <div
                        className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg ${props.successIconWrapClassName}`}
                    >
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        <span className="sr-only">Check icon</span>
                    </div>
                );
            case ResponseMessage.DANGER:
                return (
                    <div
                        className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg ${props.dangerIconWrapClassName}`}
                    >
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                        </svg>
                        <span className="sr-only">Error icon</span>
                    </div>
                );
            case ResponseMessage.WARNING:
                return (
                    <div
                        className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg ${props.warningIconWrapClassName}`}
                    >
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
                        </svg>
                        <span className="sr-only">Warning icon</span>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {props.staticType ? (
                <div className={`absolute space-y-4 z-50 ${props.staticClassName}`}>
                    {messageQueue.map((msgObj, index) => (
                        <div
                            key={index}
                            id={`toast-${msgObj.messageType.toLowerCase()}`}
                            className={`flex flex-row items-center w-full max-w-xs p-2 mb-4 text-gray-500 bg-white rounded-lg shadow ${props.wrapClassName}`}
                            role="alert"
                        >
                            <div className={`text-sm font-normal ${props.labelClassName}`}>{t(msgObj.messageKey)}</div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="fixed bottom-8 right-8 space-y-4 z-50">
                    {messageQueue.map((msgObj, index) => (
                        <div
                            key={index}
                            id={`toast-${msgObj.messageType.toLowerCase()}`}
                            className={`flex flex-row items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow ${props.wrapClassName}`}
                            role="alert"
                        >
                            {renderIcon(msgObj.messageType)}
                            <div className={`ms-3 text-sm font-normal ${props.labelClassName}`}>
                                {t(msgObj.messageKey)}
                            </div>
                            <button
                                type="button"
                                className={`ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 ${props.closeButtonClassName}`}
                                onClick={() => handleRemoveMessage(msgObj)}
                                aria-label="Close"
                            >
                                <span className="sr-only">Close</span>
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Toast;
