import _ from "lodash";
import { BaseModelImpl } from "./BaseModelImpl";
import { ArchiveDTO } from "../domain/models/dto/ArchiveDTO";
import { Archive } from "../domain/models/Archive";
import { ArchivesTypes } from "../domain/enums/ArchivesTypesEnum";

export default class ArchiveModel
    extends BaseModelImpl<ArchiveDTO>
    implements Archive
{
    constructor(data?: ArchiveDTO) {
        super();
        this.assignData(data);
    }

    id?: string;

    nameUk: string;
    nameEn: string;

    addressUk: string;
    addressEn: string;

    phone?: string[];
    email?: string[];
    site?: string;

    type: ArchivesTypes;

    createdAt?: string;
    updatedAt?: string;

    get name() {
        if (!this.nameUk && !this.nameEn) {
            return "";
        }
        return _.get(this, "nameUk", "") + "/" + _.get(this, "nameEn", "");
    }

    get address() {
        if (!this.addressUk && !this.addressEn) {
            return "";
        }
        return (
            _.get(this, "addressUk", "") + "/" + _.get(this, "addressEn", "")
        );
    }

    get phoneStr() {
        if (!this.phone) {
            return "";
        }
        return _.get(this, "phone", []).join(", ");
    }

    get emailStr() {
        if (!this.email) {
            return "";
        }
        return _.get(this, "email", []).join(", ");
    }
}
