import { Fields } from "../types/SchemaFactory";
import { TFunction } from "i18next";
import { schemaFactory } from "./SchemaFactory";
import { ResidentDTO } from "../domain/models/dto/ResidentDTO";
import { residentFields } from "./ResidentSchema";

export const residentsSearchFiltersFields: Fields<ResidentDTO> = {
    ...residentFields,
    firstNameUk: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.firstNameUk`,
        type: "text",
        initial: "",
        optional: true,
    },
    firstNameEn: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.firstNameEn`,
        type: "text",
        initial: "",
        optional: true,
    },
    lastNameUk: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.lastNameUk`,
        type: "text",
        initial: "",
        optional: true,
    },
    lastNameEn: {
        min: 1,
        max: 128,
        placeholderKey: `admin.residentsPage.tableColumns.lastNameEn`,
        type: "text",
        initial: "",
        optional: true,
    },
};

export const residentsSearchFiltersSchemaFactory = (t: TFunction) => {
    return schemaFactory(t, residentsSearchFiltersFields);
};
