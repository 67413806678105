import { FC } from "react";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import { Langs } from "../../domain/enums/common/LangsEnum";
import UkLangIconSVG from "./svg/language-icons/UkLangIconSVG";
import UaLangIconSVG from "./svg/language-icons/UaLangIconSVG";

interface CurrentLangIconProps {
    lang?: string;
    className?: string;
}

const langService: LanguageService = languageServiceFactory();

const CurrentLangIcon: FC<CurrentLangIconProps> = (props) => {
    switch (props.lang ? props.lang : langService.currentLang) {
        case Langs.EN:
            return <UkLangIconSVG className={props.className} />;
        case Langs.UA:
            return <UaLangIconSVG className={props.className} />;
        default:
            return <UaLangIconSVG className={props.className} />;
    }
};
export default CurrentLangIcon;
