import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { NullableCollection, ResponseMessageObj, defaultResponseMessageObj } from "../../../types/ApiResults";
import { DEFAULT_PAGE } from "../../../domain/constants";
import { PaginationEnum } from "../../../domain/enums/common/PaginationEnum";
import { ResponseMessage } from "../../../domain/enums/common/ResponseMessageEnum";
import { SelectValue } from "../../../types/SelectProps";
import LoadingPanel from "../../shared/loading-panel/LoadingPanel";
import Toast from "../../shared/Toast";
import Pagination from "../../shared/Pagination";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";
import { useTranslation } from "react-i18next";
import languageServiceFactory from "../../../services/LanguageServiceImpl";
import { Archive, ArchivesCollection } from "../../../domain/models/Archive";
import { ArchiveDTO } from "../../../domain/models/dto/ArchiveDTO";
import archiveServiceFactory from "../../../services/ArchiveServiceImpl";
import ArchivesHeader from "./ArchivesHeader";
import {
    archivesSearchFiltersFields,
    archivesSearchFiltersSchemaFactory,
} from "../../../validation-schemas/ArchivesSearchFiltersSchema";
import ArchivesTable from "./ArchivesTable";

const archiveService = archiveServiceFactory();
const langService = languageServiceFactory();

const Archives: FC = () => {
    const { t } = useTranslation();
    const [archives, setArchives] = useState<ArchivesCollection>(NullableCollection);

    const [selectedArchive, setSelectedArchive] = useState<Archive>(null);

    const [reloadPage, setReloadPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [currentLimit, setCurrentLimit] = useState(PaginationEnum.SHOW_20);
    const [messageObj, setMessageObj] = useState<ResponseMessageObj>(defaultResponseMessageObj);

    const getArchives = async (values?: ArchiveDTO) => {
        setLoading(true);
        const archivesResult = await archiveService.getArchives(
            currentPage,
            currentLimit,
            langService.currentLang,
            values
        );
        if (archivesResult) {
            setArchives(archivesResult);
            resetSelectedArchive();
            setReloadPage(false);
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.SUCCESS,
                messageKey: "messages.received",
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
        setLoading(false);
    };

    const [searchFiltersFields, setSearchFiltersFields] = useState<ArchiveDTO>({} as ArchiveDTO);
    const searchFiltersForm = useCustomFormik<ArchiveDTO>({
        initial: null,
        fields: archivesSearchFiltersFields,
        schema: archivesSearchFiltersSchemaFactory(t),
        onSubmit: async (values) => {
            setSearchFiltersFields(values);
            setCurrentPage(DEFAULT_PAGE);
            await getArchives(values);
        },
    });

    useEffect(() => {
        getArchives(searchFiltersFields);
    }, [currentLimit, currentPage, reloadPage, langService.currentLang]);

    const createOrUpdateArchive = async (values: ArchiveDTO) => {
        const result = { status: false };
        if (selectedArchive?.id) {
            selectedArchive.assignData(values);
            result.status = !!(await archiveService.updateArchive(selectedArchive))?.id;
        } else {
            result.status = !!(await archiveService.createArchive(values as Archive))?.id;
        }
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.SUCCESS,
                messageKey: `messages.${selectedArchive?.id ? "updated" : "created"}`,
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const deleteArchive = async (archive: Archive) => {
        const result = await archiveService.deleteArchive(archive?.id);
        setReloadPage(result.status);
        if (result.status) {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "messages.deleted",
            });
        } else {
            setMessageObj({
                id: uuidv4(),
                messageType: ResponseMessage.DANGER,
                messageKey: "errors.common",
            });
        }
    };

    const limitHandler = (value: SelectValue) => {
        setCurrentLimit(value as number);
    };

    const pageHandler = (value: number) => {
        setCurrentPage(value);
    };

    const resetSelectedArchive = () => {
        setSelectedArchive(null);
    };

    const resetMessageObj = () => {
        setMessageObj(defaultResponseMessageObj);
    };

    const resetFiltersHandler = () => {
        searchFiltersForm.form.resetForm();
        setSearchFiltersFields({} as ArchiveDTO);
        setReloadPage(true);
    };

    return (
        <div className="relative z-0">
            <LoadingPanel isVisible={loading} />
            <ArchivesHeader
                selectedArchive={selectedArchive}
                searchFiltersForm={searchFiltersForm.form}
                filtersFields={searchFiltersFields}
                resetSelectedArchive={resetSelectedArchive}
                createOrUpdateArchive={createOrUpdateArchive}
                limitHandler={limitHandler}
                resetFiltersHandler={resetFiltersHandler}
            />
            <ArchivesTable archives={archives.rows} updateHandler={setSelectedArchive} deleteHandler={deleteArchive} />
            <Pagination currentPage={currentPage} totalPages={archives.totalPages} pageHandler={pageHandler} />
            <Toast messageObj={messageObj} resetMessageObj={resetMessageObj} />
        </div>
    );
};

export default Archives;
