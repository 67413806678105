import { FC } from "react";
import { useTranslation } from "react-i18next";

const ResearchPreparationTips: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="relative w-full bg-beige1 flex justify-center h-[512px] overflow-x-clip">
            <div className="absolute left-0 right-0 mx-auto max-w-[1910px] px-[194px] flex">
                <div className="font-manrope text-[22px] text-brown3 w-[664px] mr-[85px] mt-[110px]">
                    <p>{t("searchRecommendationsPage.researchPreparationTips.text1")}</p>
                    <p className="mt-[33px]">
                        {t("searchRecommendationsPage.researchPreparationTips.text2")}{" "}
                        <span className="font-bold">
                            {t("searchRecommendationsPage.researchPreparationTips.text3")}
                        </span>{" "}
                        {t("searchRecommendationsPage.researchPreparationTips.text4")}
                    </p>
                </div>
                <div className="font-manrope text-[22px] leading-[32px] text-brown3 w-[650px] mt-[110px]">
                    <p>
                        {t("searchRecommendationsPage.researchPreparationTips.text5")}{" "}
                        <span className="font-bold">
                            {t("searchRecommendationsPage.researchPreparationTips.text6")}
                        </span>{" "}
                        {t("searchRecommendationsPage.researchPreparationTips.text7")}{" "}
                        <span className="font-bold">
                            {t("searchRecommendationsPage.researchPreparationTips.text8")}
                        </span>{" "}
                        {t("searchRecommendationsPage.researchPreparationTips.text9")}
                    </p>
                </div>
                <img
                    className="absolute right-0"
                    src="/img/recommendations-page/researchPreparationTips.png"
                    alt="Interview Instructions"
                />
            </div>
        </div>
    );
};

export default ResearchPreparationTips;
