import { Mapper } from "../domain/mappers/Mapper";
import { District } from "../domain/models/District";
import { DistrictDTO } from "../domain/models/dto/DistrictDTO";
import DistrictModel from "../models/DistrictModel";
import { SimpleMapper } from "./SimpleMapper";

class DistrictMapperImpl extends SimpleMapper<District, DistrictDTO> {
    protected entityConstructor = DistrictModel;

    protected toDTOFields: string[] = ["id", "districtUk", "districtEn", "geo"];

    protected fromDTOFields: string[] = [
        ...this.toDTOFields,
        "createdAt",
        "updatedAt",
    ];
}

export default function districtMapperFactory(): Mapper<District, DistrictDTO> {
    return new DistrictMapperImpl();
}
