import { StatusCodes } from "http-status-codes";
import { ErrorService } from "../domain/services/ErrorService";
import { BehaviorSubject, Subscription } from "rxjs";
import { ErrorResponseSubject } from "../types/ErrorResponseSubject";

export class ErrorServiceImpl implements ErrorService {
    private constructor() {}
    private static _instance: ErrorService;
    private static _errorResponseSubject: ErrorResponseSubject =
        ErrorServiceImpl.getInitialErrorResponseSubject();

    private static getInitialErrorResponseSubject(): ErrorResponseSubject {
        return {
            [StatusCodes.BAD_REQUEST]: new BehaviorSubject(false),
            [StatusCodes.UNAUTHORIZED]: new BehaviorSubject(false),
        };
    }

    static getInstance() {
        if (!ErrorServiceImpl._instance) {
            ErrorServiceImpl._instance = new ErrorServiceImpl();
        }
        return ErrorServiceImpl._instance;
    }

    async throwResponseError(response: any) {
        const responseJSON = await response.json();
        throw new Error(
            JSON.stringify({
                statusText: response.statusText,
                data: responseJSON,
            })
        );
    }

    subscribeOnError(
        statusCode: StatusCodes,
        onErrorCallback?: () => void
    ): Subscription {
        return ErrorServiceImpl._errorResponseSubject[statusCode].subscribe(
            (error) => {
                if (error && onErrorCallback) {
                    onErrorCallback();
                }
            }
        );
    }

    getStatusFromErrorResponseSubject(statusCode: StatusCodes): boolean {
        return ErrorServiceImpl._errorResponseSubject[statusCode].getValue();
    }

    notifyErrorResponseSubjectListeners(
        statusCode: StatusCodes,
        value: boolean
    ): void {
        ErrorServiceImpl._errorResponseSubject[statusCode].next(value);
    }

    async handleResponseError(response: Response): Promise<void> {
        switch (response.status) {
            case StatusCodes.OK:
                this.resetErrorResponseSubject();
                break;
            case StatusCodes.UNAUTHORIZED:
                this.notifyErrorResponseSubjectListeners(
                    StatusCodes.UNAUTHORIZED,
                    true
                );
                break;
            case StatusCodes.BAD_REQUEST:
                this.notifyErrorResponseSubjectListeners(
                    StatusCodes.BAD_REQUEST,
                    true
                );
                break;
            default:
                await this.throwResponseError(response);
                break;
        }
    }

    resetErrorResponseSubject(): void {
        Object.values(ErrorServiceImpl._errorResponseSubject).forEach((obj) => {
            obj.next(false);
        });
    }
}

export function errorServiceFactory(): ErrorService {
    return ErrorServiceImpl.getInstance();
}
