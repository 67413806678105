import { FC } from "react";
import LogoSVG from "../shared/svg/logos/LogoSVG";
import FacebookIconSVG from "../shared/svg/footer-icons/FacebookIconSVG";
import EnvelopeIconSVG from "../shared/svg/footer-icons/EnvelopeIconSVG";
import { useTranslation } from "react-i18next";
import { projectEmail } from "../../Config";
import { Link } from "react-router-dom";
import { environment } from "../../environments/environment";
import Toast from "../shared/Toast";
import useToastMessage from "../../custom-hooks/useToastMessage";
import { ResponseMessage } from "../../domain/enums/common/ResponseMessageEnum";
import { LanguageService } from "../../domain/services/LanguageService";
import languageServiceFactory from "../../services/LanguageServiceImpl";

const langService: LanguageService = languageServiceFactory();

const Footer: FC = () => {
    const { t } = useTranslation();
    const { message, setMessage, resetMessage } = useToastMessage();

    const copyProjectEmailHandler = async () => {
        await navigator.clipboard.writeText(projectEmail);
        setMessage("messages.copied", ResponseMessage.SUCCESS);
    };

    return (
        <footer className={`bg-brown7 flex justify-center items-center h-[438px] text-[#e4d6b0]`}>
            <div className="absolute left-0 right-0 max-w-[1500px] mx-auto flex overflow-x-clip">
                <LogoSVG className="w-[500px] h-[175px]" fill="#EBDCB5" />
                <div className="flex flex-col justify-between ml-[18%]">
                    <div className="font-cormorant text-[22px] leading-[26.6px] w-[775px] grid grid-cols-3 gap-x-14 gap-y-6 text-left">
                        <Link to="/about" className="hover:underline">
                            {t("navigation.aboutProject")}
                        </Link>
                        <Link to="/search" className="hover:underline">
                            {t("navigation.surnameMap")}
                        </Link>
                        <Link to="/recommendations" className="hover:underline">
                            {t("navigation.recommendations")}
                        </Link>
                        <Link to="/archives" className="hover:underline">
                            {t("navigation.archives")}
                        </Link>
                        <Link to="/dna-genealogy" className="hover:underline">
                            {t("navigation.dnaGenealogy")}
                        </Link>
                        <Link to="/contacts" className="hover:underline">
                            {t("navigation.contacts")}
                        </Link>
                    </div>
                    <div className="flex items-center">
                        <a href={environment.PROJECT_FACEBOOK} target="_blank" rel="noreferrer" className="text-beige4">
                            <FacebookIconSVG className="mr-[27px]" />
                        </a>
                        <p
                            onClick={copyProjectEmailHandler}
                            className="cursor-pointer font-manrope font-medium text-[20px] text-beige4 flex items-center"
                        >
                            <EnvelopeIconSVG className="mr-[12px]" />
                            {projectEmail}
                        </p>
                    </div>
                    <Toast
                        messageObj={message}
                        resetMessageObj={resetMessage}
                        wrapClassName="!bg-beige4"
                        successIconWrapClassName="!bg-beige4"
                        dangerIconWrapClassName="!bg-beige4"
                        warningIconWrapClassName="!bg-beige4"
                        closeButtonClassName="!bg-beige4 !text-brown3"
                        labelClassName="font-manrope text-brown3"
                        staticClassName={`bottom-[25px] ${
                            langService.isEnglish ? "!w-[95px] right-[575px]" : "!w-[135px] right-[560px]"
                        }`}
                        staticType
                        singleMessageMode
                    />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
