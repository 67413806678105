import { FC, useState } from "react";
import CustomInput from "../../shared/form-inputs/CustomInput";
import useCustomFormik from "../../../custom-hooks/useCustomFormik";
import { ChangeUserPasswordDTO } from "../../../domain/models/dto/UserDTO";
import {
    changeUserPasswordFields,
    changeUserPasswordSchemaFactory,
} from "../../../validation-schemas/ChangeUserPasswordSchema";
import { InputType } from "../../../types/InputType";
import { useTranslation } from "react-i18next";
import { userServiceFactory } from "../../../services/UserServiceImpl";
import { StatusCodes } from "http-status-codes";
import { useAuth } from "../../../context/AuthProvider";
import { useErrorHandler } from "../../../custom-hooks/base-hooks/useErrorHandler";
import SettingsSuccessModal from "./SettingsSuccessModal";

const userService = userServiceFactory();

const ChangePasswordForm: FC = () => {
    const { t } = useTranslation();
    const { signOut } = useAuth();
    const [successModal, setSuccessModalVisibility] = useState(false);

    const { errorKey, setErrorKey, resetErrors } = useErrorHandler(StatusCodes.UNAUTHORIZED);
    const { form } = useCustomFormik<ChangeUserPasswordDTO>({
        initial: null,
        fields: changeUserPasswordFields,
        schema: changeUserPasswordSchemaFactory(t),
        onSubmit: async (values) => {
            resetErrors();
            if (values.newPassword !== values.confirmNewPassword) {
                return setErrorKey("errors.validation.password.confirm");
            }
            const user = await userService.changeUserPassword(values);
            if (user?.id) {
                setSuccessModalVisibility(true);
            }
        },
    });

    const successModalHandler = () => {
        form.resetForm();
        signOut();
    };

    return (
        <div className="w-[420px] ml-[20px] mt-[20px] h-[360px] px-[20px] pt-[30px] shadow-md rounded-xl flex flex-col items-starts">
            <SettingsSuccessModal
                isVisible={successModal}
                setIsVisible={setSuccessModalVisibility}
                closeHandler={successModalHandler}
            />
            <p className="text-[24px] text-center">{t("admin.settingsPage.changePassword.changePasswordTitle")}</p>
            <div className="flex flex-col mt-[20px] items-center">
                <CustomInput
                    type={changeUserPasswordFields.currentPassword.type as InputType}
                    value={form.values.currentPassword}
                    name="currentPassword"
                    placeholderKey={changeUserPasswordFields.currentPassword.placeholderKey}
                    errorKey={form.errors.currentPassword as string}
                    onChange={form.handleChange}
                    className="w-[300px]"
                />
                <CustomInput
                    type={changeUserPasswordFields.newPassword.type as InputType}
                    value={form.values.newPassword}
                    name="newPassword"
                    placeholderKey={changeUserPasswordFields.newPassword.placeholderKey}
                    errorKey={form.errors.newPassword as string}
                    onChange={form.handleChange}
                    className="w-[300px]"
                />
                <CustomInput
                    type={changeUserPasswordFields.confirmNewPassword.type as InputType}
                    value={form.values.confirmNewPassword}
                    name="confirmNewPassword"
                    placeholderKey={changeUserPasswordFields.confirmNewPassword.placeholderKey}
                    errorKey={errorKey || (form.errors.confirmNewPassword as string)}
                    onChange={form.handleChange}
                    className="w-[300px]"
                />
                <button
                    data-modal-hide="static-modal"
                    type="button"
                    className={
                        "h-11 w-36 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-3 py-2 text-center"
                    }
                    onClick={() => form.handleSubmit()}
                >
                    {t("admin.settingsPage.changePassword.buttonTitle")}
                </button>
            </div>
        </div>
    );
};

export default ChangePasswordForm;
