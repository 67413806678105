import { FC } from "react";
import { SearchResidentsDTO } from "../../domain/models/dto/ResidentDTO";
import languageServiceFactory from "../../services/LanguageServiceImpl";
import Tooltip from "../shared/Tooltip";
import _ from "lodash";

interface SurnameMapTableRowProps {
    resident: SearchResidentsDTO;
    order: number;
    currentDistrictId: string;
    setCurrentDistrictId: (value: string) => void;
}

const langService = languageServiceFactory();

const SurnameMapTableRow: FC<SurnameMapTableRowProps> = (props) => {
    const selected = props.currentDistrictId && props.currentDistrictId === props.resident.districtId;

    const getLocalityWithRegion = () => {
        if (!props.resident?.localityUk || !props.resident?.localityEn) {
            return "";
        }
        return langService.isUkrainian
            ? _.get(props.resident, "localityUk", "") + ` (${_.get(props.resident, "regionUk", "")})`
            : _.get(props.resident, "localityEn", "") + ` (${_.get(props.resident, "regionEn", "")})`;
    };

    const getDistrictWithRegion = () => {
        if (!props.resident?.districtUk || !props.resident?.districtEn) {
            return "";
        }
        return langService.isUkrainian
            ? _.get(props.resident, "districtUk", "") + ` (${_.get(props.resident, "regionUk", "")})`
            : _.get(props.resident, "districtEn", "") + ` (${_.get(props.resident, "regionEn", "")})`;
    };

    return (
        <tr
            id={props.resident.districtId}
            onClick={() => props.setCurrentDistrictId(props.resident.districtId)}
            className={`${
                selected ? "bg-button-gradient !text-beige1" : props.order % 2 === 0 ? "bg-beige7" : "bg-beige1"
            } h-[50px] font-manrope font-normal text-[18px] text-brown3 border border-brown3 cursor-pointer hover:bg-orange-100`}
        >
            <th className="border border-brown3 w-[52px]">{props.order}</th>
            <th className="relative pl-[12px] text-left w-[160px] max-w-[160px] border border-brown3">
                <Tooltip
                    children={langService.isUkrainian ? props.resident.districtUk : props.resident.districtEn}
                    content={getDistrictWithRegion()}
                    childrenClassName="truncate"
                    wrapperClassName={`font-manrope text-brown3 border-b border-brown3 bg-beige4 shadow-beige4Section min-h-[40px] max-w-[350px] px-[10px] py-[5px] ${
                        props.order - 1 === 0
                            ? "top-[40px] z-[100]"
                            : props.order - 1 === 1
                            ? "top-[40px] z-[100]"
                            : "bottom-[40px]"
                    }`}
                />
            </th>
            <th className="relative pl-[12px] text-left w-[160px] max-w-[160px] border border-brown3">
                <Tooltip
                    children={langService.isUkrainian ? props.resident.localityUk : props.resident.localityEn}
                    content={getLocalityWithRegion()}
                    childrenClassName="truncate"
                    wrapperClassName={`font-manrope text-brown3 border-b border-brown3 bg-beige4 shadow-beige4Section min-h-[40px] max-w-[250px] px-[10px] py-[5px] ${
                        props.order - 1 === 0
                            ? "top-[40px] z-[100]"
                            : props.order - 1 === 1
                            ? "top-[40px] z-[100]"
                            : "bottom-[40px]"
                    }`}
                />
            </th>
            <th className="text-center w-[46px] max-w-[52px] border border-brown3 truncate">
                {props.resident.fathersCount !== null ? props.resident.fathersCount : "—"}
            </th>
            <th className="text-center w-[46px] max-w-[52px] border border-brown3 truncate">
                {props.resident.mothersCount !== null ? props.resident.mothersCount : "—"}
            </th>
        </tr>
    );
};

export default SurnameMapTableRow;
